.work-detail {
  @apply relative;

  &__hero {
    @apply flex items-start justify-center overflow-hidden;
    max-width: 100vw;
    padding-top: 80px;
    min-height: 720px;

    @screen s {
      @apply items-center;
      height: 720px;
    }

    @screen m {
      @apply h-screen;
      min-height: 860px;
      max-height: 1200px;
    }
  }

  &__bg {
    @apply absolute top-0 left-0 z-0 pointer-events-none select-none;
    clip-path: url(#poly-header-clip-path);
    height: 220vw;
    max-height: none;
    width: 160vw;

    @screen s {
      @apply left-auto;
      height: 150%;
      min-height: 150%;
      min-width: 1000px;
      right: 43vw;
      width: 10%;
    }

    @screen m {
      height: 140vh;
      min-height: 140%;
      width: 140vh;
      min-width: 1200px;
    }
  }

  &__bg-color {
    @apply absolute h-full top-0 left-0 overflow-hidden;
    width: 200vw;

    @screen s {
      @apply h-full w-full;
    }
  }

  &__bg-image {
    @apply absolute h-auto w-full min-w-full;
    opacity: 0.6;
  }

  &__content {
    @apply relative max-w-page z-10;

    @screen s {
      @apply flex;
      height: 80%;
      width: 85%;
    }
  }

  &__image {
    @apply mx-auto h-auto mt-16;
    width: calc(100% - 2rem);
    max-width: none;
    max-height: none;

    .cover-image {
      img {
        @apply relative w-auto mx-auto;
        max-width: 100%;
        max-height: 100%;
      }

      &--optimistic {
        @apply w-full h-full;

        img {
          @apply mx-auto w-auto h-full object-cover;
        }
      }
    }

    @screen s {
      @apply mt-0;
      width: calc(100% - 4rem);

      .cover-image {
        @apply w-full h-full;
      }
    }
  }

  &__text {
    @apply flex items-center justify-end py-12;

    @screen s {
      @apply py-0;
    }
  }

  &__text-wrap {
    @apply w-full px-4;

    @screen s {
      @apply px-0;

      width: 80%;
    }
  }

  &__learn-more {
    @apply relative pl-8;

    &::before {
      @apply absolute top-0;
      border-bottom: 2px solid theme('colors.red');
      border-left: 2px solid theme('colors.red');
      content: '';
      height: 12px;
      left: 2px;
      transform: rotate(-45deg);
      width: 12px;
    }
  }

  &__watch-video,
  &__read-transcript {
    @apply relative block align-middle h-5 mb-4;

    &:hover,
    &:focus {
      @apply text-red;
      transition: color 0.175s;
    }

    &::before {
      @apply mr-4 h-5 w-5 inline-block align-middle;
      background-image: url(/dist/images/watch-video-icon.svg);
      content: '';
    }
  }

  &__read-transcript::before {
    background-image: url(/dist/images/read-trascript-icon.svg);
  }

  .optimistic & {
    &__hero-bg {
      @apply absolute inset-0 z-0 pointer-events-none select-none;

      svg {
        height: 175vw;
        width: 175vw;

        @screen s {
          @apply h-full;
          width: 75vw;
        }
      }
    }

    &__watch-video,
    &__read-transcript {
      &:hover,
      &:focus {
        @apply text-ods-purple-core;
      }

      &::before {
        background-image: url(/dist/images/watch-video-icon-ods.svg);
      }
    }

    &__read-transcript::before {
      background-image: url(/dist/images/read-trascript-icon-ods.svg);
    }

    &__learn-more {
      &::before {
        border-bottom: 2px solid theme('colors.ods-purple.light');
        border-left: 2px solid theme('colors.ods-purple.light');
      }
    }
  }
}
