.podcast-feed {
  @apply relative;

  &__bg {
    @apply absolute top-0 left-0 right-0 z-0;
    height: 60%;

    @screen s {
      height: 52%;
    }
  }

  .podcast-feed-slider {
    img {
      @apply w-full;
    } 

    .js-slider__slide {
      @apply z-0;
    }

    .js-slider__slide.active {
      @apply z-10;
    }

    .js-slider__nav span {
      @apply hidden;
    }

    .js-slider__nav-blip {
      @apply bg-gray-400;
    }
  }
}