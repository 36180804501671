@font-face {
  font-family: 'Gelion';
  font-weight: 300;
  src: url(/dist/fonts/gelion-light.woff) format('woff'),
    url(/dist/fonts/gelion-light.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Gelion';
  font-weight: 500;
  src: url(/dist/fonts/gelion-regular.woff) format('woff'),
    url(/dist/fonts/gelion-regular.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Gelion';
  font-weight: 800;
  src: url(/dist/fonts/gelion-bold.woff) format('woff'),
    url(/dist/fonts/gelion-bold.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Apercu Mono Pro';
  font-weight: 600;
  src: url(/dist/fonts/apercu-mono-medium-pro.woff) format('woff'),
    url(/dist/fonts/apercu-mono-medium-pro.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'GT Super Display';
  font-weight: 800;
  src: url(/dist/fonts/gt-super-display-bold.woff) format('woff'),
    url(/dist/fonts/gt-super-display-bold.woff2) format('woff2');
  font-display: swap;
}
