.gdpr-banner {
  transition: opacity 0.35s ease, transform 0.35s ease;

  &.closed {
    @apply opacity-0;
    transform: translateY(100%);
  }

  &__x {
    @apply relative cursor-pointer;
    width: 24px;
    height: 24px;

    &::before,
    &::after {
      @apply absolute h-full bg-white;
      content: '';
      left: 50%;

      width: 1px;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }

    &:active {
      transform: scale(0.9);
    }
  }
}
