/** Background colors */
.cms-bg {
  &--red {
    background-color: theme('colors.red');
  }

  &--coral {
    background-color: theme('colors.coral');
  }

  &--orange {
    background-color: theme('colors.orange');
  }

  &--yellow {
    background-color: theme('colors.yellow');
  }

  &--lightYellow {
    background-color: theme('colors.lightYellow');
  }

  &--white {
    background-color: theme('colors.white.default');
  }

  &--lightGrey {
    background-color: theme('colors.gray.1');
  }

  &--black {
    background-color: theme('colors.black.default');
  }

  &--redPastel {
    @apply bg-ods-red-pastel bg-opacity-50;
  }

  &--purplePastel {
    @apply bg-ods-purple-pastel bg-opacity-50;
  }

  &--yellowPastel {
    @apply bg-ods-yellow-pastel bg-opacity-50;
  }

  &--bluePastel {
    @apply bg-ods-blue-pastel bg-opacity-50;
  }

  &--darkPurple {
    @apply bg-ods-purple-dark;
  }

  &--darkBlue {
    @apply bg-ods-blue-dark;
  }

  &--charcoal {
    @apply bg-charcoal;
  }

  .optimistic & {
    &--black {
      background-color: theme('colors.ods-purple.neutral');
    }
  }
}

/** Text colors */
.cms-color {
  &--red {
    color: theme('colors.red');
  }

  &--coral {
    color: theme('colors.coral');
  }

  &--orange {
    color: theme('colors.orange');
  }

  &--yellow {
    color: theme('colors.yellow');
  }

  &--lightYellow {
    color: theme('colors.lightYellow');
  }

  &--white {
    color: theme('colors.white.default');
  }

  &--lightGrey {
    color: theme('colors.gray.1');
  }

  &--black {
    color: theme('colors.black.default');
  }
}

/** Padding */
.cms-space {
  &-top {
    &--l {
      @apply mt-32;

      @screen s {
        @apply mt-56;
      }
    }
    &--m {
      @apply mt-20;

      @screen s {
        @apply mt-40;
      }
    }
    &--s {
      @apply mt-6;

      @screen s {
        @apply mt-12;
      }
    }
    &--none {
      @apply mt-0;
    }
    &--ns {
      @apply -mt-12;
    }
    &--nm {
      @apply -mt-40;
    }
    &--nl {
      @apply -mt-40;

      @screen s {
        @apply -mt-64;
      }
    }
  }

  &-bottom {
    &--l {
      @apply mb-32;

      @screen s {
        @apply mb-56;
      }
    }
    &--m {
      @apply mb-20;

      @screen s {
        @apply mb-40;
      }
    }
    &--s {
      @apply mb-6;

      @screen s {
        @apply mb-12;
      }
    }
    &--none {
      @apply mb-0;
    }
    &--ns {
      @apply -mb-12;
    }
    &--nm {
      @apply -mb-40;
    }
    &--nl {
      @apply -mb-32;

      @screen s {
        @apply -mb-48;
      }
    }
  }
}

/** ODS Padding */
.ods-space {
  &-top {
    &--l {
      @apply pt-20;

      @screen s {
        @apply pt-40;
      }
    }
    &--m {
      @apply pt-12;

      @screen s {
        @apply pt-20;
      }
    }
    &--s {
      @apply pt-6;

      @screen s {
        @apply pt-12;
      }
    }
    &--none {
      @apply pt-0;
    }
    &--ns {
      @apply -mt-12;
    }
    &--nm {
      @apply -mt-20;
    }
    &--nl {
      @apply -mt-40;

      @screen s {
        @apply -mt-64;
      }
    }
  }

  &-bottom {
    &--l {
      @apply pb-20;

      @screen s {
        @apply pb-40;
      }
    }
    &--m {
      @apply pb-12;

      @screen s {
        @apply pb-20;
      }
    }
    &--s {
      @apply pb-6;

      @screen s {
        @apply pb-12;
      }
    }
    &--none {
      @apply pb-0;
    }
    &--ns {
      @apply -mb-12;
    }
    &--nm {
      @apply -mb-20;
    }
    &--nl {
      @apply -mb-32;

      @screen s {
        @apply -mb-48;
      }
    }
  }
}
