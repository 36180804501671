.list-item {
  @apply relative pl-5;
  box-sizing: border-box;

  &::before {
    @apply absolute left-0 bg-red;
    content: '';
    height: 1px;
    top: 8px;
    width: 12px;
  }

  .optimistic & {
    &::before {
      @apply bg-ods-purple-light;
    }
  }
}
