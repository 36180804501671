.button {
  @apply inline-block relative pt-5 cursor-pointer outline-none;
  margin: 0 38px;
  padding-bottom: 1.125rem;
  transition: color 0.25s, padding 0.25s;

  &:hover {
    @apply px-2 text-white;

    .background {
      @apply opacity-100;
    }
  }

  &:focus,
  &:active {
    @apply outline-none;
  }

  &.button--light {
    color: theme('colors.black.default');

    &:hover .button__text {
      color: theme('colors.white.default');
    }
  }

  &.button--dark {
    color: theme('colors.white.default');

    &:hover .button__text {
      color: theme('colors.white.default');
    }
  }

  &.button--grad {
    color: theme('colors.white.default');

    &:hover .button__text {
      color: theme('colors.red');
    }

    & .tess-left .stroke,
    & .tess-right .stroke,
    & .center .stroke {
      stroke: theme('colors.white.default');
    }

    & .background .tess-left .back-fill,
    & .background .tess-right .back-fill,
    & .background .center .back-fill {
      fill: theme('colors.white.default');
    }
  }

  &.button--solid {
    color: theme('colors.red');

    & .background {
      @apply opacity-100;
    }

    & .tess-left .stroke,
    & .tess-right .stroke,
    & .center .stroke {
      stroke: theme('colors.white.default');
    }

    & .background .tess-left .back-fill,
    & .background .tess-right .back-fill,
    & .background .center .back-fill {
      fill: theme('colors.white.default');
    }
  }

	&.button--full-width-mobile {
		@apply text-center box-border pt-3 pb-2;
		width: calc(100% - 76px);

		svg.tess-left {
			left: -37px;
		}

		svg.tess-right {
			right: -37px;
		}

		&:hover {
			@apply px-0;
		}

		@screen s {
      @apply w-auto pt-5;
			padding-bottom: 1.125rem;
    }
	}

  .background {
    @apply absolute top-0 left-0 w-full h-full opacity-0;
    transition: opacity 0.25s;
  }

  svg.center {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  svg.tess {
    position: absolute;
    height: 100%;
    top: 0;
  }

  svg.tess-left {
    left: -38px;
  }

  svg.tess-right {
    right: -38px;
  }

  .stroke {
    fill: rgba(255, 39, 68, 0);
    stroke: url(#button-gradient) theme('colors.red');
    stroke-miterlimit: 10;
    stroke-width: 1px;
    transition: fill 0.25s;
  }

  .tess-left .stroke {
    stroke: theme('colors.coralGradient');
  }

  @supports (-ms-ime-align: auto) {
    .tess-left .stroke {
      stroke: theme('colors.red');
    }
  }

  .tess-right .stroke {
    stroke: theme('colors.red');
  }

  .background .back-fill {
    fill: url(#button-gradient) theme('colors.red');
  }

  .background .tess-left .back-fill {
    fill: theme('colors.coralGradient');
  }

  .background .tess-right .back-fill {
    fill: theme('colors.red');
  }

  .button__text {
    @apply relative font-body font-bold leading-body text-2xs-acc;
  }
}

.btn-ods {
  @apply relative rounded-full inline-block text-3xs font-semibold transition-transform-colors duration-300 px-6 py-3;

  &::after {
    content: '';
    @apply absolute w-full h-full border border-solid rounded-full top-0 left-0 transition-transform-colors duration-300;
  }

  &:hover {
    &::after {
      @apply transform scale-x-110;
    }
  }

  &.btn-solid {
    &.btn-dark {
      @apply text-white;

      &::after {
        @apply bg-ods-purple-core border-ods-purple-core;
      }

      &:hover {
        @apply text-ods-purple-core;

        &::after {
          @apply bg-white border-white;
        }
      }
    }
  
    &.btn-light {
      @apply text-ods-purple-core;

      &::after {
        @apply bg-white border-white;
      }

      &:hover {
        @apply text-white;

        &::after {
          @apply bg-ods-purple-core border-ods-purple-core;
        }
      }
    }
  }

  &.btn-footer-cta {
    @apply text-ods-purple-core;

    &::after {
      @apply bg-white border-white;
    }

    &:hover {
      @apply text-white;

      &::after {
        @apply bg-ods-purple-core;
      }
    }
  }

  &.btn-outline {
    &.btn-dark {
      @apply text-ods-purple-core;

      &::after {
        @apply border-ods-purple-core;
      }

      &:hover {
        @apply text-white;

        &::after {
          @apply bg-ods-purple-core;
        }
      }
    }

    &.btn-light {
      @apply text-white;

      &::after {
        @apply border-white;
      }

      &:hover {
        @apply text-ods-purple-core;

        &::after {
          @apply bg-white;
        }
      }
    }

  }

  &.external-link {
    &::after {
      @apply hidden;
    }
  }
}
