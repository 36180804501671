.tabbed-list {
  &__tab {
    @apply relative px-8 py-2 appearance-none transition-opacity duration-300;

    &::after {
      @apply absolute left-0 w-full h-px bg-red opacity-0; 
      bottom: -1px;
      content: '';
    }

    &--active {
      &::after {
        @apply opacity-100;
      }
    }
  }

  &__page {
    @apply absolute top-0 left-0 w-full grid grid-cols-1 gap-8 opacity-0 transition-opacity duration-300;

    @screen s {
      @apply grid-cols-2;
    }

    @screen m {
      @apply grid-cols-3;
    }

    &--active {
      @apply relative opacity-100;
    }
  }
}