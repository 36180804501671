.connect-index {
  &__header {
  }

  &__header-text {
    @apply mx-auto;
    margin-top: 120px;
    max-width: 700px;
  }

  &__form-wrap {
    @apply mx-auto px-4 mt-12 mb-32;
    max-width: 800px;
  }
}
