.rich-text {
  & h3.intro {
    @apply sans-l3 text-xs font-light mb-6 normal-case tracking-normal;

    @screen s {
      @apply sans-l3 text-s font-light;
    }
  }

  & p {
    @apply sans-l6 mb-6;

    &:last-child {
      @apply mb-0;
    }

    @screen m {
      @apply text-2xs-acc;
    }
  }

  a {
    @apply inline-block relative text-black underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
    transition: color 0.175s;

    &:hover {
      color: theme('colors.red');
    }
  }

  & h1 {
    @apply serif-l4 mb-3 text-m;
    line-height: 1.25;
    margin-bottom: 1.25rem;
  }

  & h2 {
    @apply sans-l5-bold mb-3;
  }

  & h3 {
    @apply mono-l1 mb-4;
  }

  & h4 {
    @apply mono-l1 mb-4;
  }

  & pre {
    @apply mb-6 overflow-scroll text-5xs;
    background-color: #e0e0e0;
    padding: 0.75rem 1rem;
  }

  & ul {
    @apply mb-6;
  }

  & ol {
    @apply list-decimal pl-4 mb-6;

    & li {
      @apply pl-2;
    }
  }

  & li {
    @apply text-3xs;
    line-height: 1.41;
  }

  & blockquote {
    @apply block text-2xs font-semibold leading-normal w-full mr-0 pl-6 mb-6 relative;

    @screen s {
      @apply float-right ml-6 mb-0;
      width: 380px;
    }

    @screen m {
      @apply -mr-24;
    }

    &::after {
      @apply w-px h-full bg-red absolute left-0 top-0;
      content: '';
    }
  }

  & .highlight {
    background: #e0e0e0;
    padding: 0.125rem 0.25rem;
  }

  & .closing-bullets {
    @apply text-center text-black-a65 mb-4;

    &::before {
      content: '• • •';
    }
  }

  &-banner {
    p,
    a {
      @apply text-white text-2xs-acc font-semibold;
    }
  }

  .optimistic & {
    h2.h1 {
      @apply font-ods-display text-l font-normal leading-tight mb-6;

      &:last-child {

        @screen s {
          @apply mb-0;
        }
      }

      @screen s {
        @apply text-xl;
      }

      @screen m {
        @apply text-xla;
      }
    }

    p {
      @apply font-ods-body text-3xs font-normal leading-body;

      &.large {
        @apply text-2xs font-light;
        
        @screen m {
          @apply text-s;
        }
      }

      &.subhead {
        @apply font-ods-mono text-5xs font-normal leading-tight tracking-widest uppercase mb-6;
      }
    }

    a {
      @apply text-black inline;

      &:hover {
        @apply text-ods-purple-light;
      }

      &.btn-ods {
        @apply no-underline;
      }
    }

    &-hero {
      h2.h1 {
        @apply text-xl;

        @screen s {
          @apply text-2xl;
        }

        @screen m {
          @apply text-3xl;
        }
      }

      p:not(.subhead) {
        @apply font-ods-body text-3xs font-light leading-snug;

        @screen s {
          @apply text-2xs;
        }
      }

      &-xl {
        p:not(.subhead) {
          @screen s {
            @apply text-s;
          }
        }
      }
    }

    &.rich-text-banner {
      p,
      a {
        @apply text-2xs-acc text-white;
      }

      a {
        &:hover {
          @apply text-ods-purple-light;
        }
      }
    }
  }
}

.optimistic {
  .work-detail {
    .rich-text {
      h2.h1 {
        @screen s {
          @apply text-m;
        }
      }
    }

    .file-download {
      .rich-text {
        h2.h1 {
          @apply mb-2;
        }
      }
    }
  }
}
