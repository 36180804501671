.ecd-maturity-survey {
  .previous-link {
    @apply inline-block relative text-black underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
    transition: color 0.175s;

    &:hover {
      color: theme('colors.red');
    }

    path{
      fill: theme('colors.black.pure');
      transition: fill 0.175s ease;
    }

    &:hover {
      & path {
        fill: theme('colors.red');
      }
    }
  }
}
