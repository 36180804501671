.popup {
  width: 440px;

  .menu-button {
    @apply absolute; 
    top: 10px;
    right: 10px; 
    padding: .875rem;

    &::before,
    &::after {
      @apply bg-white w-4;
      left: 6px;
      top: 2px;
    }
  }
}