[data-aos='fade'],
[data-aos='fade-up'],
[data-aos='zoom-out'] {
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1) !important;
  transition-duration: 1.25s !important;
}

.rellax.h-full-plus > img {
  @apply -mt-12;
}
