.block-wrap {
  @apply absolute z-0 top-0 left-0 w-full;

  .block__shape--black {
    fill: theme('colors.black.default');
  }

  .block__shape--white {
    fill: theme('colors.white.default');
  }

  .block__shape--lightGrey {
    fill: theme('colors.gray.1');
  }

  &--bottom {
    @apply top-auto bottom-0;
    transform: scaleY(-1);
  }

  &--reverse {
    transform: scaleX(-1);
  }
}
