.simple-quote-slider {
  overflow: visible !important;
}

.quote-slider {
  @apply relative w-full flex flex-col-reverse overflow-hidden;

  @screen s {
    @apply block;
  }

  &__wrap {
    @apply bg-black w-full;

    @screen s {
      @apply w-auto;
      background: none;
    }
  }

  &__quote-wrap {
    @apply w-full bg-black py-20 text-white;

    @screen s {
      @apply relative pr-12 mr-32 w-auto;
    }

    &::before {
      @apply absolute top-0 bottom-0 w-full bg-black;
      content: '';
      left: -100%;
    }
  }

  &__quote-text {
    @apply text-s font-body font-semibold leading-snug mb-8;

    @screen s {
      @apply text-l;
    }

    @screen m {
      @apply text-xl;
    }
  }

  &__bg-wrap {
    @apply relative w-full;
    height: 280px;

    @screen s {
      @apply absolute right-0 bottom-0 top-0 z-0 w-auto h-auto;
      left: 164px;
    }

    &--no-images {
      @apply h-0;
    }
  }

  &__nav-total {
    @apply opacity-50;
    margin-left: 40%;
  }

  &__image {
    @apply absolute top-0 left-0 w-full opacity-0;
    height: 200%;
    margin-top: -25%;
    transition: opacity 0.55s ease;

    @screen s {
      @apply h-full-plus -mt-8;
    }
  }

  &__image.active {
    @apply opacity-100;
  }

  &__slide {
    @apply absolute opacity-0 px-4 pl-6;
    transition: transform 0.55s ease, opacity 0.55s ease;

    @screen s {
      @apply px-0;
    }
  }

  &__slide.prev {
    transform: translateX(-35px);
  }

  &__slide.active {
    @apply opacity-100;
    right: 64px;
    transform: translateX(0);
  }

  &__slide.next {
    transform: translateX(35px);
  }

  &__nav-slides {
    @apply flex;
  }

  &__nav-slide {
    @apply relative mr-6 bg-white-a50;
    height: 2px;
    width: 44px;

    &:last-child {
      @apply mr-0;
    }

    &::after {
      @apply absolute top-0 left-0 h-full w-full bg-red;
      content: '';
      transform: scaleX(0);
      transform-origin: 0 center;
      transition: none;
    }

    &.active::after {
      transform: scaleX(1);
      transition: transform 5s linear;
    }
  }

  & .js-slider__nav-blip {
    @apply bg-white-a50;
  }

  .optimistic & {
    @apply font-ods-body;
  
    &__wrap {
      @apply bg-ods-purple-neutral;

      @screen s {
        background-color: transparent;
      }
    }
  
    &__quote-wrap {
      @apply bg-ods-purple-neutral;
  
      &::before {
        @apply bg-ods-purple-neutral;
      }
    }
  
    &__quote-text {
      @apply font-ods-body;
    }

    &__bg-wrap {  
      @screen s {
        @apply left-0;
      }
    }
  
    &__nav-slide {
      &::after {
        @apply bg-ods-purple-light;
      }
    }
  
    & .js-slider__nav-blip {
      &::after {
        @apply bg-ods-purple-light;
      }
    }

    .rellax-container {
      @apply absolute top-0 left-0 w-full h-full;

      max-height: 40vh;
    
      img {
        @apply object-top;
        height: 60vh;
      }
  
      @screen s {
        height: 150%;
        max-height: 100%;
        top: 50%;
        transform: translateY(-50%);

        img {
          @apply h-full mt-0;
        }
      }
    }
  }
}
