.cover-image {
  & img {
    @apply absolute top-0 left-0 w-full h-full object-cover;
  }
}

.bleed-right {
  @screen s {
    flex-grow: 1;
    margin-left: auto;
    margin-right: calc(-50vw + 50%);
    padding-right: 0;
  }

  .rellax {
    margin-top: -5%;

    @screen s {
      margin-top: -5%;
    }
  }
}

.bleed-left {
  @screen s {
    flex-grow: 1;
    margin-left: calc(-50vw + 50%);
    margin-right: auto;
    padding-left: 0;
    padding-left: 0;
  }

  .rellax {
    margin-top: -5%;

    @screen s {
      margin-top: -5%;
    }
  }
}
