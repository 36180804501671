.image-two-column-text {
  @apply mx-auto;

  &__image {
    @apply relative w-full h-0 bg-cover bg-center bg-no-repeat mb-16;
    padding-top: 56.25%;
  }

  &__image-wrap {
    height: 175%;

    @screen m {
      height: 140%;
    }
  }

  &__image-text {
    @apply absolute top-0 left-0 w-full h-full flex items-center justify-center text-center text-white;

    h2 {
      @apply relative z-10 text-2xs px-4;

      max-width: 820px;

      @screen s {
        @apply text-m;
      }
    }

    &::before {
      @apply absolute left-0 top-0 w-full h-full z-0 bg-black-a50;
      content: '';
    }
  }

  &__headline {
  }

  &__headline--gradient {
    @include primary-gradient(90deg);
    color: theme('colors.red');

    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
