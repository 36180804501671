.logo-slider {
  &__logos {
    @apply w-full relative overflow-hidden mb-12;
  }

  &__track {
    @apply flex;
    animation: logoSliderScroll 24s linear infinite;
    animation-delay: 1s; // hack to fix safari rendering issue

    @keyframes logoSliderScroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }

  &__logo {
    @apply w-auto flex-shrink-0 pr-20;

    & svg {
      @apply w-auto flex-shrink-0;
      height: 82px;
    }
  }
}
