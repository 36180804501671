.content-with-sub-navigation {
  &--light {
    svg {
      fill: theme('colors.gray.1');
    }
  }

  &--dark {
    @apply bg-black text-white;

    svg {
      fill: rgba(#fff, 0.09);
    }
  }

  .scroll-spy li {
    @apply pl-0;

    &::before {
      @apply hidden;
    }

    &.active {
      @apply text-red;
    }
  }

  .links .sticky {
    top: 120px;
  }

  .section-shape {
    @apply absolute left-0 right-0 z-0;
  }

  .image {
    @apply relative mr-4;
    height: 256px;

    & img {
      @apply absolute top-0 left-0 w-full h-full object-cover;
    }

    @screen s {
      @apply w-full;
      height: 400px;
    }

    @screen m {
      height: 600px;
    }

    @screen l {
      width: 72vw;
    }

    @screen xl {
      height: calc(400px + 18vw);
    }
  }

  &__icon svg {
    width: 48px;
  }

  .quote {
    @apply py-12 my-12;
    border-top: 1px solid theme('colors.black.a25');
    border-bottom: 1px solid theme('colors.black.a25');
  }

  .running-list {
    @apply pb-12 mb-12;
    border-bottom: 1px solid theme('colors.black.a25');
  }

  &__featured-button {
    @apply inline-block;
  }

  &__featured-image {
    opacity: 0.375;
    transition: opacity 0.375s ease;
  }

  .stats {
    @apply mx-auto;

    @screen m {
      max-width: 200px;
    }
  }

  .stats-subhead {
    @screen m {
      border-top: 1px solid theme('colors.black.a25');
    }
  }

  .stat-block {
    &:nth-child(odd) {
      @apply pr-4;
    }

    &:nth-child(even) {
      @apply pl-4;
    }

    @screen m {
      &:nth-child(odd) {
        @apply pr-0;
      }

      &:nth-child(even) {
        @apply pl-0;
      }
    }
  }
}
