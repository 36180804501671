.link {
  @apply inline-block relative;
  transition: color 0.175s ease 0s;

  &::after {
    @apply absolute bottom-0 left-0 right-0 bg-red;
    content: '';
    height: 1px;
    transform: scaleX(0);
    transition: transform 375ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &:hover::after {
    @apply opacity-100;
    transform: scaleX(1);
  }

  &--black::after {
    @apply bg-black;
    background-image: none;
  }

  &.solid::after {
    @apply opacity-100;
    transform: scaleX(1);
    transition: none;
  }

  &--ods {
    &::after {
      @apply bg-ods-purple-light;
    }
  }
}

a:hover .link::after {
  @apply opacity-100;
  transform: scaleX(1);
}

.external-link {
  @apply relative pr-4 bg-no-repeat bg-right;
  transition: color 0.175s;

  &::after {
    @apply absolute h-4 bottom-0 right-0 opacity-0 pr-4 bg-no-repeat bg-center bg-contain;
    content: '';
    margin-bottom: 2px;
    background-image: url('/dist/images/arrow-out.svg');
    transform: translate(0px, 4px);
    transition: opacity 0.375s,
      transform 0.375s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  &:hover::after {
    @apply opacity-100;
    transform: translate(4px, 0px);
  }

  &.location-link {
    &::after {
      bottom: 3px;
    }
  }

  &.solid {
    &::before {
      @apply absolute left-0 bg-red opacity-100;
      bottom: -3px;
      content: '';
      height: 1px;
      right: 16px;
    }
  }

  &--ods {
    @apply pr-6;

    &::after {
      @apply h-8 w-8 mb-0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjYwMzEgMTkuMDI3OEwxOC44NTI3IDEwLjc3ODIiIHN0cm9rZT0iIzg3NjZCMyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KPHBhdGggZD0iTTE5LjYyOTIgMTYuNTE3OEwxOS42MjkyIDEwLjAzNkwxMy4xNDc0IDEwLjAzNiIgc3Ryb2tlPSIjODc2NkIzIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);
      top: -6px;
    }

    &.solid {
      &::before {
        @apply bg-ods-purple-light;
        right: 24px;
      }
    }
  }
}

.link-color {
  transition: color 0.175s;

  &:hover {
    color: theme('colors.red');
  }
}
