$duration: 0.55s;

.menu-button {
  @apply relative block;

  height: 24px;
  width: 24px;
  transition: transform 0.25s ease-in-out;

  &::before,
  &::after {
    @apply absolute bg-black left-0 top-0 pointer-events-none;
    transition: all ($duration / 2) ease, background-color .25s ease;

    content: '';
    height: 1px;
    width: 24px;

    .nav--white &,
    .nav--split & {
      @apply bg-black;
    }

    .nav--black:not(.nav--open):not(.nav--scrolled) & {
      @apply bg-white;
      transition: all ($duration / 2) ease, background-color .25s ease;
    }
  }

  &::before {
    animation: barBeforeClose 0.55s ease-in-out forwards;
    transform: translateY(8px);
  }

  &::after {
    animation: barAfterClose 0.55s ease-in-out forwards;
    transform: translateY(15px);
  }

  &:hover {
    &::before,
    &::after {
      @apply bg-red;
    }
  }

  &--open {
    &::before {
      animation: barBefore 0.55s ease-in-out forwards;
    }

    &::after {
      animation: barAfter 0.55s ease-in-out forwards;
    }
  }

  &--ods {
    &:hover {
      &::before,
      &::after {
        @apply bg-ods-purple-light;
      }
    }
  }

  @keyframes barBefore {
    0% {
      transform: translateY(8px) rotate(0deg);
    }

    40% {
      transform: translateY(11.5px) rotate(0deg);
    }

    60% {
      transform: translateY(11.5px) rotate(0deg);
    }

    100% {
      transform: translateY(11.5px) rotate(45deg);
    }
  }

  @keyframes barBeforeClose {
    0% {
      transform: translateY(11.5px) rotate(45deg);
    }

    40% {
      transform: translateY(11.5px) rotate(0deg);
    }

    60% {
      transform: translateY(11.5px) rotate(0deg);
    }

    100% {
      transform: translateY(8px) rotate(0deg);
    }
  }

  @keyframes barAfter {
    0% {
      transform: translateY(15px) rotate(0deg);
    }

    40% {
      transform: translateY(11.5px) rotate(0deg);
    }

    60% {
      transform: translateY(11.5px) rotate(0deg);
    }

    100% {
      transform: translateY(11.5px) rotate(-45deg);
    }
  }

  @keyframes barAfterClose {
    0% {
      transform: translateY(11.5px) rotate(-45deg);
    }

    40% {
      transform: translateY(11.5px) rotate(0deg);
    }

    60% {
      transform: translateY(11.5px) rotate(0deg);
    }

    100% {
      transform: translateY(15px) rotate(0deg);
    }
  }
}

.nav:not(.nav--open) .menu-button:hover {
  &::before {
    top: -2px;
  }

  &::after {
    top: 2px;
  }
}

@screen s {
  @apply mr-8;
}

@screen m {
  @apply mr-16;
}

@screen l {
  @apply mr-8;
}

@screen l {
  @apply mr-0;
}
