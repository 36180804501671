/** Serif */
.serif-hero {
  @apply text-4xl font-display leading-tight antialiased;

  .optimistic & {
    @apply font-ods-display;
  }
}

.serif-l1 {
  @apply text-2xl font-display leading-tight;

  .optimistic & {
    @apply font-ods-display;
  }
}

.serif-l2 {
  @apply text-m font-display leading-snug;

  @screen s {
    @apply text-xl;
  }

  .optimistic & {
    @apply font-ods-display;
  }
}

.serif-l3 {
  @apply text-l font-display leading-normal;

  .optimistic & {
    @apply font-ods-display;
  }
}

.serif-l4 {
  @apply text-s font-display leading-normal;

  .optimistic & {
    @apply font-ods-display;
  }
}

/** Sans */
.sans-l1 {
  @apply text-xl font-body font-semibold leading-snug;

  .optimistic & {
    @apply font-ods-body;
  }
}

.sans-l2 {
  @apply text-m font-body font-light leading-snug;

  @screen s {
    @apply text-l;
  }

  .optimistic & {
    @apply font-ods-body;
  }
}

.sans-l3 {
  @apply text-m font-body font-semibold leading-snug;

  .optimistic & {
    @apply font-ods-body;
  }
}

.sans-l3-light {
  @apply sans-l3 font-light;

  .optimistic & {
    @apply font-ods-body;
  }
}

.sans-l4 {
  @apply text-xs font-body font-semibold leading-snug;

  .optimistic & {
    @apply font-ods-body;
  }
}

.sans-l5 {
  @apply text-2xs font-body font-normal leading-tight;

  .optimistic & {
    @apply font-ods-body;
  }
}

.sans-l5-bold {
  @apply text-2xs font-body font-semibold leading-tight;

  .optimistic & {
    @apply font-ods-body;
  }
}

.sans-l6 {
  @apply text-3xs font-body font-normal leading-body;

  .optimistic & {
    @apply font-ods-body;
  }
}

.sans-l6-bold {
  @apply sans-l6 font-semibold;

  .optimistic & {
    @apply font-ods-body;
  }
}

.sans-l7 {
  @apply text-4xs font-body font-normal leading-body;

  .optimistic & {
    @apply font-ods-body;
  }
}

/** Mono */
.mono-l1 {
  @apply text-4xs font-mono font-normal leading-snug uppercase;
  letter-spacing: 1px;
}

/** Module Headings */
.module-heading {
  @apply text-m font-display leading-tight;

  @screen s {
    @apply text-xl;
  }

  @screen m {
    @apply text-2xl;
  }

  .optimistic & {
    h2.h1 {
      @apply font-ods-display text-m leading-tight;
  
      @screen s {
        @apply text-xl;
      }
    
      @screen m {
        @apply text-2xl;
      }
    }
  }
}

.module-heading-med {
  @apply text-m font-display leading-tight;

  @screen s {
    @apply text-xl;
  }

  .optimistic & {
    @apply font-ods-display;
  }
}

.headline-gradient {
  @include primary-gradient(90deg);
  color: theme('colors.red');

  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/** Standard type element styles */
.rich-text {
  p {
    @apply sans-l6;
  }

  ul li {
    @apply relative pl-5 mb-3;

    &::before {
      @apply absolute left-0 bg-red;
      content: '';
      height: 1px;
      margin-top: -2px;
      top: theme('spacing.3');
      width: theme('spacing.3');
    }
  }

  a {
    @apply text-red;
  }

  .optimistic & {
    p {
      @apply font-ods-body text-3xs font-normal leading-body;
    }

    a {
      @apply text-black;

      &:hover {
        @apply text-black;
      }
    }

    ul li {
      &::before {
        @apply bg-ods-purple-core;
      }
    }
  }
}

.hang-quote {
  margin-left: -0.5em;
}

.subhead {
  @apply font-ods-mono text-5xs font-normal leading-tight tracking-widest uppercase;
}

strong {
  @apply font-bold;
}

/** Mixins */

/** @see https://css-tricks.com/snippets/css/fluid-typography/ */
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
