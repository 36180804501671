$duration: 0.55s;
$height: 790px;

.nav {
  @apply fixed top-0 left-0 right-0 z-50 select-none;
  height: 113px;

  &--open {
    height: auto;
  }

  &--scrolled {
    height: 71px;
  }

  &__shim {
    height: 113px;
  }

  &__scrim {
    @apply fixed top-0 left-0 right-0 bottom-0 bg-black-a50 z-20 opacity-0 pointer-events-none;
    transition: opacity $duration ease-in-out ($duration / 2);

    .nav--open & {
      @apply opacity-100;
      transition: opacity $duration ease-in-out;
    }
  }

  &__bg {
    @apply fixed bg-white w-full z-20 invisible;
    height: 100vh;
    transform: translateY(-100vh);
    transition: transform $duration ease-in-out ($duration / 2),
      visibility $duration linear $duration;

    @screen s {
      clip-path: polygon(0 0, 100% 0, 100% $height, 400px $height, 0 470px);
      height: $height;
      transform: translateY(-$height);
    }

    .nav--open & {
      @apply visible;

      transform: translateY(0);
      transition: transform $duration ease-in-out;
    }
  }

  &__bar {
    @apply fixed left-0 right-0 top-0 opacity-0;
    height: 64px;
    transition: opacity 0.25s;

    @screen xs {
      height: 71px;
    }

    .nav--open & {
      @apply bg-white opacity-100;
    }

    .nav--scrolled & {
      @apply bg-white opacity-100;
      border-bottom: 1px solid theme('colors.gray.1');
    }
  }

  &__content {
    transition: transform 0.25s ease-in-out;

    .nav--open & {
      @apply h-screen overflow-auto;
    }
  }

  &__overlay {
    @apply relative z-30 w-full;
    float: left;
    left: -9999px;
    top: 0;
    transition: left 0s linear $duration, visibility $duration linear;
    visibility: hidden;

    .nav--open & {
      float: none;
      left: 0;
      transition: none;
      visibility: visible;
    }
  }

  &__header {
    @apply flex pt-5 pb-8 relative z-30;
    transition: transform 0.25s ease-in-out;

    .nav--scrolled & {
      transform: translateY(-15px);

      @screen xs {
        transform: translateY(-10px);
      }

      @screen s {
        @apply pb-5;
      }
    }

    .nav--scrolled.nav--open & {
      transform: translateY(0);
    }
  }

  &__header .link.hidden {
    display: none;

    @screen s {
      display: inline-block;
    }
  }

  &__full-logo {
    @apply pointer-events-auto;
    transition: opacity 0.25s ease-in-out;

    .nav--scrolled & {
      @apply opacity-0 pointer-events-none;
    }

    .nav--open.nav--scrolled & {
      @apply opacity-100;
    }

    & svg path {
      transition: fill 0.35s ease, opacity 0.35s ease;
    }

    &:hover {
      & path:first-child {
        fill: theme('colors.red');
      }

      & path:last-child {
        opacity: 0.6;
      }
    }
  }

  &__full-logo-svg {
    @apply relative mb-2;
    width: 129px;
    height: 20px;

    & svg {
      @apply absolute;
      transition: opacity 0.75s ease 0s;
    }
  }

  &.nav--black:not(.nav--open) {
    & .logo--black {
      @apply opacity-0;
      transition-delay: .5s;
    }

    & .logo--white {
      @apply opacity-100;
    }
  }

  &.nav--split:not(.nav--open) {
    & .logo--black {
      @apply opacity-0;
    }

    & .logo--white {
      @apply opacity-100;
    }
  }

  &.nav--white {
    & .logo--black {
      @apply opacity-100;
    }

    & .logo--white {
      @apply opacity-0;
    }
  }

  &__subtext {
    @apply text-black transition-colors duration-500;
    transition-delay: 0s;

    .nav--white & {
      @apply text-black;
    }

    .nav--black:not(.nav--open) &,
    .nav--split:not(.nav--open) & {
      @apply text-white delay-500;
    }
  }

  &__scrolled-logo {
    @apply absolute opacity-0 pointer-events-none;
    height: 26px;
    left: 0;
    margin-top: -13px;
    top: 50%;
    transition: opacity 0.25s ease-in-out;
    width: 48px;

    .nav--scrolled & {
      @apply opacity-100 pointer-events-auto;
    }

    .nav--open.nav--scrolled & {
      @apply opacity-0 pointer-events-none;
    }
  }

  &__cta-links {
    a {
      transition: color .25s ease-in-out !important;
    }

    .nav--white &,
    .nav--split & {
      @apply text-black;
    }

    .nav--black:not(.nav--open):not(.nav--scrolled) & {
      @apply text-white;
    }
  }

  &__overlay-content {
    @apply w-full top-0 flex flex-col-reverse opacity-0 pointer-events-none;
    transition: opacity $duration ease-in-out;

    @screen s {
      @apply flex-row pt-8;
    }

    .nav--open & {
      @apply opacity-100 pointer-events-auto;
      transition: opacity $duration ease-in-out ($duration / 2);
    }
  }

  &__aside {
    @apply flex flex-wrap justify-between max-w-xs;

    @screen s {
      @apply flex-col max-w-none justify-start;
      width: 180px;

      & > * {
        @apply w-full flex-none;
        
        &:first-child {
          @apply w-full mb-4;
        }
      }
    }
  }

  &__links {
    @apply flex-1 flex justify-start flex-col;

    @screen s {
      @apply items-center flex-auto mb-8;
    }

    & .nav__links-border {
      @apply h-px w-full mb-8 bg-black bg-opacity-25;

      @screen s {
        @apply hidden;
      }
    }

    & .nav__links-main {
      @apply mb-6;

      &:hover a {
        @apply text-black-a50;
      }

      & a {
        @apply block relative opacity-0 text-xl font-display leading-tight pb-6;
        font-size: 48px;
        animation: fadeOut $duration ease-in-out forwards;
        transition: color .15s ease-in-out;

        &::after {
          @apply absolute block;
          @include primary-gradient(135deg);
          content: '';
          height: 1px;
          left: -5rem;
          top: calc(50% - 1rem);
          transform: scaleX(0);
          transform-origin: left;
          transition: transform 375ms cubic-bezier(0.215, 0.61, 0.355, 1);
          width: 4rem;
        }

        &:hover {
          @apply text-black;
        }

        &:hover::after {
          transform: scaleX(1);
        }

        &.active::after {
          transform: scaleX(1);
          transition-duration: 0s;
        }

        @screen s {
          @apply serif-l1;
        }

        .nav--open & {
          animation: slideIn 0.45s ease-in-out forwards;

          &:nth-child(1) {
            animation-delay: 0s;
          }
          &:nth-child(2) {
            animation-delay: 0.08s;
          }
          &:nth-child(3) {
            animation-delay: 0.16s;
          }
          &:nth-child(4) {
            animation-delay: 0.24s;
          }
          &:nth-child(5) {
            animation-delay: 0.32s;
          }
          &:nth-child(6) {
            animation-delay: 0.4s;
          }
          &:nth-child(7) {
            animation-delay: 0.48s;
          }
        }
      }
    }

    & .nav__links-secondary {
      @screen s {
        display: none;
      }

      @apply flex mb-2;

      & a {
        @apply block mb-6;

        &.start-a-project__link {
          @apply mr-8;
        }
      }
    }
  }

  .optimistic & {
    &__full-logo-svg {
      @apply mb-0;
      width: 219px;
      height: 29px;
  
      & svg {
        @apply absolute;
        transition: color 0.3s ease 0s;
      }
    }

    &.nav--scrolled {
      .nav__header {
        transform: none;
      }

      .nav__full-logo {
        @apply opacity-100 pointer-events-auto;
      }
    }

    &__links {
      & .nav__links-main {
        & a {
          @apply font-ods-display;

          &::after {
            @apply bg-ods-purple-light;
            background-image: none;
          }
        }
      }
    }
  }
}

@keyframes slideIn {
  0% {
    @apply opacity-0;
    transform: translateX(60px);
  }

  100% {
    @apply opacity-100;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  0% {
    @apply opacity-100;
  }

  100% {
    @apply opacity-0;
  }
}
