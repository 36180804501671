.transcript {
  height: 320px;

  &.full {
    @apply h-full;
  }

  table {
    tbody {
      tr {
        td {
          @apply py-4 align-top;

          p {
            @apply mb-3;
          }

          &:first-child {
            @apply w-40;

            p {
              @apply inline-block m-0;
            }
          }
        }
      }
    }
  }
}