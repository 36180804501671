@tailwind base;
@import 'fonts'; // The ordering of this @import is intentional
@tailwind components;
@tailwind utilities;

@import 'reset';
@import 'gradients';
@import 'typography';
@import 'layout';
@import 'animation';
@import 'links';
@import 'cms';
@import 'images';
@import 'lists';
@import 'form';
@import 'transitions';
@import 'loader';
