.portrait-video {
  &__video {
    width: 50%;

    @screen s {
      width: 65%;
    }

    & iframe {
      width: 100%;
    }
  }
}
