@charset "UTF-8";
@import url(/node_modules/swiper/css/swiper.min.css);
@import url(/node_modules/aos/dist/aos.css);
@tailwind base;
@font-face {
  font-family: 'Gelion';
  font-weight: 300;
  src: url(/dist/fonts/gelion-light.woff) format("woff"), url(/dist/fonts/gelion-light.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Gelion';
  font-weight: 500;
  src: url(/dist/fonts/gelion-regular.woff) format("woff"), url(/dist/fonts/gelion-regular.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Gelion';
  font-weight: 800;
  src: url(/dist/fonts/gelion-bold.woff) format("woff"), url(/dist/fonts/gelion-bold.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Apercu Mono Pro';
  font-weight: 600;
  src: url(/dist/fonts/apercu-mono-medium-pro.woff) format("woff"), url(/dist/fonts/apercu-mono-medium-pro.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'GT Super Display';
  font-weight: 800;
  src: url(/dist/fonts/gt-super-display-bold.woff) format("woff"), url(/dist/fonts/gt-super-display-bold.woff2) format("woff2");
  font-display: swap; }

@tailwind components;
@tailwind utilities;
body {
  @apply font-body;
  font-size: 16px;
  max-width: 100%;
  overflow-x: hidden; }

::selection {
  @apply bg-red text-white; }

.optimistic {
  @apply font-ods-body text-3xs;
  font-optical-sizing: auto; }
  .optimistic ::selection {
    @apply bg-ods-purple-core text-white;
    background-clip: none;
    -webkit-background-clip: none;
    -webkit-text-fill-color: theme("colors.white.default"); }

.bg-gradient-vertical {
  background-image: linear-gradient(180deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%); }

.bg-gradient-horizontal {
  background-image: linear-gradient(90deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%); }

.bg-gradient-angle {
  background-image: linear-gradient(135deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%); }

.bg-gradient--primary {
  background-image: linear-gradient(135deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%); }

.bg-gradient--secondary {
  background-image: linear-gradient(135deg, theme("colors.yellow") 0%, theme("colors.red") 100%); }

.bg-gradient--tertiary {
  background-image: linear-gradient(135deg, theme("colors.yellow") 0%, theme("colors.orange") 100%); }

/** Serif */
.serif-hero {
  @apply text-4xl font-display leading-tight antialiased; }
  .optimistic .serif-hero {
    @apply font-ods-display; }

.serif-l1 {
  @apply text-2xl font-display leading-tight; }
  .optimistic .serif-l1 {
    @apply font-ods-display; }

.serif-l2 {
  @apply text-m font-display leading-snug; }

@screen s {
  .serif-l2 {
    @apply text-xl; } }
  .optimistic .serif-l2 {
    @apply font-ods-display; }

.serif-l3 {
  @apply text-l font-display leading-normal; }
  .optimistic .serif-l3 {
    @apply font-ods-display; }

.serif-l4 {
  @apply text-s font-display leading-normal; }
  .optimistic .serif-l4 {
    @apply font-ods-display; }

/** Sans */
.sans-l1 {
  @apply text-xl font-body font-semibold leading-snug; }
  .optimistic .sans-l1 {
    @apply font-ods-body; }

.sans-l2 {
  @apply text-m font-body font-light leading-snug; }

@screen s {
  .sans-l2 {
    @apply text-l; } }
  .optimistic .sans-l2 {
    @apply font-ods-body; }

.sans-l3 {
  @apply text-m font-body font-semibold leading-snug; }
  .optimistic .sans-l3 {
    @apply font-ods-body; }

.sans-l3-light {
  @apply sans-l3 font-light; }
  .optimistic .sans-l3-light {
    @apply font-ods-body; }

.sans-l4 {
  @apply text-xs font-body font-semibold leading-snug; }
  .optimistic .sans-l4 {
    @apply font-ods-body; }

.sans-l5 {
  @apply text-2xs font-body font-normal leading-tight; }
  .optimistic .sans-l5 {
    @apply font-ods-body; }

.sans-l5-bold {
  @apply text-2xs font-body font-semibold leading-tight; }
  .optimistic .sans-l5-bold {
    @apply font-ods-body; }

.sans-l6 {
  @apply text-3xs font-body font-normal leading-body; }
  .optimistic .sans-l6 {
    @apply font-ods-body; }

.sans-l6-bold {
  @apply sans-l6 font-semibold; }
  .optimistic .sans-l6-bold {
    @apply font-ods-body; }

.sans-l7 {
  @apply text-4xs font-body font-normal leading-body; }
  .optimistic .sans-l7 {
    @apply font-ods-body; }

/** Mono */
.mono-l1 {
  @apply text-4xs font-mono font-normal leading-snug uppercase;
  letter-spacing: 1px; }

/** Module Headings */
.module-heading {
  @apply text-m font-display leading-tight; }

@screen s {
  .module-heading {
    @apply text-xl; } }

@screen m {
  .module-heading {
    @apply text-2xl; } }
  .optimistic .module-heading h2.h1 {
    @apply font-ods-display text-m leading-tight; }

@screen s {
  .optimistic .module-heading h2.h1 {
    @apply text-xl; } }

@screen m {
  .optimistic .module-heading h2.h1 {
    @apply text-2xl; } }

.module-heading-med {
  @apply text-m font-display leading-tight; }

@screen s {
  .module-heading-med {
    @apply text-xl; } }
  .optimistic .module-heading-med {
    @apply font-ods-display; }

.headline-gradient {
  background-image: linear-gradient(90deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%);
  color: theme("colors.red");
  background-clip: text;
  -webkit-text-fill-color: transparent; }

/** Standard type element styles */
.rich-text p {
  @apply sans-l6; }

.rich-text ul li {
  @apply relative pl-5 mb-3; }
  .rich-text ul li::before {
    @apply absolute left-0 bg-red;
    content: '';
    height: 1px;
    margin-top: -2px;
    top: theme("spacing.3");
    width: theme("spacing.3"); }

.rich-text a {
  @apply text-red; }

.optimistic .rich-text p {
  @apply font-ods-body text-3xs font-normal leading-body; }

.optimistic .rich-text a {
  @apply text-black; }
  .optimistic .rich-text a:hover {
    @apply text-black; }

.optimistic .rich-text ul li::before {
  @apply bg-ods-purple-core; }

.hang-quote {
  margin-left: -0.5em; }

.subhead {
  @apply font-ods-mono text-5xs font-normal leading-tight tracking-widest uppercase; }

strong {
  @apply font-bold; }

/** Mixins */
/** @see https://css-tricks.com/snippets/css/fluid-typography/ */
.no-scroll {
  height: 100vh;
  overflow-y: hidden; }

.wrap {
  @apply mx-auto px-4 max-w-page; }

@screen s {
  .wrap {
    @apply px-8; } }

@screen m {
  .wrap {
    @apply px-16; } }

@screen l {
  .wrap {
    @apply px-8; } }

@screen xl {
  .wrap {
    @apply px-0; } }

.n-wrap {
  @apply -mx-4; }

@screen s {
  .n-wrap {
    @apply -mx-8; } }

@screen m {
  .n-wrap {
    @apply -mx-16; } }

@screen l {
  .n-wrap {
    @apply -mx-8; } }

@screen xl {
  .n-wrap {
    @apply mx-0; } }

.wrap-l {
  @apply mx-auto pl-4 max-w-page; }

@screen s {
  .wrap-l {
    @apply pl-8; } }

@screen m {
  .wrap-l {
    @apply pl-16; } }

@screen l {
  .wrap-l {
    @apply pl-8; } }

@screen xl {
  .wrap-l {
    @apply pl-0; } }

.wrap-r {
  @apply mx-auto pr-4 max-w-page; }

@screen s {
  .wrap-r {
    @apply pr-8; } }

@screen m {
  .wrap-r {
    @apply pr-16; } }

@screen l {
  .wrap-r {
    @apply pr-8; } }

@screen xl {
  .wrap-r {
    @apply pr-0; } }

.work-wrap {
  @apply mx-auto px-4;
  box-sizing: content-box;
  max-width: 800px; }

@screen s {
  .work-wrap {
    @apply px-8; } }

.insights-wrap {
  @apply mx-auto px-4;
  box-sizing: content-box;
  max-width: 800px; }

@screen s {
  .insights-wrap {
    @apply px-8; } }

.work-wrap-l {
  @apply mx-auto px-4;
  box-sizing: content-box;
  max-width: 1008px; }

@screen s {
  .work-wrap-l {
    @apply px-8; } }

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.prevent-animation *:not(.allow-animation),
.prevent-animation *:not(.allow-animation)::before,
.prevent-animation *:not(.allow-animation)::after {
  animation-duration: 0s !important;
  transition-duration: 0s !important; }

.link {
  @apply inline-block relative;
  transition: color 0.175s ease 0s; }
  .link::after {
    @apply absolute bottom-0 left-0 right-0 bg-red;
    content: '';
    height: 1px;
    transform: scaleX(0);
    transition: transform 375ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  .link:hover::after {
    @apply opacity-100;
    transform: scaleX(1); }
  .link--black::after {
    @apply bg-black;
    background-image: none; }
  .link.solid::after {
    @apply opacity-100;
    transform: scaleX(1);
    transition: none; }
  .link--ods::after {
    @apply bg-ods-purple-light; }

a:hover .link::after {
  @apply opacity-100;
  transform: scaleX(1); }

.external-link {
  @apply relative pr-4 bg-no-repeat bg-right;
  transition: color 0.175s; }
  .external-link::after {
    @apply absolute h-4 bottom-0 right-0 opacity-0 pr-4 bg-no-repeat bg-center bg-contain;
    content: '';
    margin-bottom: 2px;
    background-image: url("/dist/images/arrow-out.svg");
    transform: translate(0px, 4px);
    transition: opacity 0.375s, transform 0.375s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .external-link:hover::after {
    @apply opacity-100;
    transform: translate(4px, 0px); }
  .external-link.location-link::after {
    bottom: 3px; }
  .external-link.solid::before {
    @apply absolute left-0 bg-red opacity-100;
    bottom: -3px;
    content: '';
    height: 1px;
    right: 16px; }
  .external-link--ods {
    @apply pr-6; }
    .external-link--ods::after {
      @apply h-8 w-8 mb-0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjkiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOSAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjYwMzEgMTkuMDI3OEwxOC44NTI3IDEwLjc3ODIiIHN0cm9rZT0iIzg3NjZCMyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz4KPHBhdGggZD0iTTE5LjYyOTIgMTYuNTE3OEwxOS42MjkyIDEwLjAzNkwxMy4xNDc0IDEwLjAzNiIgc3Ryb2tlPSIjODc2NkIzIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K);
      top: -6px; }
    .external-link--ods.solid::before {
      @apply bg-ods-purple-light;
      right: 24px; }

.link-color {
  transition: color 0.175s; }
  .link-color:hover {
    color: theme("colors.red"); }

/** Background colors */
.cms-bg--red {
  background-color: theme("colors.red"); }

.cms-bg--coral {
  background-color: theme("colors.coral"); }

.cms-bg--orange {
  background-color: theme("colors.orange"); }

.cms-bg--yellow {
  background-color: theme("colors.yellow"); }

.cms-bg--lightYellow {
  background-color: theme("colors.lightYellow"); }

.cms-bg--white {
  background-color: theme("colors.white.default"); }

.cms-bg--lightGrey {
  background-color: theme("colors.gray.1"); }

.cms-bg--black {
  background-color: theme("colors.black.default"); }

.cms-bg--redPastel {
  @apply bg-ods-red-pastel bg-opacity-50; }

.cms-bg--purplePastel {
  @apply bg-ods-purple-pastel bg-opacity-50; }

.cms-bg--yellowPastel {
  @apply bg-ods-yellow-pastel bg-opacity-50; }

.cms-bg--bluePastel {
  @apply bg-ods-blue-pastel bg-opacity-50; }

.cms-bg--darkPurple {
  @apply bg-ods-purple-dark; }

.cms-bg--darkBlue {
  @apply bg-ods-blue-dark; }

.cms-bg--charcoal {
  @apply bg-charcoal; }

.optimistic .cms-bg--black {
  background-color: theme("colors.ods-purple.neutral"); }

/** Text colors */
.cms-color--red {
  color: theme("colors.red"); }

.cms-color--coral {
  color: theme("colors.coral"); }

.cms-color--orange {
  color: theme("colors.orange"); }

.cms-color--yellow {
  color: theme("colors.yellow"); }

.cms-color--lightYellow {
  color: theme("colors.lightYellow"); }

.cms-color--white {
  color: theme("colors.white.default"); }

.cms-color--lightGrey {
  color: theme("colors.gray.1"); }

.cms-color--black {
  color: theme("colors.black.default"); }

/** Padding */
.cms-space-top--l {
  @apply mt-32; }

@screen s {
  .cms-space-top--l {
    @apply mt-56; } }

.cms-space-top--m {
  @apply mt-20; }

@screen s {
  .cms-space-top--m {
    @apply mt-40; } }

.cms-space-top--s {
  @apply mt-6; }

@screen s {
  .cms-space-top--s {
    @apply mt-12; } }

.cms-space-top--none {
  @apply mt-0; }

.cms-space-top--ns {
  @apply -mt-12; }

.cms-space-top--nm {
  @apply -mt-40; }

.cms-space-top--nl {
  @apply -mt-40; }

@screen s {
  .cms-space-top--nl {
    @apply -mt-64; } }

.cms-space-bottom--l {
  @apply mb-32; }

@screen s {
  .cms-space-bottom--l {
    @apply mb-56; } }

.cms-space-bottom--m {
  @apply mb-20; }

@screen s {
  .cms-space-bottom--m {
    @apply mb-40; } }

.cms-space-bottom--s {
  @apply mb-6; }

@screen s {
  .cms-space-bottom--s {
    @apply mb-12; } }

.cms-space-bottom--none {
  @apply mb-0; }

.cms-space-bottom--ns {
  @apply -mb-12; }

.cms-space-bottom--nm {
  @apply -mb-40; }

.cms-space-bottom--nl {
  @apply -mb-32; }

@screen s {
  .cms-space-bottom--nl {
    @apply -mb-48; } }

/** ODS Padding */
.ods-space-top--l {
  @apply pt-20; }

@screen s {
  .ods-space-top--l {
    @apply pt-40; } }

.ods-space-top--m {
  @apply pt-12; }

@screen s {
  .ods-space-top--m {
    @apply pt-20; } }

.ods-space-top--s {
  @apply pt-6; }

@screen s {
  .ods-space-top--s {
    @apply pt-12; } }

.ods-space-top--none {
  @apply pt-0; }

.ods-space-top--ns {
  @apply -mt-12; }

.ods-space-top--nm {
  @apply -mt-20; }

.ods-space-top--nl {
  @apply -mt-40; }

@screen s {
  .ods-space-top--nl {
    @apply -mt-64; } }

.ods-space-bottom--l {
  @apply pb-20; }

@screen s {
  .ods-space-bottom--l {
    @apply pb-40; } }

.ods-space-bottom--m {
  @apply pb-12; }

@screen s {
  .ods-space-bottom--m {
    @apply pb-20; } }

.ods-space-bottom--s {
  @apply pb-6; }

@screen s {
  .ods-space-bottom--s {
    @apply pb-12; } }

.ods-space-bottom--none {
  @apply pb-0; }

.ods-space-bottom--ns {
  @apply -mb-12; }

.ods-space-bottom--nm {
  @apply -mb-20; }

.ods-space-bottom--nl {
  @apply -mb-32; }

@screen s {
  .ods-space-bottom--nl {
    @apply -mb-48; } }

.cover-image img {
  @apply absolute top-0 left-0 w-full h-full object-cover; }

@screen s {
  .bleed-right {
    flex-grow: 1;
    margin-left: auto;
    margin-right: calc(-50vw + 50%);
    padding-right: 0; } }

.bleed-right .rellax {
  margin-top: -5%; }

@screen s {
  .bleed-right .rellax {
    margin-top: -5%; } }

@screen s {
  .bleed-left {
    flex-grow: 1;
    margin-left: calc(-50vw + 50%);
    margin-right: auto;
    padding-left: 0;
    padding-left: 0; } }

.bleed-left .rellax {
  margin-top: -5%; }

@screen s {
  .bleed-left .rellax {
    margin-top: -5%; } }

.list-item {
  @apply relative pl-5;
  box-sizing: border-box; }
  .list-item::before {
    @apply absolute left-0 bg-red;
    content: '';
    height: 1px;
    top: 8px;
    width: 12px; }
  .optimistic .list-item::before {
    @apply bg-ods-purple-light; }

input[type='text'],
input[type='email'] {
  @apply block w-full border-b border-black-default20 font-body font-semibold text-3xs;
  color: inherit;
  height: 36px;
  background-color: transparent; }
  .text-white input[type='text'], .text-white
  input[type='email'] {
    @apply border-white-a50; }
  .text-white input[type='text']::placeholder, .text-white
  input[type='email']::placeholder {
    @apply text-white; }
  .text-black input[type='text']::placeholder, .text-black
  input[type='email']::placeholder {
    @apply text-black; }
  input[type='text']:focus,
  input[type='email']:focus {
    @apply outline-none border-red; }
  input[type='text'].input--l,
  input[type='email'].input--l {
    @apply text-m font-light text-black; }

.radio-toggles {
  @apply w-full mx-auto flex;
  max-width: 420px; }

.radio-toggle {
  @apply relative block w-1/2 font-body text-3xs font-bold text-center cursor-pointer;
  height: 42px; }
  .radio-toggle span {
    @apply absolute w-full h-full top-0 left-0 border-b border-black-default20;
    transition: color 0.5s, border-color 0.5s; }
  .radio-toggle input[type='radio'] {
    @apply opacity-0 absolute;
    width: 0.1px;
    height: 0.1px; }
  .radio-toggle input[type='radio']:checked + span {
    @apply text-red border-red; }
  .radio-toggle-ods {
    @apply font-ods-body; }
    .radio-toggle-ods input[type='radio']:checked + span {
      @apply text-ods-purple-light border-ods-purple-light; }

.g-recaptcha {
  /* @apply hidden; */ }

.ods-contact-form,
.ods-modal-form {
  @apply font-ods-body; }
  .ods-contact-form input[type='text'],
  .ods-contact-form input[type='email'],
  .ods-contact-form textarea,
  .ods-modal-form input[type='text'],
  .ods-modal-form input[type='email'],
  .ods-modal-form textarea {
    @apply block w-full border-b border-black-a50 py-4 font-ods-body text-3xs; }
    .ods-contact-form input[type='text']::placeholder,
    .ods-contact-form input[type='email']::placeholder,
    .ods-contact-form textarea::placeholder,
    .ods-modal-form input[type='text']::placeholder,
    .ods-modal-form input[type='email']::placeholder,
    .ods-modal-form textarea::placeholder {
      @apply text-black opacity-75; }
    .ods-contact-form input[type='text']:focus,
    .ods-contact-form input[type='email']:focus,
    .ods-contact-form textarea:focus,
    .ods-modal-form input[type='text']:focus,
    .ods-modal-form input[type='email']:focus,
    .ods-modal-form textarea:focus {
      @apply outline-none border-ods-purple-core; }
    .ods-contact-form input[type='text'].input--l,
    .ods-contact-form input[type='email'].input--l,
    .ods-contact-form textarea.input--l,
    .ods-modal-form input[type='text'].input--l,
    .ods-modal-form input[type='email'].input--l,
    .ods-modal-form textarea.input--l {
      @apply text-m font-light text-black; }
  .ods-contact-form input[type='text'],
  .ods-contact-form input[type='email'],
  .ods-modal-form input[type='text'],
  .ods-modal-form input[type='email'] {
    @apply h-12; }
  .ods-contact-form textarea,
  .ods-modal-form textarea {
    @apply h-16 pb-0 pt-3; }
  .ods-contact-form input#zip,
  .ods-modal-form input#zip {
    display: none; }
  .ods-contact-form .btn-ods,
  .ods-modal-form .btn-ods {
    width: max-content; }
  .ods-contact-form .file-input,
  .ods-modal-form .file-input {
    @apply cursor-pointer; }
    .ods-contact-form .file-input input[type='file'],
    .ods-modal-form .file-input input[type='file'] {
      @apply opacity-0 overflow-hidden absolute;
      hieght: 0.1px;
      width: 0.1px;
      z-index: -1; }
  .ods-contact-form .errors,
  .ods-modal-form .errors {
    @apply text-ods-red-core; }
  .ods-contact-form .submit-success-message,
  .ods-modal-form .submit-success-message {
    @apply text-2xs mt-8 text-ods-purple-core; }

.ods-newsletter-form {
  @apply font-ods-body; }
  .ods-newsletter-form input[type='email'],
  .ods-newsletter-form input[type='email']::placeholder {
    @apply text-black opacity-75 font-ods-body text-3xs; }
    .ods-newsletter-form input[type='email']:focus,
    .ods-newsletter-form input[type='email']::placeholder:focus {
      @apply outline-none border-black; }
  .ods-newsletter-form input[type='email']:focus {
    @apply outline-none border-black; }
  .ods-newsletter-form textarea {
    @apply hidden; }
  .ods-newsletter-form .btn-submit {
    @apply cursor-pointer bg-center bg-no-repeat outline-none;
    height: 24px;
    background-color: transparent; }
    .ods-newsletter-form .btn-submit:focus {
      @apply outline-none; }
    .ods-newsletter-form .btn-submit:active {
      transform: scale(0.9); }
  .ods-newsletter-form .errors {
    @apply text-ods-red-core font-semibold opacity-60 text-6xs; }
  .ods-newsletter-form + .legal-consent-container {
    @apply opacity-60 text-black text-5xs; }
    .ods-newsletter-form + .legal-consent-container a {
      @apply text-black underline font-normal;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px; }
      .ods-newsletter-form + .legal-consent-container a:hover {
        @apply cursor-pointer; }
  footer .ods-newsletter-form input[type='email'],
  footer .ods-newsletter-form input[type='email']::placeholder {
    @apply text-white; }
  footer .ods-newsletter-form + .legal-consent-container {
    @apply hidden; }
  .ods-newsletter-form div:focus-within {
    @apply border-white; }

.pipedriveWebForms.get-in-touch {
  @apply mx-auto;
  max-width: 480px; }

[data-aos='fade'],
[data-aos='fade-up'],
[data-aos='zoom-out'] {
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1) !important;
  transition-duration: 1.25s !important; }

.rellax.h-full-plus > img {
  @apply -mt-12; }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.barba-container {
  @apply relative z-10; }

.barba-enter {
  animation: fade-in 600ms ease; }

.loader {
  @apply fixed top-0 left-0 w-full h-full pointer-events-none opacity-0;
  z-index: 999;
  transition: opacity 0.25s ease; }
  .loader::before, .loader::after {
    @apply absolute w-full h-full;
    content: '';
    transform: translateY(100%);
    transition: transform 0.55s ease-in-out; }
  .loader::before {
    @apply z-10;
    background-image: linear-gradient(180deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%);
    transition-delay: 0s; }
  .loader:after {
    @apply z-20 bg-white;
    transition-delay: 0.25s; }
  .transitioning .loader::before, .transitioning .loader::after {
    transform: translateY(0); }
  .optimistic .loader::before {
    @apply bg-ods-purple-core;
    background-image: none; }

.barba-wrapper {
  @apply z-10; }

.show-loader .loader {
  @apply opacity-100; }

/** Global modules */
.button {
  @apply inline-block relative pt-5 cursor-pointer outline-none;
  margin: 0 38px;
  padding-bottom: 1.125rem;
  transition: color 0.25s, padding 0.25s; }
  .button:hover {
    @apply px-2 text-white; }
    .button:hover .background {
      @apply opacity-100; }
  .button:focus, .button:active {
    @apply outline-none; }
  .button.button--light {
    color: theme("colors.black.default"); }
    .button.button--light:hover .button__text {
      color: theme("colors.white.default"); }
  .button.button--dark {
    color: theme("colors.white.default"); }
    .button.button--dark:hover .button__text {
      color: theme("colors.white.default"); }
  .button.button--grad {
    color: theme("colors.white.default"); }
    .button.button--grad:hover .button__text {
      color: theme("colors.red"); }
    .button.button--grad .tess-left .stroke,
    .button.button--grad .tess-right .stroke,
    .button.button--grad .center .stroke {
      stroke: theme("colors.white.default"); }
    .button.button--grad .background .tess-left .back-fill,
    .button.button--grad .background .tess-right .back-fill,
    .button.button--grad .background .center .back-fill {
      fill: theme("colors.white.default"); }
  .button.button--solid {
    color: theme("colors.red"); }
    .button.button--solid .background {
      @apply opacity-100; }
    .button.button--solid .tess-left .stroke,
    .button.button--solid .tess-right .stroke,
    .button.button--solid .center .stroke {
      stroke: theme("colors.white.default"); }
    .button.button--solid .background .tess-left .back-fill,
    .button.button--solid .background .tess-right .back-fill,
    .button.button--solid .background .center .back-fill {
      fill: theme("colors.white.default"); }
  .button.button--full-width-mobile {
    @apply text-center box-border pt-3 pb-2;
    width: calc(100% - 76px); }
    .button.button--full-width-mobile svg.tess-left {
      left: -37px; }
    .button.button--full-width-mobile svg.tess-right {
      right: -37px; }
    .button.button--full-width-mobile:hover {
      @apply px-0; }

@screen s {
  .button.button--full-width-mobile {
    @apply w-auto pt-5;
    padding-bottom: 1.125rem; } }
  .button .background {
    @apply absolute top-0 left-0 w-full h-full opacity-0;
    transition: opacity 0.25s; }
  .button svg.center {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .button svg.tess {
    position: absolute;
    height: 100%;
    top: 0; }
  .button svg.tess-left {
    left: -38px; }
  .button svg.tess-right {
    right: -38px; }
  .button .stroke {
    fill: rgba(255, 39, 68, 0);
    stroke: url(#button-gradient) theme("colors.red");
    stroke-miterlimit: 10;
    stroke-width: 1px;
    transition: fill 0.25s; }
  .button .tess-left .stroke {
    stroke: theme("colors.coralGradient"); }
  @supports (-ms-ime-align: auto) {
    .button .tess-left .stroke {
      stroke: theme("colors.red"); } }
  .button .tess-right .stroke {
    stroke: theme("colors.red"); }
  .button .background .back-fill {
    fill: url(#button-gradient) theme("colors.red"); }
  .button .background .tess-left .back-fill {
    fill: theme("colors.coralGradient"); }
  .button .background .tess-right .back-fill {
    fill: theme("colors.red"); }
  .button .button__text {
    @apply relative font-body font-bold leading-body text-2xs-acc; }

.btn-ods {
  @apply relative rounded-full inline-block text-3xs font-semibold transition-transform-colors duration-300 px-6 py-3; }
  .btn-ods::after {
    content: '';
    @apply absolute w-full h-full border border-solid rounded-full top-0 left-0 transition-transform-colors duration-300; }
  .btn-ods:hover::after {
    @apply transform scale-x-110; }
  .btn-ods.btn-solid.btn-dark {
    @apply text-white; }
    .btn-ods.btn-solid.btn-dark::after {
      @apply bg-ods-purple-core border-ods-purple-core; }
    .btn-ods.btn-solid.btn-dark:hover {
      @apply text-ods-purple-core; }
      .btn-ods.btn-solid.btn-dark:hover::after {
        @apply bg-white border-white; }
  .btn-ods.btn-solid.btn-light {
    @apply text-ods-purple-core; }
    .btn-ods.btn-solid.btn-light::after {
      @apply bg-white border-white; }
    .btn-ods.btn-solid.btn-light:hover {
      @apply text-white; }
      .btn-ods.btn-solid.btn-light:hover::after {
        @apply bg-ods-purple-core border-ods-purple-core; }
  .btn-ods.btn-footer-cta {
    @apply text-ods-purple-core; }
    .btn-ods.btn-footer-cta::after {
      @apply bg-white border-white; }
    .btn-ods.btn-footer-cta:hover {
      @apply text-white; }
      .btn-ods.btn-footer-cta:hover::after {
        @apply bg-ods-purple-core; }
  .btn-ods.btn-outline.btn-dark {
    @apply text-ods-purple-core; }
    .btn-ods.btn-outline.btn-dark::after {
      @apply border-ods-purple-core; }
    .btn-ods.btn-outline.btn-dark:hover {
      @apply text-white; }
      .btn-ods.btn-outline.btn-dark:hover::after {
        @apply bg-ods-purple-core; }
  .btn-ods.btn-outline.btn-light {
    @apply text-white; }
    .btn-ods.btn-outline.btn-light::after {
      @apply border-white; }
    .btn-ods.btn-outline.btn-light:hover {
      @apply text-ods-purple-core; }
      .btn-ods.btn-outline.btn-light:hover::after {
        @apply bg-white; }
  .btn-ods.external-link::after {
    @apply hidden; }

.nav {
  @apply fixed top-0 left-0 right-0 z-50 select-none;
  height: 113px; }
  .nav--open {
    height: auto; }
  .nav--scrolled {
    height: 71px; }
  .nav__shim {
    height: 113px; }
  .nav__scrim {
    @apply fixed top-0 left-0 right-0 bottom-0 bg-black-a50 z-20 opacity-0 pointer-events-none;
    transition: opacity 0.55s ease-in-out 0.275s; }
    .nav--open .nav__scrim {
      @apply opacity-100;
      transition: opacity 0.55s ease-in-out; }
  .nav__bg {
    @apply fixed bg-white w-full z-20 invisible;
    height: 100vh;
    transform: translateY(-100vh);
    transition: transform 0.55s ease-in-out 0.275s, visibility 0.55s linear 0.55s; }

@screen s {
  .nav__bg {
    clip-path: polygon(0 0, 100% 0, 100% 790px, 400px 790px, 0 470px);
    height: 790px;
    transform: translateY(-790px); } }
    .nav--open .nav__bg {
      @apply visible;
      transform: translateY(0);
      transition: transform 0.55s ease-in-out; }
  .nav__bar {
    @apply fixed left-0 right-0 top-0 opacity-0;
    height: 64px;
    transition: opacity 0.25s; }

@screen xs {
  .nav__bar {
    height: 71px; } }
    .nav--open .nav__bar {
      @apply bg-white opacity-100; }
    .nav--scrolled .nav__bar {
      @apply bg-white opacity-100;
      border-bottom: 1px solid theme("colors.gray.1"); }
  .nav__content {
    transition: transform 0.25s ease-in-out; }
    .nav--open .nav__content {
      @apply h-screen overflow-auto; }
  .nav__overlay {
    @apply relative z-30 w-full;
    float: left;
    left: -9999px;
    top: 0;
    transition: left 0s linear 0.55s, visibility 0.55s linear;
    visibility: hidden; }
    .nav--open .nav__overlay {
      float: none;
      left: 0;
      transition: none;
      visibility: visible; }
  .nav__header {
    @apply flex pt-5 pb-8 relative z-30;
    transition: transform 0.25s ease-in-out; }
    .nav--scrolled .nav__header {
      transform: translateY(-15px); }

@screen xs {
  .nav--scrolled .nav__header {
    transform: translateY(-10px); } }

@screen s {
  .nav--scrolled .nav__header {
    @apply pb-5; } }
    .nav--scrolled.nav--open .nav__header {
      transform: translateY(0); }
  .nav__header .link.hidden {
    display: none; }

@screen s {
  .nav__header .link.hidden {
    display: inline-block; } }
  .nav__full-logo {
    @apply pointer-events-auto;
    transition: opacity 0.25s ease-in-out; }
    .nav--scrolled .nav__full-logo {
      @apply opacity-0 pointer-events-none; }
    .nav--open.nav--scrolled .nav__full-logo {
      @apply opacity-100; }
    .nav__full-logo svg path {
      transition: fill 0.35s ease, opacity 0.35s ease; }
    .nav__full-logo:hover path:first-child {
      fill: theme("colors.red"); }
    .nav__full-logo:hover path:last-child {
      opacity: 0.6; }
  .nav__full-logo-svg {
    @apply relative mb-2;
    width: 129px;
    height: 20px; }
    .nav__full-logo-svg svg {
      @apply absolute;
      transition: opacity 0.75s ease 0s; }
  .nav.nav--black:not(.nav--open) .logo--black {
    @apply opacity-0;
    transition-delay: .5s; }
  .nav.nav--black:not(.nav--open) .logo--white {
    @apply opacity-100; }
  .nav.nav--split:not(.nav--open) .logo--black {
    @apply opacity-0; }
  .nav.nav--split:not(.nav--open) .logo--white {
    @apply opacity-100; }
  .nav.nav--white .logo--black {
    @apply opacity-100; }
  .nav.nav--white .logo--white {
    @apply opacity-0; }
  .nav__subtext {
    @apply text-black transition-colors duration-500;
    transition-delay: 0s; }
    .nav--white .nav__subtext {
      @apply text-black; }
    .nav--black:not(.nav--open) .nav__subtext,
    .nav--split:not(.nav--open) .nav__subtext {
      @apply text-white delay-500; }
  .nav__scrolled-logo {
    @apply absolute opacity-0 pointer-events-none;
    height: 26px;
    left: 0;
    margin-top: -13px;
    top: 50%;
    transition: opacity 0.25s ease-in-out;
    width: 48px; }
    .nav--scrolled .nav__scrolled-logo {
      @apply opacity-100 pointer-events-auto; }
    .nav--open.nav--scrolled .nav__scrolled-logo {
      @apply opacity-0 pointer-events-none; }
  .nav__cta-links a {
    transition: color .25s ease-in-out !important; }
  .nav--white .nav__cta-links,
  .nav--split .nav__cta-links {
    @apply text-black; }
  .nav--black:not(.nav--open):not(.nav--scrolled) .nav__cta-links {
    @apply text-white; }
  .nav__overlay-content {
    @apply w-full top-0 flex flex-col-reverse opacity-0 pointer-events-none;
    transition: opacity 0.55s ease-in-out; }

@screen s {
  .nav__overlay-content {
    @apply flex-row pt-8; } }
    .nav--open .nav__overlay-content {
      @apply opacity-100 pointer-events-auto;
      transition: opacity 0.55s ease-in-out 0.275s; }
  .nav__aside {
    @apply flex flex-wrap justify-between max-w-xs; }

@screen s {
  .nav__aside {
    @apply flex-col max-w-none justify-start;
    width: 180px; }
    .nav__aside > * {
      @apply w-full flex-none; }
      .nav__aside > *:first-child {
        @apply w-full mb-4; } }
  .nav__links {
    @apply flex-1 flex justify-start flex-col; }

@screen s {
  .nav__links {
    @apply items-center flex-auto mb-8; } }
    .nav__links .nav__links-border {
      @apply h-px w-full mb-8 bg-black bg-opacity-25; }

@screen s {
  .nav__links .nav__links-border {
    @apply hidden; } }
    .nav__links .nav__links-main {
      @apply mb-6; }
      .nav__links .nav__links-main:hover a {
        @apply text-black-a50; }
      .nav__links .nav__links-main a {
        @apply block relative opacity-0 text-xl font-display leading-tight pb-6;
        font-size: 48px;
        animation: fadeOut 0.55s ease-in-out forwards;
        transition: color .15s ease-in-out; }
        .nav__links .nav__links-main a::after {
          @apply absolute block;
          background-image: linear-gradient(135deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%);
          content: '';
          height: 1px;
          left: -5rem;
          top: calc(50% - 1rem);
          transform: scaleX(0);
          transform-origin: left;
          transition: transform 375ms cubic-bezier(0.215, 0.61, 0.355, 1);
          width: 4rem; }
        .nav__links .nav__links-main a:hover {
          @apply text-black; }
        .nav__links .nav__links-main a:hover::after {
          transform: scaleX(1); }
        .nav__links .nav__links-main a.active::after {
          transform: scaleX(1);
          transition-duration: 0s; }

@screen s {
  .nav__links .nav__links-main a {
    @apply serif-l1; } }
        .nav--open .nav__links .nav__links-main a {
          animation: slideIn 0.45s ease-in-out forwards; }
          .nav--open .nav__links .nav__links-main a:nth-child(1) {
            animation-delay: 0s; }
          .nav--open .nav__links .nav__links-main a:nth-child(2) {
            animation-delay: 0.08s; }
          .nav--open .nav__links .nav__links-main a:nth-child(3) {
            animation-delay: 0.16s; }
          .nav--open .nav__links .nav__links-main a:nth-child(4) {
            animation-delay: 0.24s; }
          .nav--open .nav__links .nav__links-main a:nth-child(5) {
            animation-delay: 0.32s; }
          .nav--open .nav__links .nav__links-main a:nth-child(6) {
            animation-delay: 0.4s; }
          .nav--open .nav__links .nav__links-main a:nth-child(7) {
            animation-delay: 0.48s; }
    .nav__links .nav__links-secondary {
      @apply flex mb-2; }

@screen s {
  .nav__links .nav__links-secondary {
    display: none; } }
      .nav__links .nav__links-secondary a {
        @apply block mb-6; }
        .nav__links .nav__links-secondary a.start-a-project__link {
          @apply mr-8; }
  .optimistic .nav__full-logo-svg {
    @apply mb-0;
    width: 219px;
    height: 29px; }
    .optimistic .nav__full-logo-svg svg {
      @apply absolute;
      transition: color 0.3s ease 0s; }
  .optimistic .nav.nav--scrolled .nav__header {
    transform: none; }
  .optimistic .nav.nav--scrolled .nav__full-logo {
    @apply opacity-100 pointer-events-auto; }
  .optimistic .nav__links .nav__links-main a {
    @apply font-ods-display; }
    .optimistic .nav__links .nav__links-main a::after {
      @apply bg-ods-purple-light;
      background-image: none; }

@keyframes slideIn {
  0% {
    @apply opacity-0;
    transform: translateX(60px); }
  100% {
    @apply opacity-100;
    transform: translateX(0); } }

@keyframes fadeOut {
  0% {
    @apply opacity-100; }
  100% {
    @apply opacity-0; } }

.menu-button {
  @apply relative block;
  height: 24px;
  width: 24px;
  transition: transform 0.25s ease-in-out; }
  .menu-button::before, .menu-button::after {
    @apply absolute bg-black left-0 top-0 pointer-events-none;
    transition: all 0.275s ease, background-color 0.25s ease;
    content: '';
    height: 1px;
    width: 24px; }
    .nav--white .menu-button::before,
    .nav--split .menu-button::before, .nav--white .menu-button::after,
    .nav--split .menu-button::after {
      @apply bg-black; }
    .nav--black:not(.nav--open):not(.nav--scrolled) .menu-button::before, .nav--black:not(.nav--open):not(.nav--scrolled) .menu-button::after {
      @apply bg-white;
      transition: all 0.275s ease, background-color 0.25s ease; }
  .menu-button::before {
    animation: barBeforeClose 0.55s ease-in-out forwards;
    transform: translateY(8px); }
  .menu-button::after {
    animation: barAfterClose 0.55s ease-in-out forwards;
    transform: translateY(15px); }
  .menu-button:hover::before, .menu-button:hover::after {
    @apply bg-red; }
  .menu-button--open::before {
    animation: barBefore 0.55s ease-in-out forwards; }
  .menu-button--open::after {
    animation: barAfter 0.55s ease-in-out forwards; }
  .menu-button--ods:hover::before, .menu-button--ods:hover::after {
    @apply bg-ods-purple-light; }

@keyframes barBefore {
  0% {
    transform: translateY(8px) rotate(0deg); }
  40% {
    transform: translateY(11.5px) rotate(0deg); }
  60% {
    transform: translateY(11.5px) rotate(0deg); }
  100% {
    transform: translateY(11.5px) rotate(45deg); } }

@keyframes barBeforeClose {
  0% {
    transform: translateY(11.5px) rotate(45deg); }
  40% {
    transform: translateY(11.5px) rotate(0deg); }
  60% {
    transform: translateY(11.5px) rotate(0deg); }
  100% {
    transform: translateY(8px) rotate(0deg); } }

@keyframes barAfter {
  0% {
    transform: translateY(15px) rotate(0deg); }
  40% {
    transform: translateY(11.5px) rotate(0deg); }
  60% {
    transform: translateY(11.5px) rotate(0deg); }
  100% {
    transform: translateY(11.5px) rotate(-45deg); } }

@keyframes barAfterClose {
  0% {
    transform: translateY(11.5px) rotate(-45deg); }
  40% {
    transform: translateY(11.5px) rotate(0deg); }
  60% {
    transform: translateY(11.5px) rotate(0deg); }
  100% {
    transform: translateY(15px) rotate(0deg); } }

.nav:not(.nav--open) .menu-button:hover::before {
  top: -2px; }

.nav:not(.nav--open) .menu-button:hover::after {
  top: 2px; }

@screen s {
  @apply mr-8; }

@screen m {
  @apply mr-16; }

@screen l {
  @apply mr-8; }

@screen l {
  @apply mr-0; }

.footer {
  @apply relative; }
  .footer__logo-bg {
    background-image: url("/dist/images/substantial.svg");
    background-position: -32px 0;
    background-repeat: no-repeat;
    background-size: 1490px auto;
    height: 155px;
    opacity: 0.15;
    width: 100%; }

@screen l {
  .footer__logo-bg {
    background-position: center 0; } }
  .footer .footer-logo {
    opacity: 0.1;
    pointer-events: none; }
  .footer__tagline {
    @apply serif-l3 mb-2 leading-none; }

@screen m {
  .footer__tagline {
    @apply text-xl; } }
  .footer--white {
    @apply bg-white; }
  .footer--lightGrey {
    @apply bg-gray-1; }
  .footer--black {
    @apply bg-black; }
  .footer .external-link::after {
    bottom: 3px; }
  .optimistic .footer .external-link::after {
    @apply bottom-0; }
  .footer .site-switcher-divider {
    /* width: 320px;

    @screen m {
      @apply w-full;
    } */ }
  .footer .pipedriveWebForms {
    @apply ml-0 mr-auto;
    max-width: 480px; }
  .footer .optimistic-logo {
    height: 44px;
    width: auto; }

.image-slider {
  @apply overflow-hidden; }
  .image-slider__image {
    @apply absolute top-0 left-0 w-full h-full overflow-hidden z-0; }
  .image-slider__nav-numbers {
    @apply relative flex justify-between mono-l1 pb-6; }
  .image-slider__nav-total {
    @apply absolute text-black-a65;
    left: 136px; }
    .cms-bg--black .image-slider__nav-total {
      @apply text-white-a50; }
  .image-slider__nav-pages {
    @apply flex; }
  .image-slider__nav-page {
    @apply relative mr-6 bg-black-a25;
    height: 2px;
    width: 44px; }
    .cms-bg--black .image-slider__nav-page {
      @apply relative mr-6 bg-white-a50; }
    .image-slider__nav-page:last-child {
      @apply mr-0; }
    .image-slider__nav-page::after {
      @apply absolute top-0 left-0 h-full w-full bg-red;
      content: '';
      transform: scaleX(0);
      transform-origin: 0 center;
      transition: none; }
    .image-slider__nav-page.active::after {
      transform: scaleX(1);
      transition: transform 5s linear; }

.js-slider__slide {
  @apply absolute;
  transition: transform 0.55s ease, opacity 0.55s ease; }

@screen s {
  .js-slider__slide {
    @apply px-0; } }

.js-slider__nav--current, .js-slider__nav--total {
  @apply font-mono; }

.js-slider__nav--total {
  @apply opacity-50;
  margin-left: 25%; }

.js-slider__nav-blips {
  @apply flex; }

.js-slider__nav-blip {
  @apply relative mr-6 bg-white-a50;
  height: 2px;
  width: 44px;
  cursor: pointer; }
  .js-slider__nav-blip:hover {
    @apply bg-white; }
  .js-slider__nav-blip:last-child {
    @apply mr-0; }
  .js-slider__nav-blip::before {
    @apply absolute left-0 right-0;
    content: '';
    height: 16px;
    top: -7px; }
  .js-slider__nav-blip::after {
    @apply absolute top-0 left-0 h-full w-full bg-red;
    content: '';
    transform: scaleX(0);
    transform-origin: 0 center;
    transition: none; }
  .js-slider__nav-blip.active::after {
    transform: scaleX(1);
    transition: transform linear;
    transition-duration: inherit; }

.optimistic .js-slider__nav--current, .optimistic .js-slider__nav--total {
  @apply font-ods-mono text-5xs font-normal leading-snug tracking-widest uppercase; }

.optimistic .js-slider__nav-blip::after {
  @apply bg-ods-purple-core; }

.cms-bg--white .js-slider__nav-blip {
  @apply bg-black-a25; }

.js-slider__transition--fade .js-slider__slide {
  @apply opacity-0; }

.js-slider--backwards .js-slider__transition--fade .prev {
  transform: translateX(35px); }

.js-slider--backwards .js-slider__transition--fade .active {
  @apply opacity-100;
  transform: translateX(0); }

.js-slider--forwards .js-slider__transition--fade .prev {
  transform: translateX(-35px); }

.js-slider--forwards .js-slider__transition--fade .active {
  @apply opacity-100;
  transform: translateX(0); }

.js-slider__transition--slide .js-slider__slide {
  @apply opacity-0; }

.js-slider--backwards .js-slider__transition--slide .prev {
  @apply z-10;
  transform: translateX(0);
  animation: none; }

.js-slider--backwards .js-slider__transition--slide .active {
  @apply z-30 opacity-100;
  animation: sliderSlideInBack 0.55s ease 0s forwards; }

@keyframes sliderSlideInBack {
  from {
    transform: translateX(-100%); }
  to {
    transform: translateX(0); } }
  .js-slider--backwards .js-slider__transition--slide .active img {
    animation: sliderCounterSlideInBack 0.55s ease 0s forwards; }

@keyframes sliderCounterSlideInBack {
  from {
    transform: translateX(100%); }
  to {
    transform: translateX(0); } }

.js-slider--forwards .js-slider__transition--slide .prev:not(last-child) {
  @apply z-10;
  transform: translateX(0);
  animation: none; }

.js-slider--forwards .js-slider__transition--slide .active {
  @apply z-30 opacity-100;
  animation: sliderSlideIn 0.55s ease 0s forwards; }

@keyframes sliderSlideIn {
  from {
    transform: translateX(100%); }
  to {
    transform: translateX(0); } }
  .js-slider--forwards .js-slider__transition--slide .active img {
    animation: sliderCounterSlideIn 0.55s ease 0s forwards; }

@keyframes sliderCounterSlideIn {
  from {
    transform: translateX(-100%); }
  to {
    transform: translateX(0); } }

/** Modules */
.ff-image-grid-text {
  @apply relative; }
  .ff-image-grid-text::before, .ff-image-grid-text::after {
    @apply absolute left-0 w-full z-0 bg-white;
    content: '';
    height: calc(1rem + 7.5vw); }
  .ff-image-grid-text::before {
    @apply top-0; }
  .ff-image-grid-text::after {
    @apply bottom-0 bg-black; }
  .ff-image-grid-text .text {
    @apply flex flex-col justify-center;
    box-sizing: border-box;
    height: 390px; }

@screen s {
  .ff-image-grid-text__headline {
    @apply text-m; } }

@screen m {
  .ff-image-grid-text__headline {
    @apply text-m; } }

@screen l {
  .ff-image-grid-text__headline {
    @apply text-xl; } }
  .ff-image-grid-text__text-wrap {
    @apply pt-16 w-full;
    padding-bottom: 7rem; }

@screen s {
  .ff-image-grid-text__text-wrap {
    @apply py-32;
    width: calc(43.5% - 1rem); } }
  .ff-image-grid-text__images {
    @apply relative flex items-center w-full ml-0 z-0; }

@screen s {
  .ff-image-grid-text__images {
    @apply z-10 mr-0;
    width: calc(56.5% - 1rem);
    left: -11%; } }
  .ff-image-grid-text__image-col--1 {
    @apply mr-8;
    width: calc(56% - 1rem); }
    .ff-image-grid-text__image-col--1 .ff-image-grid-text__image--1 {
      @apply block pb-8 w-full; }
    .ff-image-grid-text__image-col--1 .ff-image-grid-text__image--2 {
      @apply block;
      width: 125%;
      margin-left: -25%; }
  .ff-image-grid-text__image-col--2 {
    width: calc(44% - 1rem); }
    .ff-image-grid-text__image-col--2 .ff-image-grid-text__image--1 {
      @apply block pb-8;
      width: 75; }
    .ff-image-grid-text__image-col--2 .ff-image-grid-text__image--2 {
      @apply block;
      width: 125%; }
  .ff-image-grid-text__image {
    @apply overflow-hidden; }
  .ff-image-grid-text .shape {
    @apply absolute bottom-0 right-0;
    width: 75%;
    z-index: 0; }

@screen m {
  .ff-image-grid-text .shape {
    width: 33.33%; } }
    .ff-image-grid-text .shape svg {
      fill: theme("colors.white.default"); }
      .ff-image-grid-text .shape svg path.dec {
        animation: rotate 40s linear infinite;
        transform-origin: 100% 100%; }

@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(-360deg); } }

.ff-image-text {
  @apply relative; }
  .ff-image-text__bg::after {
    @apply absolute h-full w-full top-0 left-0 bg-black-pure opacity-75;
    content: ''; }
  .ff-image-text .icon {
    width: 48px;
    height: 48px; }
  .ff-image-text__image {
    @apply relative; }
    .ff-image-text__image--standard {
      @apply m-0; }

@screen s {
  .ff-image-text__image--standard {
    @apply my-40; } }
      .ff-image-text__image--standard .ff-image-text__image-inner {
        position: relative; }
    .ff-image-text__image--fullBleed {
      @apply overflow-hidden h-0;
      padding-top: 100%; }

@screen s {
  .ff-image-text__image--fullBleed {
    min-height: 800px;
    max-height: 1200px; } }
      .ff-image-text__image--fullBleed img {
        @apply absolute top-0 left-0 w-full h-full object-cover; }

@screen m {
  .ff-image-text__image--fullBleed {
    @apply h-screen p-0 -mb-20; } }
  .ff-image-text .text {
    @apply w-full; }

@screen s {
  .ff-image-text .text {
    max-width: 550px; } }

.ff-image-text-ods .full-bleed-wrapper {
  max-height: 40vh; }

@screen s {
  .ff-image-text-ods .full-bleed-wrapper {
    max-height: 100%; }
    .ff-image-text-ods .full-bleed-wrapper.bleed-left .rellax, .ff-image-text-ods .full-bleed-wrapper.bleed-right .rellax {
      @apply mt-0;
      height: 150%; } }

.ff-text-sliding-list {
  @apply relative;
  height: auto; }

@screen s {
  .ff-text-sliding-list {
    height: 725px; } }
  .ff-text-sliding-list__wrap {
    padding: 0 !important; }

@screen s {
  .ff-text-sliding-list__wrap {
    padding-left: inherit; } }
  .ff-text-sliding-list__slider, .ff-text-sliding-list__body {
    @apply w-full relative z-0;
    height: 50%; }

@screen s {
  .ff-text-sliding-list__slider, .ff-text-sliding-list__body {
    @apply h-full w-1/2; } }
  .ff-text-sliding-list__slider {
    height: 250px; }

@screen s {
  .ff-text-sliding-list__slider {
    @apply h-full; } }
  .ff-text-sliding-list__body {
    @apply z-10 px-4; }
    .ff-text-sliding-list__body::after {
      @apply absolute left-0 w-full bottom-0 h-full;
      box-shadow: 0 8px 4px -4px rgba(0, 0, 0, 0.2);
      content: ''; }

@screen s {
  .ff-text-sliding-list__body {
    @apply static px-0; }
    .ff-text-sliding-list__body::after {
      @apply bottom-auto top-0;
      box-shadow: inset 0 8px 4px -4px rgba(0, 0, 0, 0.1); } }
  .ff-text-sliding-list__mask {
    @apply absolute top-0 left-0 w-full h-full;
    mask-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)); }
  .ff-text-sliding-list__inner {
    @apply h-full overflow-hidden flex justify-center; }
  .ff-text-sliding-list__list {
    @apply serif-l1 leading-none antialiased;
    font-size: 90px;
    animation: scrollList 20s linear infinite; }

@keyframes scrollList {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-100%); } }
  .ff-text-sliding-list__item {
    height: calc(20px + 13vw);
    font-size: calc(20px + 13vw); }

@screen s {
  .ff-text-sliding-list__item {
    height: 90px;
    font-size: inherit; } }

.featured-work-cards .text {
  max-width: 800px; }

.featured-work-cards .featured-work-slider {
  @apply relative w-full mt-10; }
  .featured-work-cards .featured-work-slider .js-slider__nav span {
    @apply hidden; }

.featured-work-cards .featured-work-slider__slider {
  @apply absolute top-0 left-0 w-full; }
  .featured-work-cards .featured-work-slider__slider .js-slider__slide {
    @apply absolute pointer-events-none z-0; }
    .featured-work-cards .featured-work-slider__slider .js-slider__slide.active {
      @apply pointer-events-auto z-10; }

.ff-text-slider__wrap {
  @apply pt-12;
  border-top: 1px solid theme("colors.black.a25"); }
  .cms-bg--black .ff-text-slider__wrap {
    border-top: 1px solid theme("colors.white.a25"); }

.ff-text-slider__slide {
  @apply absolute opacity-0;
  transition: transform 0.55s ease, opacity 0.55s ease; }

.ff-text-slider__pages {
  @apply flex; }

.ff-text-slider__nav-slide {
  @apply relative mr-6 bg-white-a50;
  height: 2px;
  width: 44px; }
  .ff-text-slider__nav-slide:last-child {
    @apply mr-0; }
  .ff-text-slider__nav-slide::after {
    @apply absolute top-0 left-0 h-full w-full bg-red;
    content: '';
    transform: scaleX(0);
    transform-origin: 0 center;
    transition: none; }
  .ff-text-slider__nav-slide.active::after {
    transform: scaleX(1);
    transition: transform 7s linear; }

.ff-text-slider__nav-total {
  @apply opacity-50;
  margin-left: 40%; }

.offset-images-text {
  @apply py-20; }

@screen s {
  .offset-images-text {
    @apply pt-64 pb-20; } }
  .offset-images-text .image {
    width: 100%; }
  .offset-images-text__image img {
    @apply min-h-full object-cover object-center; }
  .offset-images-text__text {
    max-width: 720px; }

.image-two-column-text {
  @apply mx-auto; }
  .image-two-column-text__image {
    @apply relative w-full h-0 bg-cover bg-center bg-no-repeat mb-16;
    padding-top: 56.25%; }
  .image-two-column-text__image-wrap {
    height: 175%; }

@screen m {
  .image-two-column-text__image-wrap {
    height: 140%; } }
  .image-two-column-text__image-text {
    @apply absolute top-0 left-0 w-full h-full flex items-center justify-center text-center text-white; }
    .image-two-column-text__image-text h2 {
      @apply relative z-10 text-2xs px-4;
      max-width: 820px; }

@screen s {
  .image-two-column-text__image-text h2 {
    @apply text-m; } }
    .image-two-column-text__image-text::before {
      @apply absolute left-0 top-0 w-full h-full z-0 bg-black-a50;
      content: ''; }
  .image-two-column-text__headline--gradient {
    background-image: linear-gradient(90deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%);
    color: theme("colors.red");
    background-clip: text;
    -webkit-text-fill-color: transparent; }

.content-with-sub-navigation--light svg {
  fill: theme("colors.gray.1"); }

.content-with-sub-navigation--dark {
  @apply bg-black text-white; }
  .content-with-sub-navigation--dark svg {
    fill: rgba(255, 255, 255, 0.09); }

.content-with-sub-navigation .scroll-spy li {
  @apply pl-0; }
  .content-with-sub-navigation .scroll-spy li::before {
    @apply hidden; }
  .content-with-sub-navigation .scroll-spy li.active {
    @apply text-red; }

.content-with-sub-navigation .links .sticky {
  top: 120px; }

.content-with-sub-navigation .section-shape {
  @apply absolute left-0 right-0 z-0; }

.content-with-sub-navigation .image {
  @apply relative mr-4;
  height: 256px; }
  .content-with-sub-navigation .image img {
    @apply absolute top-0 left-0 w-full h-full object-cover; }

@screen s {
  .content-with-sub-navigation .image {
    @apply w-full;
    height: 400px; } }

@screen m {
  .content-with-sub-navigation .image {
    height: 600px; } }

@screen l {
  .content-with-sub-navigation .image {
    width: 72vw; } }

@screen xl {
  .content-with-sub-navigation .image {
    height: calc(400px + 18vw); } }

.content-with-sub-navigation__icon svg {
  width: 48px; }

.content-with-sub-navigation .quote {
  @apply py-12 my-12;
  border-top: 1px solid theme("colors.black.a25");
  border-bottom: 1px solid theme("colors.black.a25"); }

.content-with-sub-navigation .running-list {
  @apply pb-12 mb-12;
  border-bottom: 1px solid theme("colors.black.a25"); }

.content-with-sub-navigation__featured-button {
  @apply inline-block; }

.content-with-sub-navigation__featured-image {
  opacity: 0.375;
  transition: opacity 0.375s ease; }

.content-with-sub-navigation .stats {
  @apply mx-auto; }

@screen m {
  .content-with-sub-navigation .stats {
    max-width: 200px; } }

@screen m {
  .content-with-sub-navigation .stats-subhead {
    border-top: 1px solid theme("colors.black.a25"); } }

.content-with-sub-navigation .stat-block:nth-child(odd) {
  @apply pr-4; }

.content-with-sub-navigation .stat-block:nth-child(even) {
  @apply pl-4; }

@screen m {
  .content-with-sub-navigation .stat-block:nth-child(odd) {
    @apply pr-0; }
  .content-with-sub-navigation .stat-block:nth-child(even) {
    @apply pl-0; } }

.cta-background-image__image img {
  transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1); }

.cta-background-image__image.zoom img {
  transform: scale(1.125); }

.cta-background-image__headline {
  @apply serif-l1 text-xl; }

@screen s {
  .cta-background-image__headline {
    @apply serif-l1; } }

.cta-gradient {
  @apply relative; }
  .cta-gradient::after, .cta-gradient::before {
    @apply absolute left-0 top-0 h-full w-full z-0 opacity-0 pointer-events-none;
    background-image: linear-gradient(135deg, theme("colors.yellow") 0%, theme("colors.red") 100%);
    content: '';
    transition: opacity 1s; }
  .cta-gradient::before {
    background-image: linear-gradient(225deg, theme("colors.yellow") 0%, theme("colors.red") 100%); }
  .cta-gradient.hover--left::after {
    @apply opacity-75; }
  .cta-gradient.hover--right::before {
    @apply opacity-75; }
  .cta-gradient__headline {
    @apply serif-l1 text-xl text-center; }

@screen s {
  .cta-gradient__headline {
    @apply text-center; } }
  .optimistic .cta-gradient__headline {
    @apply font-ods-display; }

@screen s {
  .optimistic .cta-gradient__headline {
    @apply text-2xl; } }

.list-columns__text {
  @apply inline-block; }

.list-columns__text--strike {
  @apply relative; }
  .list-columns__text--strike::after {
    @apply absolute;
    left: -4px;
    right: -4px;
    height: 1px;
    top: 50%;
    background-color: theme("colors.red");
    content: ''; }

.card-slider__cards {
  @apply w-full; }

.card-slider__card {
  @apply opacity-50;
  margin-right: 18vw;
  transition: opacity 0.35s ease; }

@screen s {
  .card-slider__card {
    margin-right: 30vw; } }
  .card-slider__card.swiper-slide-active, .card-slider__card.swiper-slide-duplicate-active {
    @apply opacity-100; }

.card-slider__card a {
  transition: color 0.5s cubic-bezier(0.39, 0.575, 0.565, 1); }

.card-slider__card:not(.swiper-slide-active) a {
  @apply block pointer-events-none; }

.card-slider__card:not(.swiper-slide-active) a img {
  @apply pointer-events-none; }

.card-slider__card img {
  transition: transform 0.5s cubic-bezier(0.39, 0.575, 0.565, 1); }

.card-slider__card.swiper-slide-active:hover a {
  @apply text-red; }
  .card-slider__card.swiper-slide-active:hover a img {
    transform: scale(1.125) !important; }

.card-slider__card.swiper-slide-next {
  cursor: pointer;
  transition: transform 0.375s cubic-bezier(0.39, 0.575, 0.565, 1); }

.card-slider__card.swiper-slide-next:hover {
  transform: translateX(-32px); }

.card-slider__inner {
  @apply relative h-0 pointer-events-none overflow-hidden;
  padding-top: 66.72%; }

.card-slider__content {
  @apply absolute top-0 left-0 w-full h-full; }

.image-grid {
  @apply relative; }
  .image-grid::after {
    @apply absolute bg-white left-0 right-0 bottom-0 z-0;
    height: 18%;
    content: ''; }
  .image-grid__headline {
    max-width: 880px; }
  .image-grid__images {
    @apply relative mt-16 z-10 overflow-hidden;
    max-width: 100vw; }
  .image-grid__inner {
    @apply flex relative flex-no-wrap items-center; }
  .image-grid__image {
    @apply relative flex-shrink-0 mr-4; }

@screen s {
  .image-grid__image {
    @apply mr-8; } }
    .image-grid__image--1 {
      @apply w-2/4;
      height: 240px; }

@screen xs {
  .image-grid__image--1 {
    @apply w-3/6;
    height: 420px; } }

@screen s {
  .image-grid__image--1 {
    height: 620px;
    @apply w-5/12; } }
    .image-grid__image--2 {
      @apply w-4/12;
      height: 140px; }

@screen xs {
  .image-grid__image--2 {
    @apply w-3/12;
    height: 320px; } }

@screen s {
  .image-grid__image--2 {
    height: 420px; } }
    .image-grid__image--3 {
      @apply w-full;
      height: 130px; }

@screen xs {
  .image-grid__image--3 {
    height: 240px; } }

@screen s {
  .image-grid__image--3 {
    height: 330px; } }
    .image-grid__image--4 {
      @apply w-full;
      height: 130px; }

@screen xs {
  .image-grid__image--4 {
    height: 240px; } }

@screen s {
  .image-grid__image--4 {
    height: 330px; } }
    .image-grid__image--5 {
      @apply w-4/12; }

@screen xs {
  .image-grid__image--5 {
    height: 520px; } }

.work-cards-with-filters__button {
  @apply inline-block relative pr-8 select-none cursor-pointer mb-2; }
  .work-cards-with-filters__button::after {
    @apply absolute right-0 top-0 mr-1;
    border-left: 1px solid theme("colors.black.default");
    border-bottom: 1px solid theme("colors.black.default");
    content: '';
    height: 12px;
    transform: rotate(-45deg) scaleY(1);
    transform-origin: 38% 80%;
    width: 12px; }
  .work-cards-with-filters__button--open::after {
    transform: rotate(45deg) scaleY(-1); }

.work-cards-with-filters__filters {
  @apply bg-black mb-8 overflow-hidden;
  max-height: 0;
  transition: max-height 0.35s ease; }
  .work-cards-with-filters__filters--open {
    max-height: 350px; }
  .work-cards-with-filters__filters .wrap {
    @apply py-8; }

.logo-slider__logos {
  @apply w-full relative overflow-hidden mb-12; }

.logo-slider__track {
  @apply flex;
  animation: logoSliderScroll 24s linear infinite;
  animation-delay: 1s; }

@keyframes logoSliderScroll {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-100%); } }

.logo-slider__logo {
  @apply w-auto flex-shrink-0 pr-20; }
  .logo-slider__logo svg {
    @apply w-auto flex-shrink-0;
    height: 82px; }

.simple-quote-slider {
  overflow: visible !important; }

.quote-slider {
  @apply relative w-full flex flex-col-reverse overflow-hidden; }

@screen s {
  .quote-slider {
    @apply block; } }
  .quote-slider__wrap {
    @apply bg-black w-full; }

@screen s {
  .quote-slider__wrap {
    @apply w-auto;
    background: none; } }
  .quote-slider__quote-wrap {
    @apply w-full bg-black py-20 text-white; }

@screen s {
  .quote-slider__quote-wrap {
    @apply relative pr-12 mr-32 w-auto; } }
    .quote-slider__quote-wrap::before {
      @apply absolute top-0 bottom-0 w-full bg-black;
      content: '';
      left: -100%; }
  .quote-slider__quote-text {
    @apply text-s font-body font-semibold leading-snug mb-8; }

@screen s {
  .quote-slider__quote-text {
    @apply text-l; } }

@screen m {
  .quote-slider__quote-text {
    @apply text-xl; } }
  .quote-slider__bg-wrap {
    @apply relative w-full;
    height: 280px; }

@screen s {
  .quote-slider__bg-wrap {
    @apply absolute right-0 bottom-0 top-0 z-0 w-auto h-auto;
    left: 164px; } }
    .quote-slider__bg-wrap--no-images {
      @apply h-0; }
  .quote-slider__nav-total {
    @apply opacity-50;
    margin-left: 40%; }
  .quote-slider__image {
    @apply absolute top-0 left-0 w-full opacity-0;
    height: 200%;
    margin-top: -25%;
    transition: opacity 0.55s ease; }

@screen s {
  .quote-slider__image {
    @apply h-full-plus -mt-8; } }
  .quote-slider__image.active {
    @apply opacity-100; }
  .quote-slider__slide {
    @apply absolute opacity-0 px-4 pl-6;
    transition: transform 0.55s ease, opacity 0.55s ease; }

@screen s {
  .quote-slider__slide {
    @apply px-0; } }
  .quote-slider__slide.prev {
    transform: translateX(-35px); }
  .quote-slider__slide.active {
    @apply opacity-100;
    right: 64px;
    transform: translateX(0); }
  .quote-slider__slide.next {
    transform: translateX(35px); }
  .quote-slider__nav-slides {
    @apply flex; }
  .quote-slider__nav-slide {
    @apply relative mr-6 bg-white-a50;
    height: 2px;
    width: 44px; }
    .quote-slider__nav-slide:last-child {
      @apply mr-0; }
    .quote-slider__nav-slide::after {
      @apply absolute top-0 left-0 h-full w-full bg-red;
      content: '';
      transform: scaleX(0);
      transform-origin: 0 center;
      transition: none; }
    .quote-slider__nav-slide.active::after {
      transform: scaleX(1);
      transition: transform 5s linear; }
  .quote-slider .js-slider__nav-blip {
    @apply bg-white-a50; }
  .optimistic .quote-slider {
    @apply font-ods-body; }
    .optimistic .quote-slider__wrap {
      @apply bg-ods-purple-neutral; }

@screen s {
  .optimistic .quote-slider__wrap {
    background-color: transparent; } }
    .optimistic .quote-slider__quote-wrap {
      @apply bg-ods-purple-neutral; }
      .optimistic .quote-slider__quote-wrap::before {
        @apply bg-ods-purple-neutral; }
    .optimistic .quote-slider__quote-text {
      @apply font-ods-body; }

@screen s {
  .optimistic .quote-slider__bg-wrap {
    @apply left-0; } }
    .optimistic .quote-slider__nav-slide::after {
      @apply bg-ods-purple-light; }
    .optimistic .quote-slider .js-slider__nav-blip::after {
      @apply bg-ods-purple-light; }
    .optimistic .quote-slider .rellax-container {
      @apply absolute top-0 left-0 w-full h-full;
      max-height: 40vh; }
      .optimistic .quote-slider .rellax-container img {
        @apply object-top;
        height: 60vh; }

@screen s {
  .optimistic .quote-slider .rellax-container {
    height: 150%;
    max-height: 100%;
    top: 50%;
    transform: translateY(-50%); }
    .optimistic .quote-slider .rellax-container img {
      @apply h-full mt-0; } }

.rich-text h3.intro {
  @apply sans-l3 text-xs font-light mb-6 normal-case tracking-normal; }

@screen s {
  .rich-text h3.intro {
    @apply sans-l3 text-s font-light; } }

.rich-text p {
  @apply sans-l6 mb-6; }
  .rich-text p:last-child {
    @apply mb-0; }

@screen m {
  .rich-text p {
    @apply text-2xs-acc; } }

.rich-text a {
  @apply inline-block relative text-black underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  transition: color 0.175s; }
  .rich-text a:hover {
    color: theme("colors.red"); }

.rich-text h1 {
  @apply serif-l4 mb-3 text-m;
  line-height: 1.25;
  margin-bottom: 1.25rem; }

.rich-text h2 {
  @apply sans-l5-bold mb-3; }

.rich-text h3 {
  @apply mono-l1 mb-4; }

.rich-text h4 {
  @apply mono-l1 mb-4; }

.rich-text pre {
  @apply mb-6 overflow-scroll text-5xs;
  background-color: #e0e0e0;
  padding: 0.75rem 1rem; }

.rich-text ul {
  @apply mb-6; }

.rich-text ol {
  @apply list-decimal pl-4 mb-6; }
  .rich-text ol li {
    @apply pl-2; }

.rich-text li {
  @apply text-3xs;
  line-height: 1.41; }

.rich-text blockquote {
  @apply block text-2xs font-semibold leading-normal w-full mr-0 pl-6 mb-6 relative; }

@screen s {
  .rich-text blockquote {
    @apply float-right ml-6 mb-0;
    width: 380px; } }

@screen m {
  .rich-text blockquote {
    @apply -mr-24; } }
  .rich-text blockquote::after {
    @apply w-px h-full bg-red absolute left-0 top-0;
    content: ''; }

.rich-text .highlight {
  background: #e0e0e0;
  padding: 0.125rem 0.25rem; }

.rich-text .closing-bullets {
  @apply text-center text-black-a65 mb-4; }
  .rich-text .closing-bullets::before {
    content: '• • •'; }

.rich-text-banner p,
.rich-text-banner a {
  @apply text-white text-2xs-acc font-semibold; }

.optimistic .rich-text h2.h1 {
  @apply font-ods-display text-l font-normal leading-tight mb-6; }

@screen s {
  .optimistic .rich-text h2.h1:last-child {
    @apply mb-0; } }

@screen s {
  .optimistic .rich-text h2.h1 {
    @apply text-xl; } }

@screen m {
  .optimistic .rich-text h2.h1 {
    @apply text-xla; } }

.optimistic .rich-text p {
  @apply font-ods-body text-3xs font-normal leading-body; }
  .optimistic .rich-text p.large {
    @apply text-2xs font-light; }

@screen m {
  .optimistic .rich-text p.large {
    @apply text-s; } }
  .optimistic .rich-text p.subhead {
    @apply font-ods-mono text-5xs font-normal leading-tight tracking-widest uppercase mb-6; }

.optimistic .rich-text a {
  @apply text-black inline; }
  .optimistic .rich-text a:hover {
    @apply text-ods-purple-light; }
  .optimistic .rich-text a.btn-ods {
    @apply no-underline; }

.optimistic .rich-text-hero h2.h1 {
  @apply text-xl; }

@screen s {
  .optimistic .rich-text-hero h2.h1 {
    @apply text-2xl; } }

@screen m {
  .optimistic .rich-text-hero h2.h1 {
    @apply text-3xl; } }

.optimistic .rich-text-hero p:not(.subhead) {
  @apply font-ods-body text-3xs font-light leading-snug; }

@screen s {
  .optimistic .rich-text-hero p:not(.subhead) {
    @apply text-2xs; } }

@screen s {
  .optimistic .rich-text-hero-xl p:not(.subhead) {
    @apply text-s; } }

.optimistic .rich-text.rich-text-banner p,
.optimistic .rich-text.rich-text-banner a {
  @apply text-2xs-acc text-white; }

.optimistic .rich-text.rich-text-banner a:hover {
  @apply text-ods-purple-light; }

@screen s {
  .optimistic .work-detail .rich-text h2.h1 {
    @apply text-m; } }

.optimistic .work-detail .file-download .rich-text h2.h1 {
  @apply mb-2; }

.quote {
  @apply py-16; }
  .quote__blockquote {
    @apply relative pt-16 pl-8;
    border-left: 1px solid theme("colors.red"); }
  .quote__mark {
    @apply absolute text-red;
    left: 28px;
    top: -24px;
    font-size: 90px; }
  .optimistic .quote__blockquote {
    @apply border-ods-purple-core; }
  .optimistic .quote__mark {
    @apply text-ods-purple-core; }

.simple-ff-image-text__png-shadow {
  filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.1)); }

.portrait-video__video {
  width: 50%; }

@screen s {
  .portrait-video__video {
    width: 65%; } }
  .portrait-video__video iframe {
    width: 100%; }

.video__play-button {
  @apply absolute cursor-pointer;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.25s, opacity 0.25s; }
  .video__play-button:active {
    transform: translate(-50%, -50%) scale(0.9); }
  .video__play-button .play-button__bg {
    transition: fill-opacity 0.35s ease; }
  .video__play-button:hover .play-button__bg {
    fill-opacity: 1; }

#optimistic-video {
  scroll-margin-top: theme("spacing.20"); }

.image__wrap--full-width {
  height: 300px; }

@screen s {
  .image__wrap--full-width {
    height: 500px; } }

@screen m {
  .image__wrap--full-width {
    height: 650px; } }

.alternating-list-images__intro {
  @apply mx-auto;
  max-width: 800px; }

.alternating-list-images__images {
  @apply flex overflow-hidden;
  max-width: 100vw; }

.alternating-list-images__image {
  @apply w-4/6 overflow-hidden flex-shrink-0; }
  .alternating-list-images__image:first-child {
    @apply mt-12;
    margin-left: -45%; }
  .alternating-list-images__image:last-child {
    @apply ml-8; }
  .flex-row-reverse .alternating-list-images__image:first-child {
    @apply mt-0 ml-0; }
  .flex-row-reverse .alternating-list-images__image:last-child {
    @apply mt-12;
    margin-right: -45%; }
  .alternating-list-images__image img {
    @apply w-full; }

@screen s {
  .alternating-list-images__body {
    width: 80%; } }

.ff-text-sliding-images {
  @apply relative; }
  .ff-text-sliding-images__bg {
    @apply absolute top-0 left-0 w-full;
    height: 50%; }
  .ff-text-sliding-images__images {
    @apply overflow-visible;
    max-width: 100vw;
    overflow: hidden; }
  .ff-text-sliding-images__image {
    @apply h-full; }

.open-positions .whr-items {
  @apply flex flex-wrap -mx-5; }

.open-positions .whr-item {
  @apply relative w-full px-5 pb-10; }

@screen xs {
  .open-positions .whr-item {
    @apply w-1/2; } }

.open-positions .whr-title {
  @apply font-body font-semibold leading-tight pt-8;
  font-size: 21px;
  border-top: 1px solid theme("colors.black.a25");
  transition: color .15s ease; }
  .open-positions .whr-title:hover {
    @apply text-red; }

@screen s {
  .open-positions .whr-title {
    font-size: 27px; } }

.open-positions .whr-dept {
  @apply inline-block mt-6 text-5xs font-bold px-2 py-1 rounded-full bg-orange; }
  .open-positions .whr-dept span {
    @apply hidden; }

.open-positions .whr-location {
  @apply inline-block mt-6 text-5xs font-bold px-2 py-1 rounded-full bg-yellow; }
  .open-positions .whr-location span {
    @apply hidden; }

.open-positions .whr-date {
  @apply hidden; }

.prev-next-footer__bg {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25); }

.prev-next-footer__item {
  height: 400px; }

.prev-next-footer__item a {
  @apply block h-full w-full flex items-center justify-center; }

.prev-next-footer__item a:hover .prev-next-footer__arrow {
  transform: translateX(10px); }

.prev-next-footer__arrow {
  transition: transform 0.25s ease; }

.prev-next-footer__bg-item {
  transition: opacity 0.5s ease; }
  .prev-next-footer__bg-item img {
    transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1); }

.prev-next-footer__bg.hover--left .prev-next-footer__bg-item:first-child {
  opacity: 1; }
  .prev-next-footer__bg.hover--left .prev-next-footer__bg-item:first-child img {
    transform: scale(1.125); }

.prev-next-footer__bg.hover--right .prev-next-footer__bg-item:nth-child(2) {
  opacity: 1; }
  .prev-next-footer__bg.hover--right .prev-next-footer__bg-item:nth-child(2) img {
    transform: scale(1.125); }

.start-a-project {
  @apply fixed top-0 left-0 w-full h-full bg-white z-50 pt-5 pb-8 overflow-scroll opacity-0 pointer-events-none;
  left: -100vw;
  transition: opacity 0.35s ease, left 0s linear .35s; }
  .start-a-project--open {
    @apply opacity-100 pointer-events-auto left-0;
    transition: opacity 0.35s ease; }
  .start-a-project__logo .logo--white {
    @apply hidden; }
  .start-a-project__x {
    @apply relative cursor-pointer;
    width: 24px;
    height: 24px; }
    .start-a-project__x::before, .start-a-project__x::after {
      @apply absolute h-full bg-black;
      content: '';
      left: 50%;
      width: 1px; }
    .start-a-project__x::before {
      transform: rotate(-45deg); }
    .start-a-project__x::after {
      transform: rotate(45deg); }
    .start-a-project__x:active {
      transform: scale(0.9); }
  .start-a-project__wrap {
    height: calc(100vh - 140px); }
    @media screen and (max-height: 780px) {
      .start-a-project__wrap {
        @apply h-auto py-20; } }
  .start-a-project .pipedriveWebForms {
    @apply ml-0 mr-auto; }
  .optimistic .start-a-project__logo {
    width: 219px;
    height: 29px; }
    .optimistic .start-a-project__logo .optimistic-logo {
      @apply w-full h-full; }
  .optimistic .start-a-project__x::before, .optimistic .start-a-project__x::after {
    @apply transition-colors duration-200; }
  .optimistic .start-a-project__x:hover::before, .optimistic .start-a-project__x:hover::after {
    @apply bg-ods-purple-light; }

.tag {
  transition: background-color 0.35s, color 0.35s; }

.gdpr-banner {
  transition: opacity 0.35s ease, transform 0.35s ease; }
  .gdpr-banner.closed {
    @apply opacity-0;
    transform: translateY(100%); }
  .gdpr-banner__x {
    @apply relative cursor-pointer;
    width: 24px;
    height: 24px; }
    .gdpr-banner__x::before, .gdpr-banner__x::after {
      @apply absolute h-full bg-white;
      content: '';
      left: 50%;
      width: 1px; }
    .gdpr-banner__x::before {
      transform: rotate(-45deg); }
    .gdpr-banner__x::after {
      transform: rotate(45deg); }
    .gdpr-banner__x:active {
      transform: scale(0.9); }

.screen-slider {
  @apply overflow-hidden;
  max-width: 100vw; }
  .screen-slider__slide--desktop {
    width: 50%; }

@screen m {
  .screen-slider__slide--desktop {
    width: 45%; } }

@screen l {
  .screen-slider__slide--desktop {
    width: 40%; } }
  .screen-slider__slide--tablet {
    width: 50%; }

@screen s {
  .screen-slider__slide--tablet {
    width: 40%; } }

@screen m {
  .screen-slider__slide--tablet {
    width: 35%; } }

@screen l {
  .screen-slider__slide--tablet {
    width: 30%; } }
  .screen-slider__slide--mobile {
    width: 40%; }

@screen s {
  .screen-slider__slide--mobile {
    width: 30%; } }

@screen m {
  .screen-slider__slide--mobile {
    width: 22.5%; } }

@screen l {
  .screen-slider__slide--mobile {
    width: 15%; } }

.icon-social {
  @apply w-6 h-6; }
  .icon-social path {
    fill: theme("colors.red");
    transition: fill .325s; }
  .optimistic .icon-social path {
    fill: theme("colors.ods-purple.light"); }

.icon-social-link {
  @apply relative w-12 h-12 rounded-full flex items-center justify-center mr-2;
  transition: background .325s; }
  .icon-social-link:before {
    @apply absolute top-0 left-0 w-full h-full rounded-full opacity-0;
    content: '';
    background: linear-gradient(135deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%);
    z-index: -1;
    transition: opacity .5s; }
  .icon-social-link:hover:before {
    @apply opacity-100; }
  .icon-social-link:hover .icon-social path {
    fill: #FFF;
    transition: fill .325s; }
  .optimistic .icon-social-link::before {
    background-image: none;
    @apply bg-ods-purple-core; }

.transcript {
  height: 320px; }
  .transcript.full {
    @apply h-full; }
  .transcript table tbody tr td {
    @apply py-4 align-top; }
    .transcript table tbody tr td p {
      @apply mb-3; }
    .transcript table tbody tr td:first-child {
      @apply w-40; }
      .transcript table tbody tr td:first-child p {
        @apply inline-block m-0; }

.podcast-feed {
  @apply relative; }
  .podcast-feed__bg {
    @apply absolute top-0 left-0 right-0 z-0;
    height: 60%; }

@screen s {
  .podcast-feed__bg {
    height: 52%; } }
  .podcast-feed .podcast-feed-slider img {
    @apply w-full; }
  .podcast-feed .podcast-feed-slider .js-slider__slide {
    @apply z-0; }
  .podcast-feed .podcast-feed-slider .js-slider__slide.active {
    @apply z-10; }
  .podcast-feed .podcast-feed-slider .js-slider__nav span {
    @apply hidden; }
  .podcast-feed .podcast-feed-slider .js-slider__nav-blip {
    @apply bg-gray-400; }

.capabilities-accordion__inner {
  @apply relative w-full;
  max-width: 800px; }

.capabilities-accordion__row {
  @apply cursor-pointer; }
  .capabilities-accordion__row .plus {
    @apply relative ml-3 mr-6 transition-transform duration-300 flex-shrink-0;
    height: 18px;
    width: 18px; }

@screen s {
  .capabilities-accordion__row .plus {
    width: 22px;
    height: 22px; } }
    .capabilities-accordion__row .plus::before, .capabilities-accordion__row .plus::after {
      @apply absolute bg-white;
      content: ''; }
    .capabilities-accordion__row .plus::before {
      @apply w-px h-full;
      left: 50%; }
    .capabilities-accordion__row .plus::after {
      @apply h-px w-full;
      top: 50%; }
    .capabilities-accordion__row .plus--active {
      transform: rotate(-45deg); }
    .capabilities-accordion__row .plus.plus-dark::before, .capabilities-accordion__row .plus.plus-dark::after {
      @apply bg-black; }

.capabilities-accordion__row-content {
  @apply h-0 overflow-hidden; }

.capabilities-accordion__row-content--active {
  @apply h-auto; }

.capabilities-accordion__summary {
  margin-bottom: 55px; }

.capabilities-accordion__icon svg {
  height: 46px;
  width: 46px; }

.capabilities-accordion__featured-image img {
  width: 175px;
  height: 175px;
  object-fit: cover; }

.multi-column-text-icons__icon svg {
  height: 46px;
  width: 46px; }

.multi-column-text-icons__columns[data-columns="3"] {
  max-width: 960px; }

.multi-column-text-icons__columns[data-columns="4"] {
  max-width: 1200px; }

@screen s {
  .multi-column-text-icons__label {
    max-width: 240px; } }

.logo-gallery__logo svg {
  max-width: 220px;
  min-width: 200px; }

.people-gallery__image {
  @apply w-full h-0 bg-cover bg-center;
  padding-top: 125%; }

.tabbed-list__tab {
  @apply relative px-8 py-2 appearance-none transition-opacity duration-300; }
  .tabbed-list__tab::after {
    @apply absolute left-0 w-full h-px bg-red opacity-0;
    bottom: -1px;
    content: ''; }
  .tabbed-list__tab--active::after {
    @apply opacity-100; }

.tabbed-list__page {
  @apply absolute top-0 left-0 w-full grid grid-cols-1 gap-8 opacity-0 transition-opacity duration-300; }

@screen s {
  .tabbed-list__page {
    @apply grid-cols-2; } }

@screen m {
  .tabbed-list__page {
    @apply grid-cols-3; } }
  .tabbed-list__page--active {
    @apply relative opacity-100; }

.work-cards .col {
  box-sizing: border-box; }
  .work-cards .col:first-child {
    @apply pr-4; }
  .work-cards .col:last-child {
    @apply pl-4; }
  .work-cards .col.work-cards--offset:last-child {
    @apply -mt-16; }

.work-card .card {
  @apply relative mb-12; }
  .work-card .card > * {
    transition: color 0.5s ease-in-out; }
  .work-card .card:hover .work-card--title {
    color: theme("colors.red") !important; }
  .work-card .card:hover img {
    transform: scale(0.85) !important; }

@screen s {
  .work-card .card:hover .overlay {
    opacity: 0.85;
    transform: translate(0%, 0%) rotate(0deg) skew(0deg); } }

@screen s {
  .work-card .card:hover .overlay-text {
    opacity: 1;
    transform: translateY(0) rotate(0) skew(0);
    transition-delay: 0.25s; } }
  .work-card .card--filtered {
    opacity: 0.2; }

.work-card .image-wrap {
  @apply relative bg-black-pure mb-6 overflow-hidden; }
  .work-card .image-wrap img {
    transition: transform 0.5s;
    width: 100%; }

.work-card--lock-aspect-ratio .image-wrap {
  @apply relative h-0 w-full;
  padding-top: 66.666%; }
  .work-card--lock-aspect-ratio .image-wrap img {
    @apply absolute top-0 left-0 block w-full h-full object-cover; }

.work-card .overlay {
  @apply absolute w-full h-full z-10 top-0 left-0 hidden;
  background-image: linear-gradient(135deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%);
  box-shadow: inset 2px 2px 12px 0px rgba(0, 0, 0, 0.2);
  opacity: 0.375;
  transform: translate(-100%, -100%) rotate(-30deg) skew(-30deg);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; }

@screen s {
  .work-card .overlay {
    @apply block; } }

.work-card .overlay-text {
  @apply font-body font-normal absolute opacity-0 text-white z-10 top-0 left-0 mt-6 ml-4;
  max-width: 400px;
  transform: translateY(2rem) rotateX(45deg) skewX(20deg);
  transition: transform 0.375s 0s, opacity 0.375s cubic-bezier(0.215, 0.61, 0.355, 1) 0s; }

.optimistic .work-card .card:hover .work-card--title {
  color: theme("colors.ods-purple.light") !important; }

.optimistic .work-card .overlay {
  background-image: none;
  @apply bg-ods-purple-core; }

.block-wrap {
  @apply absolute z-0 top-0 left-0 w-full; }
  .block-wrap .block__shape--black {
    fill: theme("colors.black.default"); }
  .block-wrap .block__shape--white {
    fill: theme("colors.white.default"); }
  .block-wrap .block__shape--lightGrey {
    fill: theme("colors.gray.1"); }
  .block-wrap--bottom {
    @apply top-auto bottom-0;
    transform: scaleY(-1); }
  .block-wrap--reverse {
    transform: scaleX(-1); }

.popup {
  width: 440px; }
  .popup .menu-button {
    @apply absolute;
    top: 10px;
    right: 10px;
    padding: .875rem; }
    .popup .menu-button::before, .popup .menu-button::after {
      @apply bg-white w-4;
      left: 6px;
      top: 2px; }

.hero-feature {
  @apply absolute invisible pointer-events-none opacity-0 transition-all duration-300;
  transform: translateY(20px); }

@screen m {
  .hero-feature {
    @apply static visible pointer-events-auto opacity-100 transition-none;
    transform: translateY(0); } }
  .hero-feature.active {
    @apply visible pointer-events-auto opacity-100;
    transform: translateY(0); }
  .hero-feature > a {
    transition-duration: .75s;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1); }
    .hero-feature > a:hover {
      @apply text-black; }

@screen m {
  .hero-feature > a:hover {
    @apply text-white; } }
  .hero-feature__bg {
    @apply absolute z-0 h-full top-0 left-0 right-0 overflow-hidden; }

@screen m {
  .hero-feature__bg {
    right: calc(theme('padding.16') * -1); } }

@screen l {
  .hero-feature__bg {
    right: calc(((100vw - 1360px) / 2) * -1); } }
    .hero-feature__bg-scrim {
      @apply absolute top-0 left-0 w-full h-full opacity-0;
      background-color: rgba(26, 26, 26, 0.9);
      transition-duration: .75s;
      transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1); }
      .hero-feature:hover .hero-feature__bg-scrim {
        @apply opacity-100; }
    .hero-feature__bg-image {
      @apply absolute top-0 left-0 w-full h-full bg-cover bg-center opacity-0 transform scale-125;
      filter: grayscale(1);
      mix-blend-mode: screen;
      transition-duration: .75s;
      transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1); }
      .hero-feature:hover .hero-feature__bg-image {
        @apply opacity-40 scale-100; }
  .hero-feature__link {
    @apply inline-block text-3xs font-bold border-b border-red; }

@screen m {
  .hero-feature__link {
    @apply hidden; } }

.hero-features-nav {
  @apply pt-6 pb-5; }

@screen m {
  .hero-features-nav {
    @apply hidden; } }
  .hero-features-nav__button {
    @apply bg-black bg-opacity-10 px-3 leading-none rounded-full uppercase font-mono text-5xs tracking-wider text-black mr-2;
    padding-top: 6px;
    padding-bottom: 4px; }
    .hero-features-nav__button:focus {
      @apply outline-none bg-opacity-75; }
    .hero-features-nav__button.active {
      @apply bg-red text-white; }

.page {
  @apply relative; }
  .page__hero {
    @apply w-screen flex items-center justify-center relative;
    height: 100vh;
    min-height: 650px;
    max-height: 900px; }

@screen s {
  .page__hero {
    max-height: none; } }
    .page__hero.page__hero--shape::after {
      @apply absolute left-0 right-0 top-0;
      background-color: currentColor;
      content: '';
      height: 70vh;
      max-height: 640px; }

@screen s {
  .page__hero.page__hero--shape::after {
    height: 50vh;
    max-height: none; } }
    .page__hero--image {
      height: 90vh; }
    .page__hero--hex {
      @apply h-auto pt-40 pb-8;
      min-height: auto !important;
      max-height: auto !important; }

@screen s {
  .page__hero--hex {
    @apply min-h-0 max-h-full pt-48 pb-16; } }

@screen m {
  .page__hero--hex {
    @apply pt-40 pb-8; } }
      .page__hero--hex svg {
        @apply absolute opacity-75;
        top: -35vw;
        right: -50vw !important; }

@screen xs {
  .page__hero--hex svg {
    @apply w-full;
    top: -25vw;
    right: -25vw !important; } }

@screen s {
  .page__hero--hex svg {
    @apply w-3/4;
    top: -15vw; } }

@screen l {
  .page__hero--hex svg {
    top: -7vw;
    right: -25vw !important; } }
        .page__hero--hex svg path {
          animation: rotate 120s linear infinite;
          transform-origin: 50% 0%; }

@screen xs {
  .page__hero--hex svg {
    top: -20vw;
    right: -15vw !important; } }

@screen s {
  .page__hero--hex svg {
    @apply w-2/3;
    top: -10vw; } }
  .page__hero-shape svg {
    @apply absolute z-0;
    fill: currentColor; }
    .page__hero--shape .page__hero-shape svg {
      bottom: 4vh;
      left: 0; }
      .page__hero--shape .page__hero-shape svg polygon {
        animation: rotate 120s linear infinite;
        transform-origin: 50% 0%; }
    .page__hero--image .page__hero-shape svg {
      width: 33.333%; }
      .page__hero--image .page__hero-shape svg path {
        animation: rotate 40s linear infinite;
        transform-origin: 100% 100%; }
    .page__hero-shape--right .page__hero-shape svg {
      @apply right-0; }
    .page__hero-shape--left .page__hero-shape svg {
      @apply left-0;
      transform: scaleX(-1); }
    .page__hero--image .page__hero-shape svg {
      @apply bottom-0; }

@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
  .page__hero-image {
    @apply absolute left-0 right-0 overflow-hidden;
    top: 113px;
    bottom: 2rem; }

@screen s {
  .page__hero-image {
    bottom: 4rem; } }

@screen l {
  .page__hero-image {
    width: 92vw; } }
  .page__hero-text {
    max-width: 860px; }
    .page__hero--shape .page__hero-text {
      @apply mb-24 mx-auto; }
  .page__headline {
    @apply text-xl font-display leading-tight px-4; }

@screen s {
  .page__headline {
    @apply text-2xl; } }

@screen m {
  .page__headline {
    @apply text-3xl; } }

.page-home {
  position: relative; }
  .page-home__hero {
    @apply relative z-10 overflow-hidden bg-lightYellow bg-opacity-10;
    min-height: 800px;
    padding-top: 86px; }

@screen xs {
  .page-home__hero {
    @apply h-screen; } }
  .page-home__shape svg {
    filter: blur(2px);
    mix-blend-mode: multiply;
    transform: translate(-50%, -5%); }
    .page-home__shape svg path {
      transform-origin: 540px 0px;
      animation: rotate 40s linear infinite; }

@screen xl {
  .page-home__shape svg {
    @apply w-auto;
    height: 80%; } }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  .page-home__hero-content {
    @apply relative h-full flex flex-col z-10; }
    .page-home__hero-content::after {
      @apply absolute h-px top-0 bg-black bg-opacity-10;
      content: '';
      width: 99999px;
      transform: translateX(-50%); }

@screen m {
  .page-home__hero-content {
    @apply flex-row; } }
  .page-home__hero-main {
    @apply relative flex-1 flex flex-col py-6 text-black z-10; }
    .page-home__hero-main p {
      @apply mb-6; }
    .page-home__hero-main p:last-child {
      @apply mb-0; }

@screen xs {
  .page-home__hero-main {
    @apply justify-center py-0; } }
    .page-home__hero-main > h1.h5 {
      @apply mono-l1 mb-6; }
    .page-home__hero-main > h2.h1 {
      @apply font-display text-xl mb-6 mr-4 leading-none; }

@screen xs {
  .page-home__hero-main > h2.h1 {
    line-height: 1.1; } }

@screen s {
  .page-home__hero-main > h2.h1 {
    @apply text-2xl mr-20; } }

@screen l {
  .page-home__hero-main > h2.h1 {
    @apply text-3xl; } }
    .page-home__hero-main > h3.h2 {
      @apply font-body text-3xs leading-body pr-3; }

@screen xs {
  .page-home__hero-main > h3.h2 {
    @apply text-2xs; } }

@screen s {
  .page-home__hero-main > h3.h2 {
    @apply pr-48; } }

@screen m {
  .page-home__hero-main > h3.h2 {
    @apply pr-56; } }

@screen m {
  .page-home__hero-main {
    @apply flex-none border-r border-black border-opacity-10;
    width: 65%; }
    .page-home__hero-main.no-featured {
      @apply w-full border-none; } }
  .page-home__hero-features--wrapper {
    @apply relative;
    height: 280px; }
    .page-home__hero-features--wrapper::after {
      @apply absolute top-0 h-px bg-black bg-opacity-10;
      width: 99999px;
      content: '';
      transform: translateX(-50%); }

@screen xs {
  .page-home__hero-features--wrapper {
    height: 240px; } }

@screen m {
  .page-home__hero-features--wrapper {
    @apply h-auto border-none; }
    .page-home__hero-features--wrapper::after {
      @apply hidden; }
    .page-home__hero-features--wrapper .page-home__hero-features--list {
      @apply h-full flex flex-col flex-1; }
      .page-home__hero-features--wrapper .page-home__hero-features--list li {
        @apply relative flex-1; }
        .page-home__hero-features--wrapper .page-home__hero-features--list li::after {
          @apply absolute h-px bottom-0 left-0 bg-black bg-opacity-10;
          content: '';
          width: 99999px; }
        .page-home__hero-features--wrapper .page-home__hero-features--list li:last-child::after {
          @apply hidden; } }
  .page-home__intro {
    @apply sans-l3-light text-s text-center pb-12 w-5/6 mx-auto pt-12;
    max-width: 930px; }

@screen s {
  .page-home__intro {
    @apply pb-16 sans-l3-light; } }

@screen m {
  .page-home__intro {
    @apply text-l pt-20; } }

.work-detail {
  @apply relative; }
  .work-detail__hero {
    @apply flex items-start justify-center overflow-hidden;
    max-width: 100vw;
    padding-top: 80px;
    min-height: 720px; }

@screen s {
  .work-detail__hero {
    @apply items-center;
    height: 720px; } }

@screen m {
  .work-detail__hero {
    @apply h-screen;
    min-height: 860px;
    max-height: 1200px; } }
  .work-detail__bg {
    @apply absolute top-0 left-0 z-0 pointer-events-none select-none;
    clip-path: url(#poly-header-clip-path);
    height: 220vw;
    max-height: none;
    width: 160vw; }

@screen s {
  .work-detail__bg {
    @apply left-auto;
    height: 150%;
    min-height: 150%;
    min-width: 1000px;
    right: 43vw;
    width: 10%; } }

@screen m {
  .work-detail__bg {
    height: 140vh;
    min-height: 140%;
    width: 140vh;
    min-width: 1200px; } }
  .work-detail__bg-color {
    @apply absolute h-full top-0 left-0 overflow-hidden;
    width: 200vw; }

@screen s {
  .work-detail__bg-color {
    @apply h-full w-full; } }
  .work-detail__bg-image {
    @apply absolute h-auto w-full min-w-full;
    opacity: 0.6; }
  .work-detail__content {
    @apply relative max-w-page z-10; }

@screen s {
  .work-detail__content {
    @apply flex;
    height: 80%;
    width: 85%; } }
  .work-detail__image {
    @apply mx-auto h-auto mt-16;
    width: calc(100% - 2rem);
    max-width: none;
    max-height: none; }
    .work-detail__image .cover-image img {
      @apply relative w-auto mx-auto;
      max-width: 100%;
      max-height: 100%; }
    .work-detail__image .cover-image--optimistic {
      @apply w-full h-full; }
      .work-detail__image .cover-image--optimistic img {
        @apply mx-auto w-auto h-full object-cover; }

@screen s {
  .work-detail__image {
    @apply mt-0;
    width: calc(100% - 4rem); }
    .work-detail__image .cover-image {
      @apply w-full h-full; } }
  .work-detail__text {
    @apply flex items-center justify-end py-12; }

@screen s {
  .work-detail__text {
    @apply py-0; } }
  .work-detail__text-wrap {
    @apply w-full px-4; }

@screen s {
  .work-detail__text-wrap {
    @apply px-0;
    width: 80%; } }
  .work-detail__learn-more {
    @apply relative pl-8; }
    .work-detail__learn-more::before {
      @apply absolute top-0;
      border-bottom: 2px solid theme("colors.red");
      border-left: 2px solid theme("colors.red");
      content: '';
      height: 12px;
      left: 2px;
      transform: rotate(-45deg);
      width: 12px; }
  .work-detail__watch-video, .work-detail__read-transcript {
    @apply relative block align-middle h-5 mb-4; }
    .work-detail__watch-video:hover, .work-detail__watch-video:focus, .work-detail__read-transcript:hover, .work-detail__read-transcript:focus {
      @apply text-red;
      transition: color 0.175s; }
    .work-detail__watch-video::before, .work-detail__read-transcript::before {
      @apply mr-4 h-5 w-5 inline-block align-middle;
      background-image: url(/dist/images/watch-video-icon.svg);
      content: ''; }
  .work-detail__read-transcript::before {
    background-image: url(/dist/images/read-trascript-icon.svg); }
  .optimistic .work-detail__hero-bg {
    @apply absolute inset-0 z-0 pointer-events-none select-none; }
    .optimistic .work-detail__hero-bg svg {
      height: 175vw;
      width: 175vw; }

@screen s {
  .optimistic .work-detail__hero-bg svg {
    @apply h-full;
    width: 75vw; } }
  .optimistic .work-detail__watch-video:hover, .optimistic .work-detail__watch-video:focus, .optimistic .work-detail__read-transcript:hover, .optimistic .work-detail__read-transcript:focus {
    @apply text-ods-purple-core; }
  .optimistic .work-detail__watch-video::before, .optimistic .work-detail__read-transcript::before {
    background-image: url(/dist/images/watch-video-icon-ods.svg); }
  .optimistic .work-detail__read-transcript::before {
    background-image: url(/dist/images/read-trascript-icon-ods.svg); }
  .optimistic .work-detail__learn-more::before {
    border-bottom: 2px solid theme("colors.ods-purple.light");
    border-left: 2px solid theme("colors.ods-purple.light"); }

.cta-banner .cta-form-wrapper {
  box-shadow: 0px 4px 16px rgba(26, 26, 26, 0.16); }

.insights-index__button {
  @apply inline-block relative pr-8 select-none cursor-pointer mb-2; }
  .insights-index__button::after {
    @apply absolute right-0 top-0 mr-1;
    border-left: 1px solid theme("colors.black.default");
    border-bottom: 1px solid theme("colors.black.default");
    content: '';
    height: 12px;
    transform: rotate(-45deg) scaleY(1);
    transform-origin: 38% 80%;
    width: 12px; }
  .insights-index__button--open::after {
    transform: rotate(45deg) scaleY(-1); }

.insights-index__filters {
  @apply bg-black mb-8 overflow-hidden;
  max-height: 0;
  transition: max-height 0.35s ease; }
  .insights-index__filters--open {
    max-height: 350px; }
  .insights-index__filters .wrap {
    @apply py-8; }

.insights-index__cta-text {
  @apply serif-l3 leading-tight text-white mb-8; }

.insights-index__grid-item {
  @apply w-full mb-12; }

@screen s {
  .insights-index__grid-item {
    width: calc(50% - 16px); } }

.insights-index__optimistic-link-title {
  transition: color 0.5s ease-in-out; }

.insights-index__optimistic-link:hover .insights-index__optimistic-link-title {
  @apply text-red; }

.insights-index__link-title {
  transition: color 0.5s ease-in-out; }

.insights-index__link:hover .insights-index__link-title {
  @apply text-red; }

@screen s {
  .insights-index__link:hover .insights-index__overlay {
    opacity: 0.85;
    transform: translate(0%, 0%) rotate(0deg) skew(0deg); }
  .insights-index__link:hover .insights-index__overlay-text > * {
    @apply opacity-100;
    transform: translateY(0) rotate(0) skew(0);
    transition-delay: 0.25s; } }

.insights-index__overlay {
  @apply absolute w-full h-full z-10 top-0 left-0;
  background-image: linear-gradient(135deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%);
  box-shadow: inset 2px 2px 12px 0px rgba(0, 0, 0, 0.2);
  opacity: 0.375;
  transform: translate(-100%, -100%) rotate(-30deg) skew(-30deg);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; }
  .insights-index__overlay--ods {
    @apply bg-ods-purple-core;
    background-image: none; }

.insights-index__overlay-text {
  @apply font-body font-normal text-m absolute h-full w-full top-0 left-0 flex flex-col py-8 px-6 text-white justify-between z-20; }
  .insights-index__overlay-text > * {
    @apply opacity-0;
    transform: translateY(2rem) rotateX(45deg) skewX(20deg);
    transition: transform 0.375s 0s, opacity 0.375s cubic-bezier(0.215, 0.61, 0.355, 1) 0s; }

.optimistic .insights-index__link:hover .insights-index__link-title {
  @apply text-ods-purple-core; }

.optimistic .insights-index__overlay {
  @apply bg-none bg-ods-purple-core; }
  .optimistic .insights-index__overlay-text {
    @apply font-ods-body; }

.insights-detail__header--gradientBehind {
  @apply relative text-white;
  height: 495px; }
  .insights-detail__header--gradientBehind.height-auto {
    height: auto; }
  .insights-detail__header--gradientBehind::after {
    @apply absolute top-0 left-0 w-full h-full z-10;
    content: ''; }
  .insights-detail__header--gradientBehind .insights-detail__hero-wrap {
    max-width: 800px; }
    .insights-detail__header--gradientBehind .insights-detail__hero-wrap .optimistic-logo {
      max-width: 480px; }

.insights-detail__header--imageBehind {
  @apply relative text-white;
  height: 600px; }
  .insights-detail__header--imageBehind::after {
    @apply absolute top-0 left-0 w-full h-full z-10;
    background-color: rgba(0, 0, 0, 0.6);
    content: ''; }
  .insights-detail__header--imageBehind .insights-detail__hero-wrap {
    max-width: 800px; }

.insights-detail__header--imageBelow .insights-detail__hero-wrap {
  @apply w-full flex flex-col items-start justify-end mx-auto pb-16;
  margin-top: 150px;
  max-width: 800px; }

.insights-detail__header--imageBelow .insights-detail__hero-image {
  height: 300px; }

@screen s {
  .insights-detail__header--imageBelow .insights-detail__hero-image {
    height: 450px; } }

@screen m {
  .insights-detail__header--imageBelow .insights-detail__hero-image {
    height: 640px; } }

.insights-detail__attributions {
  @apply flex mx-auto mb-8;
  max-width: 800px; }

@screen l {
  .insights-detail__attributions {
    @apply block absolute top-0 my-0;
    left: -201px;
    width: 175px; } }

.optimistic .insights-detail__hero-wrap .subhead {
  @apply font-ods-mono text-5xs font-normal leading-tight tracking-widest uppercase mb-6; }

.optimistic .insights-detail__hero-wrap h1.h2 {
  @apply font-ods-display text-l font-normal leading-tight mb-6; }

@screen s {
  .optimistic .insights-detail__hero-wrap h1.h2 {
    @apply text-xl; } }

.optimistic .insights-detail__hero-wrap h2.h3 {
  @apply font-ods-body text-xs font-light leading-snug; }

@screen s {
  .optimistic .insights-detail__hero-wrap h2.h3 {
    @apply text-s; } }

.cto-to-cto .cto-hero {
  max-height: 40rem; }
  .cto-to-cto .cto-hero .video {
    max-width: 50rem; }
    .cto-to-cto .cto-hero .video__video iframe {
      @apply rounded; }

.cto-to-cto + .insights-detail__header--imageBelow .insights-detail__hero-wrap {
  @apply mt-20 pb-10; }

@screen l {
  .cto-to-cto + .insights-detail__header--imageBelow .insights-detail__hero-wrap {
    @apply mt-24; } }

.cto-interview .insights-wrap p:not(:first-child) {
  @apply mt-4; }

.cto-interview .insights-wrap .cto-section-anchor {
  top: -100px; }

.cto-interview .cto-interviewer-wrapper .cto-tapback,
.cto-interview .cto-interviewee-wrapper .cto-tapback {
  top: -12px; }
  .cto-interview .cto-interviewer-wrapper .cto-tapback svg,
  .cto-interview .cto-interviewee-wrapper .cto-tapback svg {
    height: 18px;
    width: 18px; }

@screen s {
  .cto-interview .cto-interviewer-wrapper {
    padding-right: 72px; } }

.cto-interview .cto-interviewer-wrapper .cto-tapback {
  right: 30px; }

@screen s {
  .cto-interview .cto-interviewer-wrapper .cto-tapback {
    right: 65px; } }

@screen s {
  .cto-interview .cto-interviewee-wrapper {
    padding-left: 72px; } }

.cto-interview .cto-interviewee-wrapper .cto-tapback {
  left: 30px; }

@screen s {
  .cto-interview .cto-interviewee-wrapper .cto-tapback {
    left: 65px; } }

.cto-interview .cto-interviewer-content p:not(:first-child),
.cto-interview .cto-interviewee-content p:not(:first-child) {
  @apply mt-4; }

.cto-interview .cto-interviewer-content figure,
.cto-interview .cto-interviewee-content figure {
  @apply my-4; }

.cto-interview .cto-interviewer-content {
  border-radius: 0px 24px 24px 24px; }

.cto-interview .cto-interviewee-content {
  border-radius: 24px 0px 24px 24px; }

.cto-interview__blockquote-mark {
  top: -26px; }

html:not(.no-js) .cto-interview [data-aos=fade-left] {
  -webkit-transform: translate3d(32px, 0, 0);
  transform: translate3d(32px, 0, 0); }

html:not(.no-js) .cto-interview [data-aos=fade-right] {
  -webkit-transform: translate3d(-32px, 0, 0);
  transform: translate3d(-32px, 0, 0); }

.connect-index__header-text {
  @apply mx-auto;
  margin-top: 120px;
  max-width: 700px; }

.connect-index__form-wrap {
  @apply mx-auto px-4 mt-12 mb-32;
  max-width: 800px; }

.swiper-wrapper {
  @apply mt-3; }

.swiper-container-horizontal {
  @apply relative pt-8; }
  .swiper-container-horizontal .swiper-pagination {
    @apply absolute text-left transform-none transition-none mb-6 flex; }
  .swiper-container-horizontal .swiper-pagination-bullets {
    @apply top-0 left-0 bottom-auto w-full justify-center; }
    .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet {
      @apply font-body font-bold text-left w-auto capitalize h-5 mb-6 ml-0 mx-2 opacity-50 transition-opacity;
      background: transparent; }
      .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet::after {
        background-image: none;
        background-color: black;
        transform: scaleX(1); }
      .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet:hover, .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet:focus {
        @apply opacity-100; }
        .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet:hover::after, .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet:focus::after {
          @apply opacity-100;
          background-image: linear-gradient(90deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%);
          background-color: none; }
      .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
        @apply opacity-100; }
        .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
          background-image: linear-gradient(90deg, theme("colors.coralGradient") 10%, theme("colors.red") 100%); }
  .optimistic .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet {
    @apply font-ods-body; }
    .optimistic .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet::after {
      bottom: -4px; }
    .optimistic .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet:hover::after, .optimistic .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet:focus::after {
      background-image: none;
      @apply bg-ods-purple-light; }
    .optimistic .swiper-container-horizontal .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
      @apply bg-ods-purple-light;
      background-image: none; }

.ecd-maturity-survey .previous-link {
  @apply inline-block relative text-black underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  transition: color 0.175s; }
  .ecd-maturity-survey .previous-link:hover {
    color: theme("colors.red"); }
  .ecd-maturity-survey .previous-link path {
    fill: theme("colors.black.pure");
    transition: fill 0.175s ease; }
  .ecd-maturity-survey .previous-link:hover path {
    fill: theme("colors.red"); }
