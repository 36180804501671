.image {
  &__wrap--full-width {
    height: 300px;

    @screen s {
      height: 500px;
    }

    @screen m {
      height: 650px;
    }
  }
}
