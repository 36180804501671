@mixin primary-gradient($angle: 135deg) {
  background-image: linear-gradient(
    $angle,
    theme('colors.coralGradient') 10%,
    theme('colors.red') 100%
  );
}

@mixin secondary-gradient($angle: 135deg) {
  background-image: linear-gradient(
    $angle,
    theme('colors.yellow') 0%,
    theme('colors.red') 100%
  );
}

@mixin tertiary-gradient($angle: 135deg) {
  background-image: linear-gradient(
    $angle,
    theme('colors.yellow') 0%,
    theme('colors.orange') 100%
  );
}

@mixin hero-gradient() {
  background-image: linear-gradient(134.04deg, 
    theme('colors.yellow') -41.17%, 
    theme('colors.coralGradient') 51.54%, 
    theme('colors.red') 148.03%
  );
}

.bg-gradient-vertical {
  @include primary-gradient(180deg);
}

.bg-gradient-horizontal {
  @include primary-gradient(90deg);
}

.bg-gradient-angle {
  @include primary-gradient(135deg);
}

.bg-gradient {
  &--primary {
    @include primary-gradient(135deg);
  }
  &--secondary {
    @include secondary-gradient(135deg);
  }  
  &--tertiary {
    @include tertiary-gradient(135deg);
  }    
}
