.screen-slider {
  @apply overflow-hidden;
  max-width: 100vw;

  &__slide--desktop {
    width: 50%;

    @screen m {
      width: 45%;
    }

    @screen l {
      width: 40%;
    }
  }

  &__slide--tablet {
    width: 50%;

    @screen s {
      width: 40%;
    }

    @screen m {
      width: 35%;
    }

    @screen l {
      width: 30%;
    }
  }

  &__slide--mobile {
    width: 40%;

    @screen s {
      width: 30%;
    }

    @screen m {
      width: 22.5%;
    }

    @screen l {
      width: 15%;
    }
  }
}
