.image-slider {
  @apply overflow-hidden;

  &__image {
    @apply absolute top-0 left-0 w-full h-full overflow-hidden z-0;
  }

  &__nav-numbers {
    @apply relative flex justify-between mono-l1 pb-6;
  }

  &__nav-total {
    @apply absolute text-black-a65;
    left: 136px;

    .cms-bg--black & {
      @apply text-white-a50;
    }
  }

  &__nav-pages {
    @apply flex;
  }

  &__nav-page {
    @apply relative mr-6 bg-black-a25;
    height: 2px;
    width: 44px;

    .cms-bg--black & {
      @apply relative mr-6 bg-white-a50;
    }

    &:last-child {
      @apply mr-0;
    }

    &::after {
      @apply absolute top-0 left-0 h-full w-full bg-red;
      content: '';
      transform: scaleX(0);
      transform-origin: 0 center;
      transition: none;
    }

    &.active::after {
      transform: scaleX(1);
      transition: transform 5s linear;
    }
  }
}
