.card-slider {
  &__wrap {
  }

  &__cards {
    @apply w-full;
  }

  &__card {
    @apply opacity-50;
    margin-right: 18vw;
    transition: opacity 0.35s ease;

    @screen s {
      margin-right: 30vw;
    }

    &.swiper-slide-active,
    &.swiper-slide-duplicate-active {
      @apply opacity-100;
    }
  }

  &__card a {
    transition: color 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  &__card:not(.swiper-slide-active) a {
    @apply block pointer-events-none;
  }

  &__card:not(.swiper-slide-active) a img {
    @apply pointer-events-none;
  }

  &__card img {
    transition: transform 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  &__card.swiper-slide-active:hover {
    a {
      @apply text-red;
      
      img {
        transform: scale(1.125) !important;
      }
    }
  }

  &__card.swiper-slide-next {
    cursor: pointer;
    transition: transform 0.375s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  &__card.swiper-slide-next:hover {
    transform: translateX(-32px);
  }

  &__inner {
    @apply relative h-0 pointer-events-none overflow-hidden;
    padding-top: 66.72%;
  }

  &__content {
    @apply absolute top-0 left-0 w-full h-full;
  }
}
