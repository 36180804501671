.start-a-project {
  @apply fixed top-0 left-0 w-full h-full bg-white z-50 pt-5 pb-8 overflow-scroll opacity-0 pointer-events-none;
  left: -100vw;
  transition: opacity 0.35s ease, left 0s linear .35s;
  
  &--open {
    @apply opacity-100 pointer-events-auto left-0;
    transition: opacity 0.35s ease;
  }

  &__logo .logo--white {
    @apply hidden;
  }

  &__x {
    @apply relative cursor-pointer;
    width: 24px;
    height: 24px;

    &::before,
    &::after {
      @apply absolute h-full bg-black;
      content: '';
      left: 50%;

      width: 1px;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }

    &:active {
      transform: scale(0.9);
    }
  }

  &__wrap {
    height: calc(100vh - 140px);

    @media screen and (max-height: 780px) {
      @apply h-auto py-20;
    }
  }

  .pipedriveWebForms {
    @apply ml-0 mr-auto;
  }

  .optimistic & {
    &__logo {
      width: 219px;
      height: 29px;

      .optimistic-logo {
        @apply w-full h-full;
      }
    }

    &__x {
      &::before,
      &::after {
        @apply transition-colors duration-200;
      }

      &:hover {
        &::before,
        &::after {
          @apply bg-ods-purple-light;
        }
      }
    }
  }
}
