.footer {
  @apply relative;

  &__logo-bg {
    background-image: url('/dist/images/substantial.svg');
    background-position: -32px 0;
    background-repeat: no-repeat;
    background-size: 1490px auto;
    height: 155px;
    opacity: 0.15;
    width: 100%;

    @screen l {
      background-position: center 0;
    }
  }

  .footer-logo {
    opacity: 0.1;
    pointer-events: none;
  }

  &__tagline {
    @apply serif-l3 mb-2 leading-none;

    @screen m {
      @apply text-xl;
    }
  }

  &--white {
    @apply bg-white;
  }

  &--lightGrey {
    @apply bg-gray-1;
  }

  &--black {
    @apply bg-black;
  }

  .external-link {
    &::after {
      bottom: 3px;
    }

    .optimistic & {
      &::after {
        @apply bottom-0;
      }
    }
  }

  .site-switcher-divider {
    /* width: 320px;

    @screen m {
      @apply w-full;
    } */
  }

  .pipedriveWebForms {
    @apply ml-0 mr-auto;
    max-width: 480px;
  }

  .optimistic-logo {
    height: 44px;
    width: auto;
  }
}
