.page {
  @apply relative;

  &__hero {
    @apply w-screen flex items-center justify-center relative;
    height: 100vh;
    min-height: 650px;
    max-height: 900px;
    
    @screen s {
      max-height: none;
    }

    &.page__hero--shape::after {
      @apply absolute left-0 right-0 top-0;
      background-color: currentColor;
      content: '';
      height: 70vh;
      max-height: 640px;

      @screen s {
        height: 50vh;
        max-height: none;
      }
    }

    &--image {
      height: 90vh;
    }

    &--hex {
      @apply h-auto pt-40 pb-8;
      min-height: auto !important;
      max-height: auto !important;

      @screen s {
        @apply min-h-0 max-h-full pt-48 pb-16;
      }

      @screen m {
        @apply pt-40 pb-8;
      }

      & svg {
        @apply absolute opacity-75;
        top: -35vw;
        right: -50vw !important;

        @screen xs {
          @apply w-full;
          top: -25vw;
          right: -25vw !important;
        }

        @screen s {
          @apply w-3/4;
          top: -15vw;
        }

        @screen l {
          top: -7vw;
          right: -25vw !important;
        }

        & path {
          animation: rotate 120s linear infinite;
          transform-origin: 50% 0%;
        }

        @screen xs {
          top: -20vw;
          right: -15vw !important;
        }

        @screen s {
          @apply w-2/3;
          top: -10vw;
        }
      }
    }
  }

  &__hero-shape {
    svg {
      @apply absolute z-0;
      fill: currentColor;

      .page__hero--shape & {
        bottom: 4vh;
        left: 0;

        polygon {
          animation: rotate 120s linear infinite;
          transform-origin: 50% 0%;
        }
      }

      .page__hero--image & {
        width: 33.333%;

        path {
          animation: rotate 40s linear infinite;
          transform-origin: 100% 100%;
        }
      }

      .page__hero-shape--right & {
        @apply right-0;
      }

      .page__hero-shape--left & {
        @apply left-0;
        transform: scaleX(-1);
      }

      .page__hero--image & {
        @apply bottom-0;
      }

      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  &__hero-image {
    $nav-height: 113px;

    @apply absolute left-0 right-0 overflow-hidden;
    top: $nav-height;
    bottom: 2rem;

    @screen s {
      bottom: 4rem;
    }

    @screen l {
      width: 92vw;
    }
  }

  &__hero-text {
    max-width: 860px;

    .page__hero--shape & {
      @apply mb-24 mx-auto;
    }
  }

  &__headline {
    @apply text-xl font-display leading-tight px-4;

    @screen s {
      @apply text-2xl;
    }

    @screen m {
      @apply text-3xl;
    }
  }
}
