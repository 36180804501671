.ff-text-slider {
  &__wrap {
    @apply pt-12;
    border-top: 1px solid theme('colors.black.a25');

    .cms-bg--black & {
      border-top: 1px solid theme('colors.white.a25');
    }
  }

  &__slide {
    @apply absolute opacity-0;
    transition: transform 0.55s ease, opacity 0.55s ease;
  }

  &__pages {
    @apply flex;
  }

  &__nav-slide {
    @apply relative mr-6 bg-white-a50;
    height: 2px;
    width: 44px;

    &:last-child {
      @apply mr-0;
    }

    &::after {
      @apply absolute top-0 left-0 h-full w-full bg-red;
      content: '';
      transform: scaleX(0);
      transform-origin: 0 center;
      transition: none;
    }

    &.active::after {
      transform: scaleX(1);
      transition: transform 7s linear;
    }
  }

  &__nav-total {
    @apply opacity-50;
    margin-left: 40%;
  }
}
