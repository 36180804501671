.work-card {
  .card {
    @apply relative mb-12;

    & > * {
      transition: color 0.5s ease-in-out;
    }

    &:hover {
      .work-card--title {
        color: theme('colors.red') !important;
      }
    }

    &:hover img {
      transform: scale(0.85) !important;
    }

    &:hover .overlay {
      @screen s {
        opacity: 0.85;
        transform: translate(0%, 0%) rotate(0deg) skew(0deg);
      }
    }

    &:hover .overlay-text {
      @screen s {
        opacity: 1;
        transform: translateY(0) rotate(0) skew(0);
        transition-delay: 0.25s;
      }
    }

    &--filtered {
      opacity: 0.2;
    }
  }

  .image-wrap {
    @apply relative bg-black-pure mb-6 overflow-hidden;

    & img {
      transition: transform 0.5s;
      width: 100%;
    }
  }

  &--lock-aspect-ratio .image-wrap {
    @apply relative h-0 w-full;
    padding-top: 66.666%;

    & img {
      @apply absolute top-0 left-0 block w-full h-full object-cover;
    }
  }

  .overlay {
    @apply absolute w-full h-full z-10 top-0 left-0 hidden;
    @include primary-gradient(135deg);
    box-shadow: inset 2px 2px 12px 0px rgba(0, 0, 0, 0.2);
    opacity: 0.375;
    transform: translate(-100%, -100%) rotate(-30deg) skew(-30deg);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

    @screen s {
      @apply block;
    }
  }

  .overlay-text {
    @apply font-body font-normal absolute opacity-0 text-white z-10 top-0 left-0 mt-6 ml-4;
    max-width: 400px;
    transform: translateY(2rem) rotateX(45deg) skewX(20deg);
    transition: transform 0.375s 0s,
      opacity 0.375s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  }

  .optimistic & {
    .card {
      &:hover {
        .work-card--title {
          color: theme('colors.ods-purple.light') !important;
        }
      }
    }

    .overlay {
      background-image: none;
      @apply bg-ods-purple-core;
    }
  }
}