.cta-banner {
  .cta-form-wrapper {
    box-shadow: 0px 4px 16px rgba(26, 26, 26, 0.16);
  }
}

.insights-index {
  &__button {
    @apply inline-block relative pr-8 select-none cursor-pointer mb-2;

    &::after {
      @apply absolute right-0 top-0 mr-1;
      border-left: 1px solid theme('colors.black.default');
      border-bottom: 1px solid theme('colors.black.default');
      content: '';
      height: 12px;
      transform: rotate(-45deg) scaleY(1);
      transform-origin: 38% 80%;
      width: 12px;
    }

    &--open::after {
      transform: rotate(45deg) scaleY(-1);
    }
  }

  &__filters {
    @apply bg-black mb-8 overflow-hidden;
    max-height: 0;
    transition: max-height 0.35s ease;

    &--open {
      max-height: 350px;
    }

    & .wrap {
      @apply py-8;
    }
  }

  &__cta-text {
    @apply serif-l3 leading-tight text-white mb-8;
  }

  &__grid-item {
    @apply w-full mb-12;

    @screen s {
      width: calc(50% - 16px);
    }
  }

  &__optimistic-link {
    &-title {
      transition: color 0.5s ease-in-out;
    }

    &:hover {
      .insights-index__optimistic-link-title {
        @apply text-red;
      }
    }
  }

  &__link {
    &-title {
      transition: color 0.5s ease-in-out;
    }

    &:hover {
      .insights-index__link-title {
        @apply text-red;
      }

      @screen s {
        .insights-index__overlay {
          opacity: 0.85;
          transform: translate(0%, 0%) rotate(0deg) skew(0deg);
        }

        .insights-index__overlay-text > * {
          @apply opacity-100;
          transform: translateY(0) rotate(0) skew(0);
          transition-delay: 0.25s;
        }
      }
    }
  }

  &__overlay {
    @apply absolute w-full h-full z-10 top-0 left-0;
    @include primary-gradient(135deg);
    box-shadow: inset 2px 2px 12px 0px rgba(0, 0, 0, 0.2);
    opacity: 0.375;
    transform: translate(-100%, -100%) rotate(-30deg) skew(-30deg);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

    &--ods {
      @apply bg-ods-purple-core;
      background-image: none;
    }
  }

  &__overlay-text {
    @apply font-body font-normal text-m absolute h-full w-full top-0 left-0 flex flex-col py-8 px-6 text-white justify-between z-20;

    & > * {
      @apply opacity-0;
      transform: translateY(2rem) rotateX(45deg) skewX(20deg);
      transition: transform 0.375s 0s,
        opacity 0.375s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
    }
  }

  .optimistic & {
    &__link {
      &:hover {
        .insights-index__link-title {
          @apply text-ods-purple-core;
        }
      }
    }

    &__overlay {
      @apply bg-none bg-ods-purple-core;

      &-text {
        @apply font-ods-body;
      }
    }
  }
}
