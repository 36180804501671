.capabilities-accordion {
  &__inner {
    @apply relative w-full;
    max-width: 800px;
  }

  &__row {
    @apply cursor-pointer;

    & .plus {
      @apply relative ml-3 mr-6 transition-transform duration-300 flex-shrink-0;
      height: 18px;
      width: 18px;

      @screen s {
        width: 22px;
        height: 22px;
      }

      &::before,
      &::after {
        @apply absolute bg-white;
        content: '';
      }
      
      &::before {
        @apply w-px h-full;
        left: 50%;
      }

      &::after {
        @apply h-px w-full;
        top: 50%;
      }

      &--active {
        transform: rotate(-45deg);
      }

      &.plus-dark {
        &::before,
        &::after {
          @apply bg-black;
        }
      }
    }
  }

  &__row-content {
    @apply h-0 overflow-hidden;
  }

  &__row-content--active {
    @apply h-auto;
  }

  &__summary {
    margin-bottom: 55px;
  }

  &__icon svg {
    height: 46px;
    width: 46px;
  }

  &__featured-image img {
    width: 175px;
    height: 175px;
    object-fit: cover;
  }
}