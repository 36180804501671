.insights-detail {
  &__header--gradientBehind {
    @apply relative text-white;
    height: 495px;

    &.height-auto {
      height: auto;
    }

    &::after {
      @apply absolute top-0 left-0 w-full h-full z-10;
      content: '';
    }

    .insights-detail__hero-wrap {
      max-width: 800px;

      .optimistic-logo {
        max-width: 480px;
      }
    }
  }

  &__header--imageBehind {
    @apply relative text-white;
    height: 600px;

    &::after {
      @apply absolute top-0 left-0 w-full h-full z-10;
      background-color: rgba(0, 0, 0, 0.6);
      content: '';
    }

    .insights-detail__hero-wrap {
      max-width: 800px;
    }
  }

  &__header--imageBelow {
    .insights-detail__hero-wrap {
      @apply w-full flex flex-col items-start justify-end mx-auto pb-16;
      margin-top: 150px;
      max-width: 800px;
    }

    .insights-detail__hero-image {
      height: 300px;

      @screen s {
        height: 450px;
      }

      @screen m {
        height: 640px;
      }
    }
  }

  &__attributions {
    @apply flex mx-auto mb-8;
    max-width: 800px;

    @screen l {
      @apply block absolute top-0 my-0;
      left: -201px;
      width: 175px;
    }
  }

  .optimistic & {
    &__hero-wrap {
      .subhead {
        @apply font-ods-mono text-5xs font-normal leading-tight tracking-widest uppercase mb-6;
      }

      h1.h2 {
        @apply font-ods-display text-l font-normal leading-tight mb-6;
  
        @screen s {
          @apply text-xl;
        }
      }

      h2.h3 {
        @apply font-ods-body text-xs font-light leading-snug;

        @screen s {
          @apply text-s;
        }
      }
    }
  }
}

.cto-to-cto {
  .cto-hero {
    max-height: 40rem; // 640px

    .video {
      max-width: 50rem; // 800px

      &__video {
        iframe {
          @apply rounded;
        }
      }
    }
  }

  +.insights-detail__header--imageBelow {
    .insights-detail__hero-wrap {
      @apply mt-20 pb-10;

      @screen l {
        @apply mt-24;
      }
    }
  }
}

.cto-interview {
  .insights-wrap {
    p:not(:first-child) {
      @apply mt-4;
    }

    .cto-section-anchor {
      top: -100px;
    }
  }

  .cto-interviewer-wrapper,
  .cto-interviewee-wrapper {
    .cto-tapback {
      top: -12px;

      svg {
        height: 18px;
        width: 18px;
      }
    }
  }
  
  .cto-interviewer-wrapper {
    @screen s {
      padding-right: 72px;
    }

    .cto-tapback {
      right: 30px;

      @screen s {
        right: 65px;
      }
    }
  }
  
  .cto-interviewee-wrapper {
    @screen s {
      padding-left: 72px;
    }

    .cto-tapback {
      left: 30px;

      @screen s {
        left: 65px;
      }
    }
  }
  
  .cto-interviewer-content,
  .cto-interviewee-content {
    p:not(:first-child) {
      @apply mt-4;
    }

    figure {
      @apply my-4;
    }
  }
  
  .cto-interviewer-content {
    border-radius: 0px 24px 24px 24px;
  }
  
  .cto-interviewee-content {
    border-radius: 24px 0px 24px 24px;
  }

  &__blockquote {
    &-mark {
      top: -26px;
    }
  }

}

html:not(.no-js) .cto-interview [data-aos=fade-left] {
  -webkit-transform: translate3d(32px,0,0);
  transform: translate3d(32px,0,0);
}

html:not(.no-js) .cto-interview [data-aos=fade-right] {
  -webkit-transform: translate3d(-32px,0,0);
  transform: translate3d(-32px,0,0);
}

