.offset-images-text {
  @apply py-20;

  @screen s {
    @apply pt-64 pb-20;
  }

  .image {
    width: 100%;
  }

  &__image img {
    @apply min-h-full object-cover object-center;
  }

  &__text {
    max-width: 720px;
  }
}
