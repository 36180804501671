.featured-work-cards {
  .text {
    max-width: 800px;
  }

  .featured-work-slider {
    @apply relative w-full mt-10;

    .js-slider__nav span {
      @apply hidden;
    }
  }

  .featured-work-slider__slider {
    @apply absolute top-0 left-0 w-full;

    .js-slider__slide {
      @apply absolute pointer-events-none z-0;

      &.active {
        @apply pointer-events-auto z-10;
      }
    }
  }
}
