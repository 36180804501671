.image-grid {
  @apply relative;

  &::after {
    @apply absolute bg-white left-0 right-0 bottom-0 z-0;
    height: 18%;
    content: '';
  }

  &__headline {
    max-width: 880px;
  }

  &__images {
    @apply relative mt-16 z-10 overflow-hidden;
    max-width: 100vw;
  }

  &__inner {
    @apply flex relative flex-no-wrap items-center;
  }

  &__image {
    @apply relative flex-shrink-0 mr-4;

    @screen s {
      @apply mr-8;
    }

    &--1 {
      @apply w-2/4;
      height: 240px;

      @screen xs {
        @apply w-3/6;
        height: 420px;
      }

      @screen s {
        height: 620px;
        @apply w-5/12;
      }
    }

    &--2 {
      @apply w-4/12;
      height: 140px;

      @screen xs {
        @apply w-3/12;
        height: 320px;
      }

      @screen s {
        height: 420px;
      }
    }

    &--3 {
      @apply w-full;
      height: 130px;

      @screen xs {
        height: 240px;
      }

      @screen s {
        height: 330px;
      }
    }

    &--4 {
      @apply w-full;
      height: 130px;

      @screen xs {
        height: 240px;
      }

      @screen s {
        height: 330px;
      }
    }

    &--5 {
      @apply w-4/12;

      @screen xs {
        height: 520px;
      }
    }
  }
}
