.hero-features-nav {
  @apply pt-6 pb-5;

  @screen m {
    @apply hidden;
  }

  &__button {
    @apply bg-black bg-opacity-10 px-3 leading-none rounded-full uppercase font-mono text-5xs tracking-wider text-black mr-2;
    padding-top: 6px;
    padding-bottom: 4px;

    &:focus {
      @apply outline-none bg-opacity-75;
    }
    
    &.active {
      @apply bg-red text-white;
    }
  }

}