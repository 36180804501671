.js-slider {
  &__slide {
    @apply absolute;
    transition: transform 0.55s ease, opacity 0.55s ease;

    @screen s {
      @apply px-0;
    }
  }

  &__nav--current,
  &__nav--total {
    @apply font-mono;
  }

  &__nav--total {
    @apply opacity-50;
    margin-left: 25%;
  }

  &__nav-blips {
    @apply flex;
  }

  &__nav-blip {
    @apply relative mr-6 bg-white-a50;
    height: 2px;
    width: 44px;
    cursor: pointer;

    &:hover {
      @apply bg-white;
    }

    &:last-child {
      @apply mr-0;
    }

    &::before {
      @apply absolute left-0 right-0;
      content: '';
      height: 16px;
      top: -7px;
    }

    &::after {
      @apply absolute top-0 left-0 h-full w-full bg-red;
      content: '';
      transform: scaleX(0);
      transform-origin: 0 center;
      transition: none;
    }

    &.active::after {
      transform: scaleX(1);
      transition: transform linear;
      transition-duration: inherit;
    }
  }

  .optimistic & {
    &__nav--current,
    &__nav--total {
      @apply font-ods-mono text-5xs font-normal leading-snug tracking-widest uppercase;
    }

    &__nav-blip {
      &::after {
        @apply bg-ods-purple-core;
      }
    }
  }
}

.cms-bg--white .js-slider__nav-blip {
  @apply bg-black-a25;
}

.js-slider__transition--fade {
  .js-slider__slide {
    @apply opacity-0;
  }

  .js-slider--backwards & {
    .prev {
      transform: translateX(35px);
    }

    .active {
      @apply opacity-100;
      transform: translateX(0);
    }
  }

  .js-slider--forwards & {
    .prev {
      transform: translateX(-35px);
    }

    .active {
      @apply opacity-100;
      transform: translateX(0);
    }
  }
}

.js-slider__transition--slide {
  .js-slider__slide {
    @apply opacity-0;
  }

  .js-slider--backwards & {
    .prev {
      @apply z-10;
      transform: translateX(0);
      animation: none;
    }

    .active {
      @apply z-30 opacity-100;
      animation: sliderSlideInBack 0.55s ease 0s forwards;

      @keyframes sliderSlideInBack {
        from {
          transform: translateX(-100%);
        }
        to {
          transform: translateX(0);
        }
      }

      & img {
        animation: sliderCounterSlideInBack 0.55s ease 0s forwards;

        @keyframes sliderCounterSlideInBack {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(0);
          }
        }
      }
    }
  }

  .js-slider--forwards & {
    .prev:not(last-child) {
      @apply z-10;
      transform: translateX(0);
      animation: none;
    }

    .active {
      @apply z-30 opacity-100;
      animation: sliderSlideIn 0.55s ease 0s forwards;

      @keyframes sliderSlideIn {
        from {
          transform: translateX(100%);
        }
        to {
          transform: translateX(0);
        }
      }

      & img {
        animation: sliderCounterSlideIn 0.55s ease 0s forwards;

        @keyframes sliderCounterSlideIn {
          from {
            transform: translateX(-100%);
          }
          to {
            transform: translateX(0);
          }
        }
      }
    }
  }

  .optimistic & {

  }
}
