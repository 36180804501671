.ff-image-grid-text {
  @apply relative;

  &::before,
  &::after {
    @apply absolute left-0 w-full z-0 bg-white;
    content: '';
    height: calc(1rem + 7.5vw);
  }

  &::before {
    @apply top-0;
  }

  &::after {
    @apply bottom-0 bg-black;
  }

  .text {
    @apply flex flex-col justify-center;
    box-sizing: border-box;
    height: 390px;
  }

  &__headline {
    @screen s {
      @apply text-m;
    }

    @screen m {
      @apply text-m;
    }

    @screen l {
      @apply text-xl;
    }
  }

  &__text-wrap {
    @apply pt-16 w-full;
    padding-bottom: 7rem;

    @screen s {
      @apply py-32;
      width: calc(43.5% - 1rem);
    }
  }

  &__images {
    @apply relative flex items-center w-full ml-0 z-0;

    @screen s {
      @apply z-10 mr-0;
      width: calc(56.5% - 1rem);
      left: -11%;
    }
  }

  &__image-col {
    &--1 {
      @apply mr-8;
      width: calc(56% - 1rem);

      & .ff-image-grid-text__image--1 {
        @apply block pb-8 w-full;
      }

      & .ff-image-grid-text__image--2 {
        @apply block;
        width: 125%;
        margin-left: -25%;
      }
    }

    &--2 {
      width: calc(44% - 1rem);

      & .ff-image-grid-text__image--1 {
        @apply block pb-8;
        width: 75;
      }

      & .ff-image-grid-text__image--2 {
        @apply block;
        width: 125%;
      }
    }
  }

  &__image {
    @apply overflow-hidden;
  }

  .shape {
    @apply absolute bottom-0 right-0;
    width: 75%;
    z-index: 0;

    @screen m {
      width: 33.33%;
    }

    & svg {
      fill: theme('colors.white.default');

      & path.dec {
        animation: rotate 40s linear infinite;
        transform-origin: 100% 100%;

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(-360deg);
          }
        }
      }
    }
  }
}
