.quote {
  @apply py-16;

  &__blockquote {
    @apply relative pt-16 pl-8;
    border-left: 1px solid theme('colors.red');
  }

  &__mark {
    @apply absolute text-red;
    left: 28px;
    top: -24px;
    font-size: 90px;
  }

  .optimistic & {
    &__blockquote {
      @apply border-ods-purple-core;
    }

    &__mark {
      @apply text-ods-purple-core;
    }
  }
}
