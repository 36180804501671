.ff-text-sliding-images {
  @apply relative;

  &__bg { 
    @apply absolute top-0 left-0 w-full;
    height: 50%;
  }

  &__images {
    @apply overflow-visible;
    max-width: 100vw;
    overflow: hidden;
  }

  &__image {
    @apply h-full;
  }
}
