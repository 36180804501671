$nav-height: 113px;

.page-home {
  position: relative;

  &__hero {
    @apply relative z-10 overflow-hidden bg-lightYellow bg-opacity-10;
    min-height: 800px;
    padding-top: 86px;

    @screen xs {
      @apply h-screen;
    }
  }

  &__shape {
    & svg {
      filter: blur(2px);
      mix-blend-mode: multiply;
      transform: translate(-50%, -5%);

      path {
        transform-origin: 540px 0px;
        animation: rotate 40s linear infinite;
      }

      @screen xl {
        @apply w-auto;
        height: 80%;
      }
      
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &__hero-content {
    @apply relative h-full flex flex-col z-10;

    &::after {
      @apply absolute h-px top-0 bg-black bg-opacity-10;
      content: '';
      width: 99999px;
      transform: translateX(-50%);
    }

    @screen m {
      @apply flex-row;
    }
  }

  &__hero-main {
    @apply relative flex-1 flex flex-col py-6 text-black z-10;

    & p {
      @apply mb-6;
    }

    & p:last-child {
      @apply mb-0;
    }

    @screen xs {
      @apply justify-center py-0;
    }

    & > h1.h5 {
      @apply mono-l1 mb-6;
    }

    & > h2.h1 {
      @apply font-display text-xl mb-6 mr-4 leading-none;

      @screen xs {
        line-height: 1.1;
      }

      @screen s {
        @apply text-2xl mr-20;
      }

      @screen l {
        @apply text-3xl
      }
    }

    & > h3.h2 {
      @apply font-body text-3xs leading-body pr-3;

      @screen xs {
        @apply text-2xs;
      }

      @screen s {
        @apply pr-48;
      }

      @screen m {
        @apply pr-56;
      }
    }
     
    @screen m {
      @apply flex-none border-r border-black border-opacity-10;
      width: 65%;

      &.no-featured {
        @apply w-full border-none;
      }
    }
  }

  &__hero-features--wrapper {
    @apply relative;
    height: 280px;

    &::after {
      @apply absolute top-0 h-px bg-black bg-opacity-10;

      width: 99999px;
      content: '';
      transform: translateX(-50%);
    }

    @screen xs {
      height: 240px;
    }

    @screen m {
      @apply h-auto border-none;

      &::after {
        @apply hidden;
      }

      .page-home__hero-features--list {
        @apply h-full flex flex-col flex-1;
        
        li {
          @apply relative flex-1;

          &::after {
            @apply absolute h-px bottom-0 left-0 bg-black bg-opacity-10;
            content: '';
            width: 99999px;
          }
  
          &:last-child::after {
            @apply hidden;
          }
        }
      }
    }
  }

  &__intro {
    @apply sans-l3-light text-s text-center pb-12 w-5/6 mx-auto pt-12;
    max-width: 930px;

    @screen s {
      @apply pb-16 sans-l3-light;
    }

    @screen m {
      @apply text-l pt-20;
    }
  }
}
