.no-scroll {
  height: 100vh;
  overflow-y: hidden;
}

.wrap {
  @apply mx-auto px-4 max-w-page;

  @screen s {
    @apply px-8;
  }

  @screen m {
    @apply px-16;
  }

  @screen l {
    @apply px-8;
  }

  @screen xl {
    @apply px-0;
  }
}

.n-wrap {
  @apply -mx-4;

  @screen s {
    @apply -mx-8;
  }

  @screen m {
    @apply -mx-16;
  }

  @screen l {
    @apply -mx-8;
  }

  @screen xl {
    @apply mx-0;
  }
}

.wrap-l {
  @apply mx-auto pl-4 max-w-page;

  @screen s {
    @apply pl-8;
  }

  @screen m {
    @apply pl-16;
  }

  @screen l {
    @apply pl-8;
  }

  @screen xl {
    @apply pl-0;
  }
}

.wrap-r {
  @apply mx-auto pr-4 max-w-page;

  @screen s {
    @apply pr-8;
  }

  @screen m {
    @apply pr-16;
  }

  @screen l {
    @apply pr-8;
  }

  @screen xl {
    @apply pr-0;
  }
}

.work-wrap {
  @apply mx-auto px-4;
  box-sizing: content-box;
  max-width: 800px;

  @screen s {
    @apply px-8;
  }
}

.insights-wrap {
  @apply mx-auto px-4;
  box-sizing: content-box;
  max-width: 800px;

  @screen s {
    @apply px-8;
  }
}

.work-wrap-l {
  @apply mx-auto px-4;
  box-sizing: content-box;
  max-width: 1008px;

  @screen s {
    @apply px-8;
  }
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
