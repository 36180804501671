.cta-gradient {
  @apply relative;

  &::after,
  &::before {
    @apply absolute left-0 top-0 h-full w-full z-0 opacity-0 pointer-events-none;
    @include secondary-gradient();
    content: '';
    transition: opacity 1s;
  }

  &::before {
    @include secondary-gradient(225deg);
  }

  &.hover--left::after {
    @apply opacity-75;
  }

  &.hover--right::before {
    @apply opacity-75;
  }

  &__headline {
    @apply serif-l1 text-xl text-center;

    @screen s {
      @apply text-center;
    }
  }

  .optimistic & {
    &__headline {
      @apply font-ods-display;

      @screen s {
        @apply text-2xl;
      }
    }
  }
}
