.video {
  &__play-button {
    @apply absolute cursor-pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.25s, opacity 0.25s;

    &:active {
      transform: translate(-50%, -50%) scale(0.9);
    }

    & .play-button__bg {
      transition: fill-opacity 0.35s ease;
    }

    &:hover .play-button__bg {
      fill-opacity: 1;
    }
  }
}

#optimistic-video {
  scroll-margin-top: theme('spacing.20');
}
