.list-columns {
  &__text {
    @apply inline-block;
  }

  &__text--strike {
    @apply relative;

    &::after {
      @apply absolute;
      left: -4px;
      right: -4px;
      height: 1px;
      top: 50%;
      background-color: theme('colors.red');
      content: '';
    }
    // text-decoration: theme('colors.red') line-through;
  }
}
