@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.barba-container {
  @apply relative z-10;
}

.barba-enter {
  animation: fade-in 600ms ease;
}

.loader {
  @apply fixed top-0 left-0 w-full h-full pointer-events-none opacity-0;
  z-index: 999;
  transition: opacity 0.25s ease;

  &::before,
  &::after {
    @apply absolute w-full h-full;
    content: '';
    transform: translateY(100%);
    transition: transform 0.55s ease-in-out;
  }

  &::before {
    @apply z-10;
    @include primary-gradient(180deg);
    transition-delay: 0s;
  }

  &:after {
    @apply z-20 bg-white;
    transition-delay: 0.25s;
  }

  .transitioning & {
    &::before,
    &::after {
      transform: translateY(0);
    }
  }

  .optimistic & {
    &::before {
      @apply bg-ods-purple-core;
      background-image: none;
    }
  }
}

.barba-wrapper {
  @apply z-10;
}

.show-loader {
  .loader {
    @apply opacity-100;
  }
}
