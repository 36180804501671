.alternating-list-images {
  &__intro {
    @apply mx-auto;
    max-width: 800px;
  }

  &__images {
    @apply flex overflow-hidden;
    max-width: 100vw;
  }

  &__image {
    @apply w-4/6 overflow-hidden flex-shrink-0;

    &:first-child {
      @apply mt-12;
      margin-left: -45%;
    }

    &:last-child {
      @apply ml-8;
    }

    .flex-row-reverse & {
      &:first-child {
        @apply mt-0 ml-0;
      }

      &:last-child {
        @apply mt-12;
        margin-right: -45%;
      }
    }

    img {
      @apply w-full;
    }
  }

  &__body {
    @screen s {
      width: 80%;
    }
  }
}
