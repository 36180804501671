.prev-next-footer {
  &__bg {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  &__item {
    height: 400px;
  }

  &__item a {
    @apply block h-full w-full flex items-center justify-center;
  }

  &__item a:hover {
    .prev-next-footer__arrow {
      transform: translateX(10px);
    }
  }

  &__arrow {
    transition: transform 0.25s ease;
  }

  &__bg-item {
    transition: opacity 0.5s ease;

    img {
      transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }

  &__bg.hover--left .prev-next-footer__bg-item:first-child {
    opacity: 1;

    & img {
      transform: scale(1.125);
    }
  }

  &__bg.hover--right .prev-next-footer__bg-item:nth-child(2) {
    opacity: 1;

    & img {
      transform: scale(1.125);
    }
  }
}
