.open-positions {
  .whr-items {
    @apply flex flex-wrap -mx-5;
  }

  .whr-item {
    @apply relative w-full px-5 pb-10;

    @screen xs {
      @apply w-1/2;
    }
  }

  .whr-title {
    @apply font-body font-semibold leading-tight pt-8;
    font-size: 21px;
    border-top: 1px solid theme('colors.black.a25');
    transition: color .15s ease;

    &:hover {
      @apply text-red;
    }

    @screen s {
      font-size: 27px;
    }
  }

  .whr-dept {
    @apply inline-block mt-6 text-5xs font-bold px-2 py-1 rounded-full bg-orange;

    & span {
      @apply hidden;
    }
  }

  .whr-location {
    @apply inline-block mt-6 text-5xs font-bold px-2 py-1 rounded-full bg-yellow;

    & span {
      @apply hidden;
    }
  }

  .whr-date {
    @apply hidden;
  }
}
