.ff-text-sliding-list {
  @apply relative;
  height: auto;

  @screen s {
    height: 725px;
  }

  &__wrap {
    padding: 0 !important;

    @screen s {
      padding-left: inherit;
    }
  }

  &__slider,
  &__body {
    @apply w-full relative z-0;
    height: 50%;

    @screen s {
      @apply h-full w-1/2;
    }
  }

  &__slider {
    height: 250px;

    @screen s {
      @apply h-full;
    }
  }

  &__body {
    @apply z-10 px-4;

    &::after {
      @apply absolute left-0 w-full bottom-0 h-full;
      box-shadow: 0 8px 4px -4px rgba(0, 0, 0, 0.2);
      content: '';
    }

    @screen s {
      @apply static px-0;

      &::after {
        @apply bottom-auto top-0;
        box-shadow: inset 0 8px 4px -4px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &__mask {
    @apply absolute top-0 left-0 w-full h-full;
    mask-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  }

  &__inner {
    @apply h-full overflow-hidden flex justify-center;
  }

  &__list {
    @apply serif-l1 leading-none antialiased;
    font-size: 90px;
    animation: scrollList 20s linear infinite;

    @keyframes scrollList {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-100%);
      }
    }
  }

  &__item {
    height: calc(20px + 13vw);
    font-size: calc(20px + 13vw);

    @screen s {
      height: 90px;
      font-size: inherit;
    }
  }
}
