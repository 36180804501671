.icon-social {
	@apply w-6 h-6;

	path {
		fill: theme('colors.red');
		transition: fill .325s;
	}

	.optimistic & {
		path {
			fill: theme('colors.ods-purple.light');
		}
	}
}

.icon-social-link {
	@apply relative w-12 h-12 rounded-full flex items-center justify-center mr-2;
	transition: background .325s;

	&:before {
		@apply absolute top-0 left-0 w-full h-full rounded-full opacity-0;
    content: '';
    background: linear-gradient(135deg, theme('colors.coralGradient') 10%, theme('colors.red') 100%);
    z-index: -1;
    transition: opacity .5s;
	}

	&:hover {

		&:before {
			@apply opacity-100;
		}

		.icon-social {

			path {
				fill: #FFF;
				transition: fill .325s;
			}
		}
	}

	.optimistic & {
		&::before {
			background-image: none;
			@apply bg-ods-purple-core;
		}
	}
}
