.work-cards-with-filters {
  &__button {
    @apply inline-block relative pr-8 select-none cursor-pointer mb-2;

    &::after {
      @apply absolute right-0 top-0 mr-1;
      border-left: 1px solid theme('colors.black.default');
      border-bottom: 1px solid theme('colors.black.default');
      content: '';
      height: 12px;
      transform: rotate(-45deg) scaleY(1);
      transform-origin: 38% 80%;
      width: 12px;
    }

    &--open::after {
      transform: rotate(45deg) scaleY(-1);
    }
  }

  &__filters {
    @apply bg-black mb-8 overflow-hidden;
    max-height: 0;
    transition: max-height 0.35s ease;

    &--open {
      max-height: 350px;
    }

    & .wrap {
      @apply py-8;
    }
  }
}
