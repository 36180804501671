.ff-image-text {
  @apply relative;

  &__bg::after {
    @apply absolute h-full w-full top-0 left-0 bg-black-pure opacity-75;
    content: '';
  }

  .icon {
    width: 48px;
    height: 48px;
  }

  &__image {
    @apply relative;

    &--standard {
      @apply m-0;

      @screen s {
        @apply my-40;
      }

      & .ff-image-text__image-inner {
        position: relative;
      }
    }

    &--fullBleed {
      @apply overflow-hidden h-0;
      padding-top: 100%;

      @screen s {
        min-height: 800px;
        max-height: 1200px;
      }

      & img {
        @apply absolute top-0 left-0 w-full h-full object-cover;
      }

      @screen m {
        @apply h-screen p-0 -mb-20;
      }
    }
  }

  .text {
    @apply w-full;

    @screen s {
      max-width: 550px;
    }
  }
}

.ff-image-text-ods {
  .full-bleed-wrapper {
    max-height: 40vh;
  }
  
  @screen s {
    .full-bleed-wrapper {
      max-height: 100%;

      &.bleed-left,
      &.bleed-right {
        .rellax {
          @apply mt-0;
          height: 150%;
        }
      }
    }
  }
}
