.cta-background-image {
  &__image {
    & img {
      transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &.zoom img {
      transform: scale(1.125);
    }
  }

  &__headline {
    @apply serif-l1 text-xl;

    @screen s {
      @apply serif-l1;
    }
  }
}
