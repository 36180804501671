.hero-feature {
  @apply absolute invisible pointer-events-none opacity-0 transition-all duration-300;
  transform: translateY(20px);

  @screen m {
    @apply static visible pointer-events-auto opacity-100 transition-none;
    transform: translateY(0);
  }

  &.active {
    @apply visible pointer-events-auto opacity-100;
    transform: translateY(0);
  }

  > a {
    transition-duration: .75s;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);

    &:hover {
      @apply text-black;

      @screen m {
        @apply text-white;
      }
    }
  }

  &__bg {
    @apply absolute z-0 h-full top-0 left-0 right-0 overflow-hidden;

    @screen m {
      right: calc(theme('padding.16') * -1);
    }

    @screen l {
      right: calc(((100vw - 1360px) / 2) * -1);
    }

    &-scrim {
      @apply absolute top-0 left-0 w-full h-full opacity-0;
      background-color: rgba(26, 26, 26, .9);
      transition-duration: .75s;
      transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);

      .hero-feature:hover & {
        @apply opacity-100;
      }
    }

    &-image {
      @apply absolute top-0 left-0 w-full h-full bg-cover bg-center opacity-0 transform scale-125;
      filter: grayscale(1);
      mix-blend-mode: screen;
      transition-duration: .75s;
      transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);

      .hero-feature:hover & {
        @apply opacity-40 scale-100;
      }
    }
  }

  &__link {
    @apply inline-block text-3xs font-bold border-b border-red;

    @screen m {
      @apply hidden;
    }
  }
}