input[type='text'],
input[type='email'] {
  @apply block w-full border-b border-black-default20 font-body font-semibold text-3xs;
  color: inherit;
  height: 36px;
  background-color: transparent;

  .text-white & {
    @apply border-white-a50;
  }

  &::placeholder {
    .text-white & {
      @apply text-white;
    }

    .text-black & {
      @apply text-black;
    }
  }

  &:focus {
    @apply outline-none border-red;
  }

  &.input--l {
    @apply text-m font-light text-black;
  }
}

.radio-toggles {
  @apply w-full mx-auto flex;
  max-width: 420px;
}

.radio-toggle {
  @apply relative block w-1/2 font-body text-3xs font-bold text-center cursor-pointer;
  height: 42px;

  & span {
    @apply absolute w-full h-full top-0 left-0 border-b border-black-default20;
    transition: color 0.5s, border-color 0.5s;
  }

  & input[type='radio'] {
    @apply opacity-0 absolute;
    width: 0.1px;
    height: 0.1px;
  }

  & input[type='radio']:checked + span {
    @apply text-red border-red;
  }

  &-ods {
    @apply font-ods-body;

    & input[type='radio']:checked + span {
      @apply text-ods-purple-light border-ods-purple-light;
    }
  }
}
.g-recaptcha {
  /* @apply hidden; */
}

.ods-contact-form,
.ods-modal-form {
  @apply font-ods-body;

  input[type='text'],
  input[type='email'],
  textarea {
    @apply block w-full border-b border-black-a50 py-4 font-ods-body text-3xs;
    
    &::placeholder {
      @apply text-black opacity-75;
    }
    
    &:focus {
      @apply outline-none border-ods-purple-core;
    }
    
    &.input--l {
      @apply text-m font-light text-black;
    }
  }

  input[type='text'],
  input[type='email'] {
    @apply h-12;
  }

  textarea {
    @apply h-16 pb-0 pt-3;
  }
  
  input#zip {
    display: none;
  }

  .btn-ods {
    width: max-content;
  }
  
  .file-input {
    @apply cursor-pointer;
    
    input[type='file'] {
      @apply opacity-0 overflow-hidden absolute;
      hieght: 0.1px;
      width: 0.1px;
      z-index: -1;
    }
  }

  .errors {
    @apply text-ods-red-core;
  }

  .submit-success-message {
    @apply text-2xs mt-8 text-ods-purple-core;
  }
}

.ods-newsletter-form {
  @apply font-ods-body;

  input[type='email'],
  input[type='email']::placeholder {
    @apply text-black opacity-75 font-ods-body text-3xs;

    &:focus {
      @apply outline-none border-black;
    }
  }

  input[type='email'] {
    &:focus {
      @apply outline-none border-black;
    }
  }

  textarea {
    @apply hidden;
  }

  .btn-submit {
    @apply cursor-pointer bg-center bg-no-repeat outline-none;
    height: 24px;
    background-color: transparent;

    &:focus {
      @apply outline-none;
    }

    &:active {
      transform: scale(0.9);
    }
  }

  & .errors {
    @apply text-ods-red-core font-semibold opacity-60 text-6xs;
  }

  + .legal-consent-container {
    @apply opacity-60 text-black text-5xs;

    & a {
      @apply text-black underline font-normal;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;

      &:hover {
        @apply cursor-pointer;
      }
    }
  }

  footer & {
    input[type='email'],
    input[type='email']::placeholder {
      @apply text-white;
    }

    + .legal-consent-container {
      @apply hidden;
    }
  }

  div {
    &:focus-within {
      @apply border-white;
    }
  }
}

.pipedriveWebForms {

  &.get-in-touch {
    @apply mx-auto;
    max-width: 480px;
  }
}
