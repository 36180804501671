body {
  @apply font-body;
  font-size: 16px;
  max-width: 100%;
  overflow-x: hidden;
}

::selection {
  @apply bg-red text-white;
}

.optimistic {
  @apply font-ods-body text-3xs;
  font-optical-sizing: auto;

  ::selection {
    @apply bg-ods-purple-core text-white;
    background-clip: none;
		-webkit-background-clip: none;
		-webkit-text-fill-color:  theme('colors.white.default');
  }
}

