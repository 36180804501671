.swiper-wrapper {
  @apply mt-3;
}

.swiper-container-horizontal {
  @apply relative pt-8;

  .swiper-pagination {
    @apply absolute text-left transform-none transition-none mb-6 flex;
  }

  .swiper-pagination-bullets {
    @apply top-0 left-0 bottom-auto w-full justify-center;

    .swiper-pagination-bullet {
      @apply font-body font-bold text-left w-auto capitalize h-5 mb-6 ml-0 mx-2 opacity-50 transition-opacity;
      background: transparent;
    
      &::after {
        background-image: none;
        background-color: black;
        transform: scaleX(1);
      }
      
      &:hover,
      &:focus {
        @apply opacity-100;

        &::after {
          @apply opacity-100;
          @include primary-gradient(90deg);
          background-color: none;
        }
      }

      &.swiper-pagination-bullet-active {
        @apply opacity-100;

        &::after {
          @include primary-gradient(90deg);
        }
      }
    }
  }

  .optimistic & {
    .swiper-pagination-bullets {
      .swiper-pagination-bullet {
        @apply font-ods-body;

        &::after {
          bottom: -4px;
        }

        &:hover,
        &:focus {
          &::after {
            background-image: none;
            @apply bg-ods-purple-light;
          }
        }

        &.swiper-pagination-bullet-active {
          &::after {
            @apply bg-ods-purple-light;
            background-image: none;
          }
        }
      }
    }
  }
}
