.work-cards {
  .col {
    box-sizing: border-box;

    &:first-child {
      @apply pr-4;
    }

    &:last-child {
      @apply pl-4;
    }

    &.work-cards--offset:last-child {
      @apply -mt-16;
    }
  }  
}
