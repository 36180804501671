.multi-column-text-icons {
  &__icon svg {
    height: 46px;
    width: 46px;
  }

  &__columns[data-columns="3"] {
    max-width: 960px;
  }

  &__columns[data-columns="4"] {
    max-width: 1200px;
  }
  
  &__label {
    @screen s {
      max-width: 240px;
    }
  }
}